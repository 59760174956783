import walker from 'src/assets/images/howtoplay/walker2.png';
import jogger from 'src/assets/images/howtoplay/jogger2.png';
import runner from 'src/assets/images/howtoplay/runner2.png';
import trainer from 'src/assets/images/howtoplay/trainer2.png';
import iconWalker from 'src/assets/images/howtoplay/iconWalker.png';
import iconJogger from 'src/assets/images/howtoplay/iconJogger.png';
import iconRunner from 'src/assets/images/howtoplay/iconRunner.png';
import iconTrainer from 'src/assets/images/howtoplay/iconTrainer.png';

export const sneakerStats = [
  {
    name: 'Walker',
    speed: '1-6',
    fitEarn: '4',
  },
  {
    name: 'Jogger',
    speed: '4-10',
    fitEarn: '5',
  },
  {
    name: 'Runner',
    speed: '8-20',
    fitEarn: '6',
  },
  {
    name: 'Trainer',
    speed: '1-20',
    fitEarn: '4-6.25',
  },
];

export const renderTypeIcon = (type) => {
  switch (type) {
    case 'Walker':
      return iconWalker;
    case 'Jogger':
      return iconJogger;
    case 'Runner':
      return iconRunner;
    case 'Trainer':
      return iconTrainer;
    default:
      return null;
  }
};

export const renderSneakerImg = (type) => {
  switch (type) {
    case 'Walker':
      return walker;
    case 'Jogger':
      return jogger;
    case 'Runner':
      return runner;
    case 'Trainer':
      return trainer;
    default:
      return null;
  }
};