import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import phoneSocial from 'src/assets/images/howtoplay/phoneSocial3.png';
import { StyledShareSocialDesktop } from './styled';
import { Col, Row } from 'antd';

const ShareSocialDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledShareSocialDesktop isDarkMode={isDarkMode}>
      <div className="container">
        <Row>
          <Col span={14} className="col-content">
            <div className="content-title top">
              <div className="title">
                <span className="bold">CHECK</span>
                <span>your earning</span>
              </div>
            </div>
            <div className="content-title bottom">
              <div className="title">
                <span className="bold">SHARE</span>
                <span>on social</span>
              </div>
            </div>
          </Col>
          <Col span={10} className="col-img">
            <div className="phone-box">
              <img className="phoneImg img-fluid" src={phoneSocial} alt="img" />
            </div>
            <div className="blur" />
          </Col>
        </Row>
      </div>
    </StyledShareSocialDesktop>
  );
};

export default ShareSocialDesktop;
