import React from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import appScreen from 'src/assets/images/appScreen2.png';
import graphicLine from 'src/assets/images/graphicLine.png';
import { StyledAppScreenDesktop } from './styled';

const AppScreenDesktop = () => {
  return (
    <StyledAppScreenDesktop>
      <div className="container">
        <Row align="middle">
          <Col span={12} style={{ zIndex: 2 }}>
            <div className="col-content">
              <div className="title">Calo Metaverse</div>
              <div className="title">
                Let's <span className="bold">Move To Earn</span>
              </div>
              <div className="downloadBox flex-start">
                <div className="btn-download">
                  <a
                    href="https://apps.apple.com/us/app/calo-run/id1606108720"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={btnIos} alt="download" />
                  </a>
                </div>
                <div className="btn-download">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.calometaverse"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={btnAndroid} alt="download" />
                  </a>
                </div>
              </div>
              <Link to="/how-to-play">
                <div className="btn-primary">
                  <span>How To Play</span>
                </div>
              </Link>
            </div>
          </Col>
          <Col span={12}>
            <div className="img-wrapper">
              <div className="line">
                <img
                  className="img-fluid"
                  src={graphicLine}
                  alt="graphicLine"
                />
              </div>
              <div className="blur" />
              <img className="img-a" src={appScreen} alt="appScreen" />
            </div>
          </Col>
        </Row>
      </div>
    </StyledAppScreenDesktop>
  );
};

export default AppScreenDesktop;
