import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import IntroductionDesktop from './IntroductionDesktop';
import IntroductionMobile from './IntroductionMobile';

const Introduction = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<IntroductionDesktop {...props} />}
      mobileView={<IntroductionMobile {...props} />}
    />
  );
};

export default Introduction;
