import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import btnIos from 'src/assets/images/btnIos.svg';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import carlLoryDownload from 'src/assets/images/howtoplay/carlLoryDownload.png';

import { StyledDownloadMobile } from './styled';

const DownloadMobile = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledDownloadMobile isDarkMode={isDarkMode}>
      <div className="container">
        <div className="content-wrapper text-center">
          <div className="left">
            <img
              className="contentImg img-fluid"
              src={carlLoryDownload}
              alt="img"
              width={327}
            />
          </div>
          <div className="content-box">
            <div className="title">YOUR STEPS COUNT WITH CALO!</div>
            <div className="btn-download flex-center">
              <div className="ios">
                <a
                  href="https://apps.apple.com/us/app/calo-run/id1606108720"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={btnIos} width={150} />
                </a>
              </div>
              <div className="android">
                <a
                  href="https://play.google.com/store/apps/details?id=com.calometaverse"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={btnAndroid} width={150} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledDownloadMobile>
  );
};

export default DownloadMobile;
