import { Dropdown } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const StyledHeader = styled.header`
  width: 100%;
  padding-bottom: 42px;
  .navContainer {
    padding-top: 24px;
    .navWrapper {
      max-width: 1216px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .navHeader {
      .navBrand {
        .navLogo {
          width: 176px;
        }
      }
    }
  }
  .navMenuCenterContainer {
    display: flex;
    align-items: center;
    /* opacity: ${({ isHomePage }) => (isHomePage ? '1' : '0')}; */
    .navMenuContainer {
      .navMenu {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;

        // Style default nav
        .navLink {
          margin-right: 32px;
          cursor: pointer;
          transition: all ease 0.4s;
          font-weight: 600;
          font-size: 18px;
          text-align: center;
          color: #ffffff;
          &:hover {
            color: #f67e3e;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .navMenuRightContainer {
    display: flex;
    align-items: center;
    .linkTrial {
      cursor: pointer;
      .buttonTrial {
        margin-left: 24px;
        padding: 8px 18.5px;
        background: linear-gradient(180deg, #ffaa4e 0%, #f45c0e 100%);
        box-shadow: 2px 2px 50px -5px rgba(249, 131, 75, 0.8);
        border-radius: 24px;
        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 1.25px;
          color: #ffffff;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .navContainer {
      padding: 24px 20px 0 20px;
    }
    .navMenuCenterContainer {
      .navMenuContainer {
        margin-right: 0;
        .navMenu {
          .navLink {
            margin-right: 20px;
          }
        }
      }
    }
    .navMenuRightContainer {
      .linkTrial {
        .buttonTrial {
          padding: 8px 14.5px;
        }
      }
    }
  }
  @media (max-width: 820px) {
    .navContainer {
      padding: 0 8px;
    }
    .navMenuCenterContainer {
      .navMenuContainer {
        margin-right: 0;
        .navMenu {
          .navLink {
            margin-right: 12px;
            font-size: 16px;
          }
        }
      }
    }
    .navMenuRightContainer {
      .linkTrial {
        .buttonTrial {
          padding: 8px 14.5px;
        }
      }
    }
  }
  .ant-affix {
    z-index: 20;
    .caloAddressContainer {
      box-shadow: rgb(0 35 149 / 8%) 0px 2px 4px;
    }
  }
  .caloAddressContainer {
    min-height: 70px;
    width: 1008px;
    margin: 16px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: linear-gradient(
      180deg,
      #101222 0%,
      rgba(16, 18, 34, 0.24) 100%
    );
    border-radius: 16px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 16px;
      padding: 1.6px;
      background: linear-gradient(
        90.22deg,
        #ffffff 1.48%,
        rgba(255, 255, 255, 0) 33.59%,
        #ffffff 94.31%
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      z-index: 1;
    }
    .contract {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.0125em;
      z-index: 2;
      .label {
        color: ${({ theme }) => theme.title};
      }
      .calo {
        padding: 0 8px 0 14px;
        font-weight: 600;
        color: #ff9e58;
      }
      .fit {
        color: #096cff !important;
      }
      .address {
        font-weight: 600;
        color: ${({ theme }) => theme.description};
      }
      .copy {
        margin-left: 12px;
      }
    }
    .divider {
      margin-left: 26px;
      margin-right: 64px;
    }
    .auditedBox {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 40px;
      background: ${({ theme }) => theme.auditBox};
      box-shadow: ${({ theme }) => theme.auditShadow};
      border-radius: 16px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        border: ${({ theme }) => theme.boxBorder};
        border-radius: 16px;
      }
      span {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.0125em;
        color: #ffffff;
        padding-right: 16px;
      }
      .status {
        position: absolute;
        left: -10%;
        top: -8%;

        min-height: 20px;
        padding: 4px 12px;
        border-radius: 20px;
        box-sizing: border-box;

        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.01em;

        color: ${({ theme }) => theme.badgeTxt};
        border: ${({ theme }) => theme.badgeBorder};
        background: ${({ theme }) => theme.badgeBg};
      }
    }
  }
`;

export const StyledDropdown = styled(Dropdown)``;

export const StyledDropdownOverlay = createGlobalStyle`
  .documents-dropdown {
    z-index: 100;
    width: 160px;
    .ant-dropdown-menu {
      padding: 16px;
      border-radius: 16px;
      background: linear-gradient(210.66deg, rgba(175, 198, 255, 0.157036) 60.09%, rgba(255, 255, 255, 0) 93.19%);
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        border: ${({ theme }) => theme.boxBorder};
        border-radius: 16px;
      }
      .ant-dropdown-menu-title-content {
        transition: all ease 0.4s;
        color: #999999;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        &:hover {
          color: #f67e3e;
        }
      }
      .ant-dropdown-menu-item {
        padding: 0;
        &:not(:last-child) {
          padding: 8px 0;
          border-bottom: 1px solid #808080;
        }
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-top: 8px;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
`;
