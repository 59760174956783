import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useDarkMode } from 'src/hooks/useDarkMode';
import { darkTheme, lightTheme } from 'src/styles/theme-colors';
import BannerHowToPlay from 'src/components/HowToPlay/components/BannerHowToPlay/BannerHowToPlay';
import StepChooseSneaker from 'src/components/HowToPlay/components/StepChooseSneaker/StepChooseSneaker';
import StepFindSneakerMarket from 'src/components/HowToPlay/components/StepFindSneakerMarket/StepFindSneakerMarket';
import StepSneakerDetail from 'src/components/HowToPlay/components/StepSneakerDetail/StepSneakerDetail';
import StepEquipAndMove from 'src/components/HowToPlay/components/StepEquipAndMove/StepEquipAndMove';
import StepMeasure from 'src/components/HowToPlay/components/StepMeasure/StepMeasure';
import StepShareSocial from 'src/components/HowToPlay/components/StepShareSocial/StepShareSocial';
import StepDownload from 'src/components/HowToPlay/components/StepDownload/StepDownload';
import Footer from 'src/components/Footer/Footer';
import iconTopDark from 'src/assets/images/iconTopDark.png';
import iconTopLight from 'src/assets/images/iconTopLight.png';
import Header from 'src/components/Header/Header';
import { Affix, BackTop } from 'antd';
import { StyledLayout } from '../home.styled';
import { useAppStore } from 'src/hooks/useAppStore';

const HowToPlay = (props) => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'dark' ? darkTheme : lightTheme;
  const [, updateAppStore] = useAppStore();

  useEffect(() => {
    updateAppStore((draft) => {
      draft.isDarkMode = true;
    });
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      <StyledLayout>
        <Header theme={theme} toggleTheme={toggleTheme} id="home" />
        <BannerHowToPlay />
        <StepChooseSneaker />
        <StepFindSneakerMarket />
        <StepSneakerDetail />
        <StepEquipAndMove />
        <StepMeasure />
        <StepShareSocial />
        <StepDownload />
        <Footer />
        <Affix offsetBottom={80}>
          <BackTop>
            <img
              src={theme === 'dark' ? iconTopDark : iconTopLight}
              width="56px"
              height="56px"
            />
          </BackTop>
        </Affix>
      </StyledLayout>
    </ThemeProvider>
  );
};

export default HowToPlay;
