import styled from 'styled-components';

export const StyledTokenV2Mobile = styled.section`
  padding-top: 64px;
  position: relative;
  z-index: 3;
  .container {
    max-width: 1216px;
    margin: auto;
    flex-direction: column;
    .title {
      padding: 0 24px;
      @media (min-width: 768px) {
        img {
          width: 260px;
        }
      }
    }
    .tokens {
      .token-wrapper {
        margin-top: 100px;
        .blur-bg {
          position: absolute;
          z-index: 1;
        }
        .calo {
          z-index: 3;
        }
        .fit {
          margin-top: -100px;
          margin-left: -40px;
          z-index: 2;
          .token-img {
            width: 160px !important;
          }
        }
        .token-item {
          .token-img {
            width: 160px;
          }
          .name {
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #f45c0e;
            margin-top: 16px;
            margin-bottom: 8px;
            text-align: center;
          }
          .desc {
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.0015em;
            color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#4D4D4D')};
            text-align: center;
            padding-top: 8px;
          }
        }
      }
    }
  }
`;
