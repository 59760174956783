import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import strongGPS from 'src/assets/images/howtoplay/strongGPS.png';
import speedometer from 'src/assets/images/howtoplay/speedometer.png';
import runningMeasureMb from 'src/assets/images/howtoplay/runningMeasureMb4.png';
import measureTextMb from 'src/assets/images/howtoplay/measureTextMb3.png';
import { StyledMeasureMobile } from './styled';

const MeasureMobile = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledMeasureMobile isDarkMode={isDarkMode}>
      <div className="container">
        <div className="title">MEASURE RUNNING OUTDOOR WITH GPS</div>
        <div className="info-wrapper">
          <div className="flex-center item">
            <img className="icon" src={strongGPS} alt="gps" width={40} />
            <div className="text-desc">Strong GPS</div>
          </div>
          <div className="flex-center item">
            <div className="text-desc">Ensure</div>
            <span className="dot-running" />
            <div className="text-desc">Running</div>
          </div>
          <div className="flex-center item">
            <img
              className="icon"
              src={speedometer}
              alt="speedometer"
              width={30}
            />
            <div className="text-desc">Run within orange zone</div>
          </div>
        </div>
        <div className="content-wrapper phone flex-center">
          <div className="blur" />
          <img className="img-fluid" src={runningMeasureMb} alt="img-detail" />
        </div>
        <div className="content-wrapper flex-center">
          <img className="img-fluid" src={measureTextMb} alt="img-detail" width={288} />
        </div>
      </div>
    </StyledMeasureMobile>
  );
};

export default MeasureMobile;
