import styled from 'styled-components';

export const StyledDocuments = styled.section`
  padding: 64px 0;
  position: relative;
  z-index: 3;
  .container {
    max-width: 1216px;
    margin: auto;
    .documentsSlider {
      margin-top: 100px;
    }
    .documentsTitle {
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      color: ${({ theme }) => theme.title};
      padding-bottom: 8px;
    }
    .documentsDesc {
      max-width: 680px;
      margin: auto;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.0125em;
      color: ${({ theme }) => theme.description};
    }
    .documentItem {
      position: relative;
      top: 0;
      transition: top ease 0.5s;
      background: ${({ theme }) => theme.itemBg};
      border-radius: 16px;
      padding: 62px 0 62px 59px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        border: ${({ theme }) => theme.boxBorder};
        border-radius: 16px;
        z-index: -1;
      }
      .itemInfo {
        display: flex;
        align-items: center;
        .icon {
        }
        .itemDetail {
          margin-left: 16px;
          .title {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.0015em;
            color: ${({ theme }) => theme.itemTitle};
          }
          .btnText {
            margin-top: 16px;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: ${({ theme }) => theme.itemText};
          }
        }
      }
      &:hover {
        position: relative;
        top: -24px;
        transition: top ease 0.5s;
        opacity: 1 !important;
      }
    }
    .slick-list {
      padding-top: 24px;
      &:hover {
        .documentItem {
          opacity: 0.5;
        }
      }
    }
    @media (max-width: 1024px) {
      .documentItem {
        padding: 40px 20px;
      }
    }
  }
`;
