import React, { useEffect, useState } from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import TopicsDesktop from './TopicsDesktop';
import TopicsMobile from './TopicsMobile';

const Topics = (props) => {
  const [topics, setTopics] = useState([]);
  const [statistic, setStatistic] = useState();

  const getStatistic = async () => {
    const url = 'https://api.calo.run/public.statics';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    setStatistic(data);
  };

  const getTopics = async () => {
    try {
      const res = await fetch(
        'https://editor.calo.run/api/categories?filters%5Bslug%5D=news&populate%5Barticles%5D%5Bpopulate%5D=%2A',
      );
      const articles = await res.json();
      const topicsFeatured =
        articles?.data[0]?.attributes?.articles?.data.filter(
          (article) => article?.attributes?.isFeatured,
        );
      setTopics(
        topicsFeatured.sort(
          (a, b) =>
            new Date(b.attributes.publishedAt) -
            new Date(a.attributes.publishedAt),
        ),
      );
    } catch (err) {
      setTopics([]);
    }
  };

  useEffect(() => {
    getTopics();
    getStatistic();
  }, []);

  return (
    <div>
      <ResponsiveLayout
        desktopView={
          <TopicsDesktop topics={topics} statistic={statistic} {...props} />
        }
        mobileView={
          <TopicsMobile topics={topics} statistic={statistic} {...props} />
        }
      />
    </div>
  );
};

export default Topics;
