import styled from 'styled-components';
import sneakerDetailBgMb from 'src/assets/images/howtoplay/sneakerDetailBgMb.png';

export const StyledSneakerDetailMobile = styled.div`
  padding: 64px 0;
  background-image: url(${sneakerDetailBgMb});
  background-size: cover;
  background-repeat: round;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.0015em;
      color: #ffffff;
      padding-bottom: 16px;
    }
    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #e5e5e5;
    }
    .content-wrapper {
      margin-top: 20px;
      .box-img {
        margin-bottom: 24px;
      }
      .img-detail {
        img {
          max-width: 300px;
        }
      }
    }
  }
`;
