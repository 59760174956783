import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import SneakerDetailDesktop from './SneakerDetailDesktop';
import SneakerDetailMobile from './SneakerDetailMobile';

const StepSneakerDetail = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<SneakerDetailDesktop {...props} />}
      mobileView={<SneakerDetailMobile {...props} />}
    />
  );
};

export default StepSneakerDetail;