import Slider from 'react-slick';
import styled from 'styled-components';

export const StyledSliderSlick = styled(Slider)`
  .slick-slide {
    padding-left: ${(props) =>
    props['data-slide-paddingX'] ? props['data-slide-paddingX'] : 'unset'};
    padding-right: ${(props) =>
    props['data-slide-paddingX'] ? props['data-slide-paddingX'] : 'unset'};
    padding-top: ${(props) =>
    props['data-slide-paddingY'] ? props['data-slide-paddingY'] : 'unset'};
    padding-bottom: ${(props) =>
    props['data-slide-paddingY'] ? props['data-slide-paddingY'] : 'unset'};
  }
  .slick-prev {
    &::before {
      content: '';
    }
  }
  .slick-next {
    &::before {
      content: '';
    }
  }
  .slick-dots {
    ul {
      padding-left: 0;
    }
    li {
      /* width: 46px; */
    }
    @media (max-width: 992px) {
      li {
        margin: 0px 12px;
        width: 20px;
      }
    }
    @media (max-width: 428px) {
      li {
        margin: 0px 4px;
        width: 16px;
      }
    }
    .slick-active > div {
      background-color: #e8773d !important;
    }
  }
`;

export const StyledSliderArrow = styled.div`
  z-index: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  top: ${(props) => (props['data-top'] ? props['data-top'] : '50%')};
  width: 30px;
  height: 30px;
  &::before {
    content: unset;
  }

  img {
    width: 100%;
  }
`;
