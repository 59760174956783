import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import phoneDetailMbSm from 'src/assets/images/howtoplay/phoneDetailMb4.png';
import phoneDetailTextMb from 'src/assets/images/howtoplay/phoneDetailTextMb5.png';
import { StyledSneakerDetailMobile } from './styled';

const SneakerDetailMobile = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledSneakerDetailMobile isDarkMode={isDarkMode}>
      <div className="container">
        <div className="title">SNEAKER DETAIL PAGE</div>
        <div className="content-wrapper text-center">
          <div className="box-img img-detail">
            <img className="img-fluid" src={phoneDetailMbSm} alt="img-detail" />
          </div>
          <div className="box-img">
            <img className="img-fluid" src={phoneDetailTextMb} alt="img-text" width={300} />
          </div>
        </div>
      </div>
    </StyledSneakerDetailMobile>
  );
};

export default SneakerDetailMobile;
