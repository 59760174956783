import React from 'react';
import carlLory from 'src/assets/images/carlLorySocialNew.png';
import { StyledSocialMobile } from './styled';
import { useAppStore } from 'src/hooks/useAppStore';
import { socials } from '../socials';

const SocialMobile = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledSocialMobile isDarkMode={isDarkMode}>
      <div className="container">
        <div className="containerTop">
          <div className="title">JOIN OUR COMMUNITY</div>
          <div className="description">
            Connect with Calo community to update the newest thing about the
            market and our business.
          </div>
        </div>
        <div className="containerBottom">
          <div className="icon-wrapper">
            {socials.map((social, index) => (
              <a
                className="image-social"
                href={social.url}
                target="_blank"
                rel="noreferrer"
              >
                <img src={social.iconWhite} />
              </a>
            ))}
          </div>
          <div className="mainObject">
            <img className='img-fluid' src={carlLory} />
          </div>
        </div>
      </div>
    </StyledSocialMobile>
  );
};

export default SocialMobile;
