import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import LoadingContainer from './components/LoadingContainer';
import Home from './pages/Home';
import HowToPlay from './pages/HowToPlay/HowToPlayPage';
import LitePaper from './pages/LitePaper';
import PrivacyPage from './pages/Privacy/PrivacyPage';
import TermPage from './pages/Term/TermPage';

// const Home = lazy(() => import('./pages/Home'));
// const PrivacyPage = lazy(() => import('./pages/Privacy/PrivacyPage'));
// const TermPage = lazy(() => import('./pages/Term/TermPage'));
// const LitePaper = lazy(() => import('./pages/LitePaper'));

export const StyleGlobalTooltip = createGlobalStyle`
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner{
    border-radius: 6px;
    background-color: #171f36;
    font-style: normal;
    font-weight: normal;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
  }
`;

const App = () => {
  return (
    <>
      <StyleGlobalTooltip />
      <BrowserRouter>
        {/* <Suspense fallback={<LoadingContainer />}> */}
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/privacy-policy" exact>
          <PrivacyPage />
        </Route>
        <Route path="/terms-and-conditions" exact>
          <TermPage />
        </Route>
        <Route path="/onepaper" exact>
          <LitePaper />
        </Route>
        <Route path="/how-to-play" exact>
          <HowToPlay />
        </Route>
        {/* </Suspense> */}
      </BrowserRouter>
    </>
  );
};

export default App;
