import styled, { css } from 'styled-components';

export const StyledChooseSneakerMobile = styled.div`
  padding: 48px 0;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .py-10 {
      padding: 0 10px;
    }
    .main-title {
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      text-align: center;
      color: #ff8a34;
    }
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      padding-bottom: 24px;
    }
    .desc {
      max-width: 282px;
      margin: 0 auto;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: center;
      color: #e5e5e5;
    }
    .boxContainer {
      margin-top: 24px;
      .boxItemsGrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;

const sneakerTypes = {
  Walker: css`
    color: #cfddff;
  `,
  Jogger: css`
    color: #9bfebc;
  `,
  Runner: css`
    color: #d99bff;
  `,
  Trainer: css`
    color: #ffaa4e;
  `,
};

export const SneakerBoxItem = styled.div`
  background: #1B2030;
  border-radius: 14px;
  width: 280px;
  padding-top: 8px;
  .boxImg {
    img {
      width: 168px;
      height: 168px;
    }
  }
  .type {
    padding: 14px 0;
    width: 100%;
    border-bottom: 1.7622px solid #2b3755;
    .name {
      padding-left: 12px;
      font-weight: 500;
      font-size: 21.1463px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.704878px;
      ${({ type }) => sneakerTypes[type]};
    }
  }
  .speed {
    font-weight: 500;
    font-size: 21.1463px;
    line-height: 25px;
    text-align: center;
    color: #f2f2f2;
    margin-bottom: 16px;
  }
  .earn {
    border-top: 1.7622px solid #2B3755;
    padding: 18px 0;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    .equal {
      margin: 0 6px;
    }
    img {
      margin-left: 6px;
      width: 24px;
    }
  }
`;
