import React from 'react';
import ResponsiveLayout from '../ResponsiveLayout';
import FooterDesktop from './FooterDesktop';
import FooterMobile from './FooterMobile';

const Footer = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<FooterDesktop {...props} />}
      mobileView={<FooterMobile {...props} />}
    />
  );
};

export default Footer;
