import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import phoneEquipMb from 'src/assets/images/howtoplay/phoneEquip5.png';
import phoneEquipTextMb from 'src/assets/images/howtoplay/phoneEquipTextMb3.png';
import { StyledEquipMoveMobile } from './styled';

const EquipAndMoveMobile = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledEquipMoveMobile isDarkMode={isDarkMode}>
      <div className="container">
        <div className="info-content">
          <div className="title">
            <span className="bold">EQUIP</span>
            <span>your sneaker</span>
          </div>
          <div className="title">
            <span className="bold">MOVE</span>
            <span>outdoors</span>
          </div>
          <div className="title">
            <span className="bold">BURN</span>
            <span>stamina</span>
          </div>
          <div className="title">
            <span className="bold">EARN</span>
            <span>sexy rewards</span>
          </div>
        </div>
        <div className="content-wrapper text-center">
          <div className="box-img box-wrapper">
            <div className="blur" />
            <img className="img-fluid" src={phoneEquipMb} alt="img-detail" />
          </div>
          <div className="box-img box-text">
            <img className="img-fluid" src={phoneEquipTextMb} alt="img-text" />
          </div>
        </div>
      </div>
    </StyledEquipMoveMobile>
  );
};

export default EquipAndMoveMobile;
