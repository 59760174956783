import styled from 'styled-components';

export const StyledBannerLunarGacha23 = styled.div`
  max-width: 1224px;
  margin: 0 auto;
  width: 100%;
  .rowContainer {
    height: 100%;
    .imageColumn {
      position: relative;
      .calo {
        position: absolute;
        bottom: 100px;
        left: -50px;
        width: 117px;
      }
      .fit {
        position: absolute;
        top: 150px;
        right: 500px;
        width: 70px;
      }
      .image-box {
        position: relative;
        right: -80px;
        img {
          max-width: 668px;
          @media (max-width: 1024px) {
            max-width: 438px;
            right: 0;
          }
        }
      }
    }

    .contentColumn {
      padding-top: 24px;
      position: relative;
      .title {
        img {
          max-width: 638px;
          @media (max-width: 1024px) {
            max-width: 438px;
          }
        }
      }
      .desc {
        max-width: 395px;
      }
      .downloadBox {
        margin-top: 40px;
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
    }
  }
`;
