import { Col, Row } from 'antd';
import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import titleHTP from 'src/assets/images/howtoplay/titleHTP.png';
import loryHTP from 'src/assets/images/howtoplay/loryHTP.png';
import { StyledBannerDesktop } from './styled';

const BannerDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledBannerDesktop isDarkMode={isDarkMode}>
      <div className='banner-container'>
        <Row className="wrapper" align="middle" justify="center">
          <Col span={16} className="col-left">
            <img className='title-img img-fluid' src={titleHTP} alt="title" />
          </Col>
          <Col span={8}>
            <img className='carl-img img-fluid' src={loryHTP} alt="title" />
          </Col>
        </Row>
      </div>
    </StyledBannerDesktop>
  );
};

export default BannerDesktop;
