import { Layout } from 'antd';
import styled from 'styled-components';
import homeBannerBg from 'src/assets/images/homeBannerBg.png';
import bannerXmas from 'src/assets/images/introduction/desktop/xmas/bannerXmas.png';
import bannerXmasMb from 'src/assets/images/introduction/mobile/xmas/bannerXmasMb.png';
import bannerLunar from 'src/assets/images/introduction/desktop/lunar/bannerLunar1.png';
import bannerLunarMb from 'src/assets/images/introduction/mobile/lunar/bannerLunarMb.png';
import litepaperBannerBg from 'src/assets/images/litepaperBannerBg.png';
import introLPBgMb from 'src/assets/images/introLPBgMb.png';

export const StyledLayout = styled(Layout)`
  overflow: hidden;
  background: ${({ theme }) => theme.body};
  min-height: 100vh;
  .intro-section {
    background-image: url(${bannerLunar});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    @media (max-width: 768px) {
      background-image: url(${bannerLunarMb});
    }
  }
  .intro-section-lite {
    background-image: url(${litepaperBannerBg});
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 768px) {
      background-image: url(${introLPBgMb});
    }
    @media (max-width: 568px) {
      background-size: contain;
      background-position: center;
    }
  }
`;

export const StyledAnimatedCursor = styled.div`
  > div {
    border: ${({ theme }) => theme.cursorBorder};
    border-inline-style: solid;
    background-color: rgba(255, 255, 255, 0.01);
  }
`;
