import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import { scrollTo } from 'src/utils';
import caloLogo from 'src/assets/images/caloLogo.svg';
import iconDownload from 'src/assets/images/iconDownload.svg';
import coinGeckoLogo from 'src/assets/images/coinGeckoLogoWhite.png';
import cmcLogo from 'src/assets/images/cmcLogo.png';
import { StyledFooter, StyledInputEmail } from './styled';

const FooterDesktop = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isHomePage = pathname === '/';

  const navigateScroll = (toId, toRef, duration) =>
    scrollTo({ id: toId, ref: toRef, duration });

  const onNavigatePage = (id) => {
    if (isHomePage) {
      navigateScroll(id);
    } else {
      history.push(`/#${id}`);
    }
  };

  return (
    <StyledFooter>
      <div className="container">
        <Row gutter={0}>
          <Col lg={5} xl={4}>
            <div className="caloInfo">
              <img className="footerLogo" src={caloLogo} />
            </div>
            <div className="copyright">© 2021 Calo Co.</div>
          </Col>
          <Col lg={7} xl={9}>
            <div className="companyInfo">
              <div>Metaverse Application Pte, Ltd.</div>
              <div>UEN: 202142943M</div>
              <div>1B Trengganu Street Singapore (058455)</div>
              <div>
                <a href="mailto:hi@calo.run" target="_blank" rel="noreferrer">
                  hi@calo.run
                </a>
              </div>
            </div>
            <div className="data-provided">
              <p className="text">Data provided by</p>
              <div className="flex-start">
                <a
                  href="https://www.coingecko.com/en/coins/calo"
                  target="_blank"
                  rel="noreferrer"
                  className="coingecko"
                >
                  <img className="img-fluid" src={coinGeckoLogo} />
                </a>
                <a
                  href="https://coinmarketcap.com/currencies/calo-app/"
                  target="_blank"
                  rel="noreferrer"
                  className="cmc"
                >
                  <img className="img-fluid" src={cmcLogo} />
                </a>
              </div>
            </div>
          </Col>
          <Col lg={3} xl={3}>
            <ul className="footerMenu">
              {/* <li className="footerLink" onClick={() => navigateScroll('home')}>
                <a>Home</a>
              </li> */}
              <li
                className="footerLink"
                onClick={() => onNavigatePage('gameplay')}
              >
                <a>Gameplay</a>
              </li>
              <li
                className="footerLink"
                onClick={() => onNavigatePage('nft-system')}
              >
                <a>NFT Systems</a>
              </li>
              <li className="footerLink" onClick={() => onNavigatePage('team')}>
                <a>Team</a>
              </li>
            </ul>
          </Col>
          <Col lg={3} xl={3}>
            <ul className="footerMenu">
              <li className="footerLink">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="footerLink">
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
            </ul>
          </Col>
          <Col lg={5} xl={5}>
            <a href="https://dl.calo.run/calo_media_kit.zip" download>
              <div className="btn-download flex-center">
                <img className="icon" src={iconDownload} alt="icon" />
                <span className="label">DOWNLOAD MEDIA KIT</span>
              </div>
            </a>
          </Col>
        </Row>
      </div>
    </StyledFooter>
  );
};

export default FooterDesktop;
