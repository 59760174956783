import styled from 'styled-components';

export const StyledAppScreenDesktop = styled.div`
  padding: 100px 0;
  max-width: 1216px;
  margin: 0 auto;
  .container {
    padding: 0;
    .col-content {
      .title {
        font-weight: 600;
        font-size: 60px;
        line-height: 68px;
        color: #ffffff;
        .bold {
          color: #ff8a34;
        }
        @media (max-width: 1024px) {
          font-size: 50px;
        }
      }
      .downloadBox {
        padding-top: 32px;
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
      .btn-primary {
        margin-top: 32px;
        padding: 8px 18.5px;
        background: linear-gradient(180deg, #ffaa4e 0%, #f45c0e 100%);
        box-shadow: 2px 2px 50px -5px rgba(249, 131, 75, 0.8);
        border-radius: 24px;
        max-width: fit-content;
        cursor: pointer;
        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 1.25px;
          color: #ffffff;
        }
      }
    }
    .img-wrapper {
      text-align: right;
      .line {
        position: absolute;
        top: 0;
        left: -10%;
        filter: blur(5px);
        z-index: 0;
      }
      .blur {
        z-index: 1;
        position: absolute;
        width: 700px;
        height: 700px;
        border-radius: 50%;
        background: #425687;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: blur(250px);
        @media (max-width: 1440px) {
          width: 400px;
          height: 400px;
          filter: blur(150px);
        }
      }
      .img-a {
        max-width: 800px;
        position: relative;
        z-index: 2;
        @media (max-width: 1440px) {
          /* max-width: 714px; */
        }
        @media (max-width: 1024px) {
          max-width: 511px;
        }
      }
    }
  }
`;
