import styled from 'styled-components';
import sneakerDetailBg from 'src/assets/images/howtoplay/sneakerDetailBg.png';

export const StyledSneakerDetailDesktop = styled.div`
  padding: 80px 0 200px 0;
  background-image: url(${sneakerDetailBg});
  background-size: cover;
  background-repeat: round;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .title {
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #ffffff;
      padding-bottom: 16px;
    }
    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #e5e5e5;
    }
    .content-wrapper {
      margin: 60px 0;
    }
  }
`;
