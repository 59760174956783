import React, { useEffect } from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import phoneMarket from 'src/assets/images/howtoplay/phoneMarket.png';
import sneakerCard from 'src/assets/images/howtoplay/sneakerCard.png';
import shoeboxCard from 'src/assets/images/howtoplay/shoeboxCard.png';
import lineTopRight from 'src/assets/images/benefits/desktop/lineTopRight2.svg';
import { StyledFMSDesktop } from './styled';

const FindSneakerMarketDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledFMSDesktop isDarkMode={isDarkMode}>
      <div className="container">
        <div className="main-title">FIND A SNEAKER</div>
        <div className="title">From the in-app marketplace</div>
        <div className="content-wrapper flex-center">
          <div className="phone-box">
            <img className="phoneImg img-fluid" src={phoneMarket} alt="img" />
            <div className="line">
              <img src={lineTopRight} width={160} />
            </div>
            <div className="blur" />
          </div>
          <div className="content-box">
            <div className="info">
              <div className="title">Search Sneaker</div>
              <div className="flex-start info-item">
                <div className="box-img">
                  <img src={sneakerCard} alt="box-img" />
                </div>
                <div className="desc">
                  Own more sneakers to gain higher energy level
                </div>
              </div>
              <div className="flex-start info-item">
                <div className="box-img">
                  <img src={shoeboxCard} alt="box-img" />
                </div>
                <div className="desc">
                  Shoebox contains one sneaker with random type and quality
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFMSDesktop>
  );
};

export default FindSneakerMarketDesktop;
