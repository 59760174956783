import styled from 'styled-components';

export const StyledPartnersMobile = styled.section`
  padding: 64px 0;
  margin-top: -1px;
  position: relative;
  z-index: 3;
  .container {
    .header {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-align: center;
      color: ${({ theme }) => theme.header};
      padding-bottom: 8px;
    }
    .title {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      text-align: center;
      color: ${({ theme }) => theme.title};
      padding-bottom: 16px;
    }

    .partners {
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 24px;
    }

    .slider-partner {
      .slick-dots {
        bottom: -60px;
      }
    }

    .partnerCard {
      .cardWrapper {
        height: 100%;
        width: 100%;
        background: transparent;
        border-radius: 16px;
        position: relative;
        .cardImage {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100px;
          }
          @media (max-width: 375px) {
            img {
              max-width: 80px;
            }
          }
        }
      }
    }
    :global(.slick-slide) {
      > div {
        display: flex;
        align-items: flex-start;
      }
    }
  }
  .partners-container {
    .slider-partner {
      .partnerCard {
        .cardWrapper {
          height: 100%;
          width: 100%;
          background: transparent;
          border-radius: 16px;
          position: relative;
          .cardImage {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              max-width: 100px;
              /* filter: grayscale(1); */
            }
            @media (max-width: 428px) {
              img {
                max-width: 95px;
              }
            }
            @media (max-width: 414px) {
              img {
                max-width: 85px;
              }
            }
            @media (max-width: 375px) {
              img {
                max-width: 80px;
              }
            }
            @media (max-width: 320px) {
              img {
                max-width: 70px;
              }
            }
          }
        }
      }
    }
  }
`;
