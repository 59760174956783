import styled from 'styled-components';

export const StyledMetaverse = styled.section`
  padding: 100px 0;
  position: relative;
  .backgroundLayer {
    position: absolute;
    width: 100%;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    .line {
      text-align: center;
      opacity: 0.5;
    }
  }
  .blur {
    width: 917px;
    height: 917px;
    border-radius: 50%;
    background: #425687;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(350px);
  }
  .groupCircle {
    position: absolute;
    left: 0;
    top: 150px;
    img {
      width: 100%;
    }
  }
  .curvedLine {
    position: absolute;
    right: 0;
    top: 250px;
    img {
      width: 100%;
    }
  }
  .container {
    max-width: 1216px;
    margin: auto;
    position: relative;
    .title {
      font-weight: 600;
      font-size: 60px;
      line-height: 68px;
      text-align: center;
      color: #ffffff;
      padding-bottom: 10px;
    }
    .rowContent {
      margin-top: 100px;
      display: flex !important;
      .contentTitle {
        font-style: italic;
        font-weight: 600;
        font-size: 34px;
        line-height: 40px;
        letter-spacing: -1.5px;
        color: #ffffff;
        padding-top: 50px;
        padding-bottom: 30px;
      }
      .contentDesc {
        max-width: 488px;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.4px;
        color: #bfbfbf;
        padding-top: 12px;
      }
      .link {
        font-weight: 600;
        font-size: 14px;
        color: #ff9e58;
        text-decoration: underline;
      }
      .next {
        cursor: pointer;
        margin-bottom: 16px;
      }

      .contentImage {
        position: relative;
        .mainImage {
          width: 609px;
          height: 696px;
        }
      }
    }

    @media (max-width: 1024px) {
      .rowContent {
        padding: 0 30px;
        .contentImage {
          img {
            width: 90%;
          }
        }
      }
    }

    .buttonGroup {
      position: absolute;
      bottom: 200px;
      display: flex;
      align-items: center;
      .btnItem {
        .btnIcon {
          width: 24px;
          cursor: pointer;
        }
      }
      .slideCounter {
        margin: 0 51.5px;
        color: #f89462;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.0125em;
      }
    }

    @media (max-width: 1024px) {
      .buttonGroup {
        bottom: 0;
      }
    }
  }
`;
