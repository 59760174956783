import { Switch } from 'antd';
import styled from 'styled-components';

export const StyledSwitch = styled(Switch)`
  transform: rotateY(180deg);
  &.ant-switch {
    min-width: 40px;
    height: 24px;
    line-height: 24px;
    background-color: #4d4d4d;
    .ant-switch-handle {
      top: 4px;
      width: 16px;
      height: 16px;
    }
    .ant-switch-inner {
      margin-top: -2px;
      transform: rotateY(180deg);
    }
  }
  &.ant-switch-checked {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.35),
        rgba(255, 255, 255, 0.35)
      ),
      #f45c0e;
  }
`;
