import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Affix, Tooltip } from 'antd';
import { useAppStore } from 'src/hooks/useAppStore';
import { scrollTo } from 'src/utils';
import Switch from 'src/components/Switch';
import caloLogo from 'src/assets/images/caloLogo.svg';
import iconMenuDark from 'src/assets/images/iconMenuDark.png';
import iconMenuLight from 'src/assets/images/iconMenuLight.png';
import iconCopyAddress from 'src/assets/images/iconCopyAddress.svg';
import certikDark from 'src/assets/images/logoCertik.svg';
import divider from 'src/assets/images/divider.svg';
import { truncateAddress } from 'src/utils/text';
import { caloAddress, fitAddress } from 'src/variables/calo';
import { StyledHeader, StyledDrawer } from './styled';

const HeaderMobile = (props) => {
  const [open, setOpen] = useState(false);
  const { theme, toggleTheme, id } = props;
  const [appStore, updateAppStore] = useAppStore();
  const { isDarkMode } = appStore;
  const history = useHistory();
  const { pathname } = useLocation();

  const isHomePage = pathname === '/';

  const noSwitchPath = ['/how-to-play', '/onepaper'];

  const navigateScroll = (toId, toRef, duration) =>
    scrollTo({ id: toId, ref: toRef, duration });

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const onNavigate = (section) => {
    setOpen(false);
    navigateScroll(section);
  };

  const onNavigatePage = (id) => {
    if (isHomePage) {
      onNavigate(id);
    } else {
      history.push(`/#${id}`);
    }
  };

  const onToggleTheme = () => {
    toggleTheme();
    updateAppStore((draft) => {
      draft.isDarkMode = theme === 'dark' ? false : true;
    });
  };

  const [copied, setCopied] = useState(false);

  const copyAddress = (address) => {
    setCopied(true);
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(address);
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('input');
      ele.value = address;
      document.body.appendChild(ele);
      ele.select();
      document.execCommand('copy');
      document.body.removeChild(ele);
    }
  };

  return (
    <StyledHeader id={id}>
      <Affix offsetTop={0}>
        <div className="caloAddressContainer">
          <div className='box-contract'>
            <div className="contract">
              <span className="calo"> CALO </span>
              <a
                href={`https://bscscan.com/token/${caloAddress}`}
                target="_blank"
                rel="noreferrer"
              >
                <span className="address">
                  {truncateAddress(caloAddress, 12, '...')}
                </span>
              </a>
              <Tooltip
                title={copied ? 'Copied' : 'Copy'}
                onVisibleChange={(visible) => {
                  if (!visible) {
                    setCopied(false);
                  }
                }}
                placement="bottom"
              >
                <img
                  className="copy link"
                  src={iconCopyAddress}
                  onClick={() => copyAddress(caloAddress)}
                  width="24px"
                />
              </Tooltip>
            </div>
            <div className="contract">
              <span className="calo fit"> FIT </span>
              <a
                href={`https://bscscan.com/token/${fitAddress}`}
                target="_blank"
                rel="noreferrer"
              >
                <span className="address">
                  {truncateAddress(fitAddress, 12, '...')}
                </span>
              </a>
              <Tooltip
                title={copied ? 'Copied' : 'Copy'}
                onVisibleChange={(visible) => {
                  if (!visible) {
                    setCopied(false);
                  }
                }}
                placement="bottom"
              >
                <img
                  className="copy link"
                  src={iconCopyAddress}
                  onClick={() => copyAddress(fitAddress)}
                  width="24px"
                />
              </Tooltip>
            </div>
          </div>
          <div className="divider">
            <img src={divider} alt="divider" />
          </div>
          <a
            href="https://www.certik.com/projects/calo"
            target="_blank"
            rel="noreferrer"
          >
            <div className="auditedBox">
              <img src={certikDark} alt="certik" />
            </div>
          </a>
        </div>
      </Affix>
      <div className="headerWrapper flex-center">
        <a href="/" className="navBrand">
          <img
            className="navLogo img-fluid"
            src={caloLogo}
          />
        </a>
        <div className="menuIcon" onClick={toggleDrawer}>
          <img
            className="navBar"
            src={isDarkMode ? iconMenuDark : iconMenuLight}
          />
        </div>
        {/* <div className="switchThemeBtn">
          {!noSwitchPath.includes(pathname) && (
            <Switch checked={isDarkMode} onChange={onToggleTheme} />
          )}
        </div> */}
      </div>
      <StyledDrawer
        mask
        width={280}
        zIndex={1000}
        closable={false}
        placement={'left'}
        onClose={toggleDrawer}
        visible={open}
      >
        <div className="navMenuContainer">
          <ul className="navMenu">
            <li className="navLink" onClick={() => onNavigatePage('home')}>
              Home
            </li>
            <li className="navLink" onClick={() => onNavigatePage('gameplay')}>
              Gameplay
            </li>
            <li className="navLink" onClick={() => onNavigatePage('nft-system')}>
              NFTs System
            </li>
            <li className="navLink" onClick={() => onNavigatePage('team')}>
              Team
            </li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.calo.run/app/"
            >
              <li className="navLink">
                Whitepaper
              </li>
            </a>
            <Link to="/onepaper" className="navLink">
              Onepaper
            </Link>
            <Link to="/how-to-play" className="navLink">
              How to play
            </Link>
            {/* <li className="navLink">How to play</li> */}
          </ul>
        </div>
        <a href="https://dapps.calo.run/" target="_blank" rel="noreferrer">
          <div className="buttonTrial">
            <span>Enter Dapp</span>
          </div>
        </a>
        <a href="https://blog.calo.run" target="_blank" rel="noreferrer">
          <div className="buttonTrial">
            <span>Blog</span>
          </div>
        </a>
      </StyledDrawer>
    </StyledHeader>
  );
};

export default HeaderMobile;
