import styled from 'styled-components';

export const StyledTeamMobile = styled.section`
  padding: 64px 0 100px 0;
  margin-top: -2px;
  position: relative;
  z-index: 3;
  .teamBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: lighten;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    .header {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-align: center;
      color: ${({ theme }) => theme.header};
      padding-bottom: 12px;
    }
    .teamTitle {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      text-align: center;
      color: ${({ theme }) => theme.title};
      padding-bottom: 16px;
    }
    .description {
      max-width: 340px;
      margin: auto;
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: ${({ theme }) => theme.description};
    }

    .advisors {
      padding-top: 64px;
    }

    .teamSlider {
      .slick-slide {
        > div {
          display: flex;
          align-items: flex-start;
        }
      }
      .teamCard {
        text-align: center;
        margin: 32px 0;
        .cardImage {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 90%;
          }
        }
      }
      .teamInfo {
        padding-top: 24px;
        .position {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #f45c0e;
          margin-bottom: 8px;
        }
        .name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.0125em;
          text-align: center;
          color: ${({ theme }) => theme.infoName};
        }
        .jobDesc {
          max-width: 196px;
          margin: 0 auto;
          padding-top: 8px;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.4px;
          color: ${({ theme }) => theme.infoJobDesc};
        }
        .link {
          padding-top: 16px;
        }
      }

      @media (min-width: 767px) {
        .teamInfo {
          /* margin-bottom: 64px; */
        }
      }
      @media (max-width: 428px) {
        .teamInfo {
          padding-top: 32px;
          .name {
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.4px;
            color: #ffffff;
          }
          .position {
            margin-top: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.4px;
            color: #f45c0e;
          }
          .jobDesc {
            padding-top: 12px;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            text-align: center;
            color: #8c8c8c;
          }
        }
      }
      @media (max-width: 320px) {
        .teamInfo {
          .position {
            font-size: 12px;
          }
          .name {
            font-size: 14px;
          }
        }
      }
    }
  }
`;
