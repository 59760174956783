import React from 'react';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import { StyledIntroduction } from './styled';
import BannerBetaPhase2 from '../BannerScreen/BannerScreenDesktop/BannerBetaPhase2';
import HeaderDesktop from 'src/components/Header/HeaderDesktop';
import BannerF2P from '../BannerScreen/BannerScreenDesktop/BannerF2P';
import BannerXmas from '../BannerScreen/BannerScreenDesktop/BannerXmas';
import BannerLunarGacha23 from '../BannerScreen/BannerScreenDesktop/BannerLunarGacha23';

const IntroductionDesktop = (props) => {
  const slideSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    rows: 1,
    slidesPerRow: 1,
    appendDots: (dots) => (
      <div
        className="link"
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="link"
        style={{
          width: '46px',
          height: '4px',
          marginRight: '12px',
          backgroundColor: '#AAB2C8',
          borderRadius: '4px',
        }}
      ></div>
    ),
  };

  return (
    <>
      <HeaderDesktop />
      <StyledIntroduction>
        <SliderSlick settings={slideSettings} paddingX="0px">
          {/* <BannerBetaPhase2 /> */}
          <BannerLunarGacha23 />
        </SliderSlick>
      </StyledIntroduction>
    </>
  );
};

export default IntroductionDesktop;
