import styled from 'styled-components';

export const StyledSocial = styled.section`
  height: 900px;
  position: relative;
  z-index: 3;
  mix-blend-mode: ${({ isDarkMode }) => (isDarkMode ? 'lighten' : 'unset')};
  .blur {
    position: absolute;
    right: -316.5px;
    width: 633px;
    height: 633px;
    background: #ff8a34;
    filter: blur(300px);
  }
  .container {
    height: 100%;
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .ant-row {
      height: 100%;
    }
    .containerLeft {
      height: 100%;
      max-width: 506px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .object {
        margin-bottom: 20px;
        img {
          width: 177px;
        }
      }
      .title {
        font-weight: 600;
        font-size: 60px;
        line-height: 68px;
        letter-spacing: -1.5px;
        text-transform: capitalize;
        color: #ffffff;
        margin-bottom: 16px;
      }
      .description {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.4px;
        color: #8c8c8c;
      }
      .icon-wrapper {
        margin-top: 16px;
        width: 360px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        img {
          margin-bottom: 16px;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 16px;
          }
          &:nth-child(4) {
            margin-right: 0;
          }
        }
        .image-social {
          position: relative;
          overflow: hidden;
          img.hover-img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity 0.2s ease;
          }
          &:hover {
            img {
              opacity: 0;
              transition: opacity 0.2s ease;
            }
            img.hover-img {
              opacity: 1;
            }
          }
        }
      }
    }
    .containerRight {
      .mainObject {
        position: absolute;
        bottom: 0;
        right: -15%;
      }
    }
  }
  @media (max-width: 1024px) {
    .container {
      max-width: 982px;
      .title {
        font-size: 54px !important;
      }
    }
  }
`;
