import loryGameplay1 from 'src/assets/images/loryGameplay1.png';
import loryGameplay2 from 'src/assets/images/loryGameplay2.png';
import loryGameplay3 from 'src/assets/images/loryGameplay3.png';

const storyContents = [
  {
    img: loryGameplay1,
    url: 'https://docs.calo.run/app/gameplay-system/game-play#single-mode',
    storyTitle: 'Single Mode',
    storyDesc:
      '<p>In Single Mode, players are equipped with NFT Sneakers to earn tokens by moving. Stamina is required to earn FIT tokens. Each stamina equals 5 minutes of move &amp; earn movement and stamina, which ONLY starts replenishing after players acquire an NFT Sneaker. To begin, players choose one of their sneakers and press Start.</p>',
  },
  {
    img: loryGameplay2,
    url: 'https://docs.calo.run/app/gameplay-system/game-play#team-challenge-mode',
    storyTitle: 'Challenge Mode',
    storyDesc:
      '<p>In Challenge Mode, there are Weekly and Monthly challenges for players to join. Players must register for the Challenge session at least 24 hours before launch. On registration, a returnable fee will be charged to guarantee responsibility from players.</p>',
  },
  {
    img: loryGameplay3,
    url: 'https://docs.calo.run/app/gameplay-system/shoe-minting',
    storyTitle: 'Minting Sneaker',
    storyDesc:
      '<p>Minting sneaker occurs when players use two Sneakers they already own as a blueprint to &quot;breed,&quot; creating a Shoebox. The two sneakers will be referred to as Origin. To start minting sneakers, both Origins must be in the player&#39;s custody (not leased) and have total durability.</p>',
  },
];

export { storyContents };
