import styled, { css } from 'styled-components';

export const StyledChooseSneakerDesktop = styled.div`
  padding: 64px 0;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .main-title {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #ff8a34;
    }
    .title {
      font-weight: 600;
      font-size: 36px;
      line-height: 56px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #ffffff;
      padding-bottom: 24px;
    }
    .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #f5f5f5;
    }
    .boxContainer {
      margin-top: 60px;
      .boxItems {
        gap: 32px;
        @media (max-width: 1024px) {
          justify-content: center;
          gap: 16px;
        }
      }
    }
  }
`;

const sneakerTypes = {
  Walker: css`
    color: #cfddff;
  `,
  Jogger: css`
    color: #9bfebc;
  `,
  Runner: css`
    color: #d99bff;
  `,
  Trainer: css`
    color: #ffaa4e;
  `,
};

const sneakerTypesShadow = {
  Walker: css`
    box-shadow: 0px 0px 14px rgba(207, 221, 255, 0.4);
  `,
  Jogger: css`
    box-shadow: 0px 0px 14px rgba(155, 254, 188, 0.4);
  `,
  Runner: css`
    box-shadow: 0px 0px 14px rgba(217, 155, 255, 0.4);
  `,
  Trainer: css`
    box-shadow: 0px 0px 14px rgba(255, 170, 78, 0.4);
  `,
};

export const SneakerBoxItem = styled.div`
  background: #1b2030;
  border-radius: 14px;
  width: 100%;
  box-shadow: 0;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    ${({ type }) => sneakerTypesShadow[type]};
  }
  .type {
    padding: 14px 0;
    width: 100%;
    border-bottom: 1.7622px solid #2b3755;
    .name {
      padding-left: 12px;
      font-weight: 500;
      font-size: 21.1463px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.704878px;
      ${({ type }) => sneakerTypes[type]};
    }
  }
  .boxImg {
    img {
      max-width: 200px;
      max-height: 200px;
    }
    @media (max-width: 1024px) {
      img {
        max-width: 60%;
      }
    }
  }
  .speed {
    font-weight: 500;
    font-size: 21.1463px;
    line-height: 25px;
    text-align: center;
    color: #f2f2f2;
    margin-bottom: 16px;
  }
  .earn {
    border-top: 1.7622px solid #2b3755;
    padding: 12px 0;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #ffffff;
    .equal {
      margin: 0 8px;
    }
    img {
      margin-left: 8px;
    }
  }
  @media (max-width: 1024px) {
    width: 240px;
    .name {
      font-size: 18px;
    }
    .speed {
      font-size: 14px;
    }
    .earn {
      font-size: 14px;
      .equal {
        margin: 0 4px;
      }
      img {
        width: 50%;
      }
    }
  }
`;
