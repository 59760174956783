const nonAnimatingContainer = {
  visible: {
    transition: {
      when: 'beforeChildren',
      duration: 0.5,
      type: 'easeInOut',
      staggerChildren: 0.2,
    },
  },
  hidden: {
    transition: {
      duration: 0.3,
      type: 'easeInOut',
      staggerChildren: 0.2,
    },
  },
};

const boxItemLeftVariant = {
  visible: {
    opacity: 1,
    scaleY: 1,
    x: 0,
    transition: {
      when: 'beforeChildren',
      duration: 0.5,
      type: 'easeInOut',
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    scaleY: 1,
    x: '-10%',
    transition: {
      duration: 0.3,
      type: 'easeInOut',
      staggerChildren: 0.2,
    },
  },
};

const boxItemRightVariant = {
  visible: {
    opacity: 1,
    scaleY: 1,
    x: 0,
    transition: {
      when: 'beforeChildren',
      duration: 0.5,
      type: 'easeInOut',
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    scaleY: 1,
    x: '10%',
    transition: {
      duration: 0.3,
      type: 'easeInOut',
      staggerChildren: 0.2,
    },
  },
};

const boxItemTopVariant = {
  visible: {
    opacity: 1,
    scaleY: 1,
    y: 0,
    transition: {
      when: 'beforeChildren',
      duration: 0.5,
      type: 'easeInOut',
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    scaleY: 1,
    y: '-10%',
    transition: {
      duration: 0.3,
      type: 'easeInOut',
      staggerChildren: 0.2,
    },
  },
};

const boxItemBottomVariant = {
  visible: {
    opacity: 1,
    scaleY: 1,
    y: 0,
    transition: {
      when: 'beforeChildren',
      duration: 0.5,
      type: 'easeInOut',
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    scaleY: 1,
    y: '10%',
    transition: {
      duration: 0.3,
      type: 'easeInOut',
      staggerChildren: 0.2,
    },
  },
};

export {
  nonAnimatingContainer,
  boxItemLeftVariant,
  boxItemRightVariant,
  boxItemTopVariant,
  boxItemBottomVariant,
}