import styled from 'styled-components';

export const StyledFMSDesktop = styled.div`
  padding: 64px 0;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .main-title {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #FF8A34;
    }
    .title {
      font-weight: 600;
      font-size: 36px;
      line-height: 56px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #FFFFFF;
      padding-bottom: 16px;
    }
    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #e5e5e5;
    }
    .content-wrapper {
      margin-top: 80px;
      .phone-box {
        position: relative;
        padding-right: 100px;
        .phoneImg {
          position: relative;
          z-index: 1;
        }
        .line {
          position: absolute;
          top: 70px;
          right: 0;
          z-index: 2;
        }
        .blur {
          z-index: 0;
          position: absolute;
          top: 50%;
          left: 40%;
          transform: translate(-50%, -50%);
          width: 872px;
          height: 872px;
          background: radial-gradient(50% 50% at 50% 50%, rgba(49, 193, 255, 0.3) -60%, rgba(53, 85, 255, 0) 80%);
          @media(max-width: 1024px) {
            left: 50%;
            width: 772px;
            height: 772px;
          }
        }
      }

      .content-box {
        padding-left: 20px;
        .info {
          .title {
            text-align: left;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #ffffff;
            padding-bottom: 24px;
          }
          .info-item {
            /* margin-bottom: 40px; */
            &:not(:last-child) {
              margin-bottom: 40px;
            }
            .box-img {
              max-width: 176px;
              background: #03111e;
              box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.25);
              border-radius: 12px;
              img {
                width: 100%;
              }
            }
            .desc {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #e5e5e5;
              padding-left: 48px;
              max-width: 360px;
              text-align: left;
            }
          }
        }
      }
      @media (max-width: 1024px) {
        .phone-box {
          padding-right: 0;
        }
    }
  }
`;
