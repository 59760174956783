import styled from 'styled-components';

export const StyledBannerXmas = styled.div`
  max-width: 1330px;
  margin: 0 auto;
  width: 100%;

  .rowContainer {
    height: 100%;
    .imageColumn {
      position: relative;
      .calo {
        position: absolute;
        bottom: 0;
        left: -140px;
        width: 117px;
      }
      .fit {
        position: absolute;
        top: 100px;
        right: 40px;
        width: 70px;
      }
      .image-box {
        img {
          margin-left: auto;
          width: 312px;
          margin-right: 77px;
          @media (max-width: 1024px) {
            margin-top: 20px;
            width: 230px;
          }
        }
      }
    }

    .contentColumn {
      padding-top: 24px;
      position: relative;
      .title {
        img {
          max-width: 777px;
          @media (max-width: 1024px) {
            max-width: 550px;
          }
        }
      }
      .desc {
        max-width: 658px;
        margin-top: 32px;
        margin: auto 58px;
      }
      .downloadBox {
        margin-top: 80px;
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
    }
  }
`;
