import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import EquipAndMoveDesktop from './EquipAndMoveDesktop';
import EquipAndMoveMobile from './EquipAndMoveMobile';

const StepEquipAndMove = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<EquipAndMoveDesktop {...props} />}
      mobileView={<EquipAndMoveMobile {...props} />}
    />
  );
};

export default StepEquipAndMove;
