import { Col, Row } from 'antd';
import React from 'react';
import loryOnepaper from 'src/assets/images/carlLoryR2E_2.png';
import carlLoryR2E from 'src/assets/images/carlLoryR2E.png';
import sneakerIcon from 'src/assets/images/sneakerIcon.png';
import { useAppStore } from 'src/hooks/useAppStore';
import { StyledIntroductionV2 } from './styled';

const IntroductionV2 = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledIntroductionV2 isDarkMode={isDarkMode}>
      <div className="container">
        <div className="intro-content">
          <div className="content-column">
            <div className="header-title">
              <div className="title text-og">Calo Metaverse</div>
              <div className="title">Onepaper</div>
            </div>
            <p>
              Calo Metaverse is the "moving lifestyle" app based on Blockchain
              with Game-fi elements. Users can get more income through moving
              activities such as walking, running, and training.
            </p>
            <p>
              Upon entering the Calo Metaverse, players will equip themselves
              with NFTs SNEAKER to participate in MOVE TO EARN. After that,
              players will receive FIT Token, Calo Token, and other in-game
              items to continue participating in the following activities on the
              app.
            </p>
            <p>
              Players can trade and sell-buy NFTs SNEAKER on the In-app
              marketplace.
            </p>
          </div>
          <div className="image-column">
            <img
              className="img-fluid img"
              src={loryOnepaper}
              alt="loryOnepaper"
            />
          </div>
        </div>
      </div>
    </StyledIntroductionV2>
  );
};

export default IntroductionV2;
