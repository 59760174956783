import React from 'react';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import ResponsiveLayout from 'src/components/ResponsiveLayout';

const Header = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<HeaderDesktop {...props} />}
      mobileView={<HeaderMobile {...props} />}
    />
  );
};

export default Header;
