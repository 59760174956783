import React from 'react';
import { Link } from 'react-router-dom';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import appScreenMb from 'src/assets/images/appScreenMb2.png';
import { StyledAppScreenMobile } from './styled';

const AppScreenMobile = () => {
  return (
    <StyledAppScreenMobile>
      <div className="container">
        <div className="img-wrapper">
          <img
            className="img-app img-fluid"
            src={appScreenMb}
            alt="appScreen"
          />
        </div>
        <div className="box-content">
          <div className="title">Calo Metaverse</div>
          <div className="title">
            Let's <span className="bold">Move To Earn</span>
          </div>
          <div className="downloadBox flex-center">
            <div className="btn-download">
              <a
                href="https://apps.apple.com/us/app/calo-run/id1606108720"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnIos} alt="download" />
              </a>
            </div>
            <div className="btn-download">
              <a
                href="https://play.google.com/store/apps/details?id=com.calometaverse"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnAndroid} alt="download" />
              </a>
            </div>
          </div>
          <Link to="/how-to-play" className="flex-center">
            <div className="btn-primary">
              <span>How To Play</span>
            </div>
          </Link>
        </div>
      </div>
    </StyledAppScreenMobile>
  );
};

export default AppScreenMobile;
