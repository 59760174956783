import styled from 'styled-components';

export const StyledTokenMetricsMobile = styled.section`
  padding: 80px 0;
  .container {
    margin: auto;
    position: relative;
    .header {
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1.5px;
      text-align: center;
      color: ${({ theme }) => theme.header};
      padding-bottom: 8px;
    }
    .title {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      color: #e5e5e5;
      text-align: center;
      padding-bottom: 8px;
    }
    .description {
      max-width: 295px;
      margin: auto;
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: ${({ theme }) => theme.description};
      padding-bottom: 48px;
      @media (min-width: 768px) {
        max-width: 500px;
      }
    }
    .tokenSupply {
      margin-top: 24px;
      @media (min-width: 768px) {
        img {
          width: 400px;
        }
      }
    }
    .tokenInfo {
      @media (min-width: 768px) {
        max-width: 500px;
        margin: 0 auto;
      }
    }
    .total {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: ${({ theme }) => theme.title};
      margin-top: 50px;
      .number {
        color: #ff7510;
      }
    }
    .contract {
      margin-top: 24px;
      .label {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: ${({ theme }) => theme.title};
        margin-bottom: 10px;
      }
      .inputBox {
        padding: 12px;
        text-align: left;
        color: ${({ theme }) => theme.title};
        border: ${({ theme }) => theme.inputBorder};
        background: ${({ theme }) => theme.inputBg};
        box-sizing: border-box;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    @media (max-width: 414px) {
      /* .title {
        font-weight: 700;
        font-size: 34px;
        line-height: 40px;
        letter-spacing: 0.25px;
      } */
      .description {
        max-width: 282px;
        margin: auto;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: ${({ theme }) => theme.description};
      }
      .total {
        font-size: 16px;
        line-height: 20px;
      }
      .contract {
        .label {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    @media (max-width: 375px) {
      .inputBox {
        font-size: 12px;
      }
    }
    @media (max-width: 320px) {
      .inputBox {
        font-size: 10px;
        img {
          width: 18px;
        }
      }
    }
  }
`;
