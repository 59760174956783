import React from 'react';
import { Col, Row } from 'antd';
import phoneEquip from 'src/assets/images/howtoplay/phoneEquip.png';
import { StyledEquipMoveDesktop } from './styled';

const EquipAndMoveDesktop = () => {
  return (
    <StyledEquipMoveDesktop>
      <div className="container">
        <Row gutter={0} align="middle">
          <Col span={15}>
            <div className="img-wrapper">
              <div className="blur" />
              <div className="img-box">
                <img className="img-fluid" src={phoneEquip} alt="img" />
              </div>
            </div>
          </Col>
          <Col span={9}>
            <div className="content-wrapper">
              <div className="title">
                <span className="bold">EQUIP</span>
                <span>your sneaker</span>
              </div>
              <div className="title">
                <span className="bold">MOVE</span>
                <span>outdoors</span>
              </div>
              <div className="title">
                <span className="bold">BURN</span>
                <span>stamina</span>
              </div>
              <div className="title">
                <span className="bold">EARN</span>
                <span>sexy rewards</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </StyledEquipMoveDesktop>
  );
};

export default EquipAndMoveDesktop;
