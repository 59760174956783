import styled from 'styled-components';
import homeBannerBgMb from 'src/assets/images/homeBannerBgMb.png';
import homeBannerBgTbl from 'src/assets/images/homeBannerBgTbl.png';

export const StyledBannerScreen = styled.div`
  max-width: 1216px;
  margin: 0 auto;
  width: 100%;
  height: 315px;
  overflow: visible;
  position: relative;
  .backgroundImage {
    position: absolute;
    height: 100%;
    border-top-left-radius: 64px;
    border-bottom-left-radius: 64px;
  }
  .imageBanner {
    text-align: center;
    height: 310px;
    z-index: 10;
    img {
      width: 271px;
    }
  }
  .downloadBox {
    margin-top: 32px;
    .btn-download {
      &:first-child {
        margin-right: 16px;
      }
    }
    @media (max-width: 375px) {
      .btn-download {
        width: 40%;
        img {
          width: 100%;
        }
      }
    }
  }
  .animationBox {
    height: 100%;
    position: relative;
    > div:first-child {
      position: absolute;
      top: -50px;
      left: 20%;
    }
    .monsterPhone {
      position: absolute;
      bottom: 0;
      left: 10%;
      img {
        width: 120px;
      }
    }
    .floatingObject {
      position: absolute;
      top: -30px;
      right: 16%;
      img {
        width: 70px;
      }
    }
  }
  @media (min-width: 767px) {
    .animationBox {
      > div:first-child {
        left: 22%;
      }
    }
  }
`;

export const StyledBannerScreenNFT = styled.div`
  margin: 0 auto;
  width: 100%;
  overflow: visible;
  position: relative;
  padding-bottom: 24px;
  background-image: url(${homeBannerBgMb});
  background-size: cover;
  background-repeat: round;
  @media (min-width: 767px) {
    background-image: url(${homeBannerBgTbl});
  }
  .imageBanner {
    text-align: center;
    img {
      max-width: 400px;
      @media (max-width: 568px) {
        max-width: 100%;
      }
    }
  }
  .downloadBox {
    margin-top: 32px;
    flex-direction: column;
    .btn-download {
      &:first-child {
        margin-bottom: 16px;
      }
    }
    @media (max-width: 375px) {
      .btn-download {
        width: 40%;
        img {
          width: 100%;
        }
      }
    }
  }
`;

export const StyledBannerScreenWhitelist = styled.div`
  margin: 0 auto;
  width: 100%;
  overflow: visible;
  position: relative;
  padding-bottom: 24px;
  .imageBanner {
    text-align: center;
    img {
      max-width: 400px;
      @media (max-width: 568px) {
        max-width: 100%;
      }
    }
  }
  .contentIntro {
    text-align: center;
    padding-top: 20px;
    .header {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #ffffff;
      margin-bottom: 12px;
    }
    .title {
      font-weight: 700;
      font-size: 60px;
      line-height: 51px;
      text-align: center;
      color: #ff8a34;
    }
    .description {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }
  .btn-primary {
    margin: 32px auto 0 auto;
    padding: 16px 0;
    max-width: 160px;
    background: linear-gradient(180deg, #ffaa4e 0%, #f45c0e 100%);
    box-shadow: 2px 2px 50px -5px rgba(249, 131, 75, 0.8);
    border-radius: 24px;
    text-align: center;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 1.25px;
      color: #ffffff;
    }
  }
  .downloadBox {
    margin-top: 32px;
    flex-direction: column;
    .btn-download {
      &:first-child {
        margin-bottom: 16px;
      }
    }
    @media (max-width: 375px) {
      .btn-download {
        width: 40%;
        img {
          width: 100%;
        }
      }
    }
  }
`;

export const StyledBannerScreenGacha = styled.div`
  margin: 0 auto;
  width: 100%;
  overflow: visible;
  position: relative;
  padding-bottom: 24px;
  .imageBanner {
    text-align: center;
    margin-top: -100px;
    img {
      max-width: 360px;
      @media (max-width: 568px) {
        max-width: 100%;
      }
    }
  }
  .contentIntro {
    text-align: center;
    padding-top: 20px;
    .banner-gacha {
      @media (min-width: 568px) {
        max-width: 450px;
        margin: 0 auto;
      }
    }
  }
  .btn-primary {
    margin: 32px auto 0 auto;
    padding: 16px 0;
    max-width: 160px;
    background: linear-gradient(180deg, #ffaa4e 0%, #f45c0e 100%);
    box-shadow: 2px 2px 50px -5px rgba(249, 131, 75, 0.8);
    border-radius: 24px;
    text-align: center;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 1.25px;
      color: #ffffff;
    }
  }
  .downloadBox {
    margin-top: 32px;
    flex-direction: column;
    .btn-download {
      &:first-child {
        margin-bottom: 16px;
      }
    }
    @media (max-width: 375px) {
      .btn-download {
        width: 40%;
        img {
          width: 100%;
        }
      }
    }
  }
`;

export const StyledBannerScreenF2P = styled.div`
  margin: 0 auto;
  width: 100%;
  overflow: visible;
  position: relative;
  padding-bottom: 24px;
  .imageBanner {
    text-align: center;
    img {
      max-width: 345px;
    }
  }
  .contentIntro {
    text-align: center;
    .title-days {
      img {
        max-width: 175px;
        margin: 0 auto;
      }
    }
    .title {
      img {
        max-width: 360px;
        margin: 0 auto;
        @media (max-width: 320px) {
          max-width: 100%;
        }
      }
    }
    .desc {
      img {
        max-width: 231px;
        margin: 0 auto;
        margin-top: 12px;
      }
    }
  }
  .btn-primary {
    margin: 32px auto 0 auto;
    padding: 16px 0;
    max-width: 160px;
    background: linear-gradient(180deg, #ffaa4e 0%, #f45c0e 100%);
    box-shadow: 2px 2px 50px -5px rgba(249, 131, 75, 0.8);
    border-radius: 24px;
    text-align: center;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 1.25px;
      color: #ffffff;
    }
  }
  .downloadBox {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    .btn-download {
      &:first-child {
        margin-bottom: 16px;
      }
    }
    /* @media (max-width: 375px) {
      .btn-download {
        width: 40%;
        img {
          width: 100%;
        }
      }
    } */
  }
`;

export const StyledBannerXmas = styled.div`
  margin: 0 auto;
  width: 100%;
  overflow: visible;
  position: relative;
  padding-bottom: 24px;
  .imageBanner {
    text-align: center;
    img {
      max-width: 233px;
      padding-left: 24px;
    }
  }
  .contentIntro {
    text-align: center;
    .title {
      img {
        max-width: 360px;
        margin: 0 auto;
        @media (max-width: 320px) {
          max-width: 100%;
        }
      }
    }
    .desc {
      img {
        max-width: 326px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 80px;
        padding: 0 24px;
      }
    }
  }
  .btn-primary {
    margin: 32px auto 0 auto;
    padding: 16px 0;
    max-width: 160px;
    background: linear-gradient(180deg, #ffaa4e 0%, #f45c0e 100%);
    box-shadow: 2px 2px 50px -5px rgba(249, 131, 75, 0.8);
    border-radius: 24px;
    text-align: center;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 1.25px;
      color: #ffffff;
    }
  }
  .downloadBox {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    .btn-download {
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
`;

export const StyledBannerLunarGacha23 = styled.div`
  margin: 0 auto;
  width: 100%;
  overflow: visible;
  position: relative;
  padding-bottom: 24px;
  .imageBanner {
    text-align: center;
    img {
      max-width: 400px;
    }
  }
  .contentIntro {
    text-align: center;
    .title {
      img {
        max-width: 360px;
        margin: 0 auto;
        @media (max-width: 320px) {
          max-width: 100%;
        }
      }
    }
    .desc {
      img {
        max-width: 326px;
        width: 100%;
        margin: 0 auto;
        padding: 0 24px;
      }
    }
  }
  .btn-primary {
    margin: 32px auto 0 auto;
    padding: 16px 0;
    max-width: 160px;
    background: linear-gradient(180deg, #ffaa4e 0%, #f45c0e 100%);
    box-shadow: 2px 2px 50px -5px rgba(249, 131, 75, 0.8);
    border-radius: 24px;
    text-align: center;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 1.25px;
      color: #ffffff;
    }
  }
  .downloadBox {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    .btn-download {
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
`;