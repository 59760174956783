import React, { useState, useEffect } from 'react';
import { wrap } from 'popmotion';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import MetaverseDesktop from './MetaverseDesktop';
import MetaverseMobile from './MetaverseMobile';
import { storyContents } from './storyContent';

const Metaverse = (props) => {
  const [[page, direction], setPage] = useState([0, 0]);

  const idx = wrap(0, storyContents.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     paginate(1);
  //   }, 10000);
  //   return () => clearInterval(timer);
  // });

  return (
    <>
      <ResponsiveLayout
        desktopView={
          <MetaverseDesktop
            page={page}
            idx={idx}
            direction={direction}
            onNext={paginate}
            onPrev={paginate}
            {...props}
          />
        }
        mobileView={
          <MetaverseMobile
            page={page}
            idx={idx}
            direction={direction}
            onNext={paginate}
            onPrev={paginate}
            {...props}
          />
        }
      />
    </>
  );
};

export default Metaverse;
