import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAppStore } from 'src/hooks/useAppStore';
import gamePlayBg from 'src/assets/images/gamePlayBg.png';
import nextArrowGameplay from 'src/assets/images/nextArrowGameplay.png';
import { storyContents } from '../storyContent';
import { StyledMetaverse } from './styled';

const MetaverseMobile = (props) => {
  const { idx, direction, page, onPrev, onNext } = props;
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  function createMarkup(text) {
    return { __html: text };
  }

  function rawHTML(text) {
    // eslint-disable-next-line react/react-in-jsx-scope
    return <div dangerouslySetInnerHTML={createMarkup(text)} />;
  }

  return (
    <StyledMetaverse>
      <div className="backgroundLayer">
        <div className="line">
          <img className="img-fluid" src={gamePlayBg} alt="line" />
        </div>
      </div>
      {/* <div className="blur" /> */}
      <div className="title">Gameplay</div>
      <AnimatePresence initial={false} custom={direction}>
        <div className="contentWrapper">
          <div className="contentImage">
            <motion.img
              className="mainImage"
              key={idx}
              initial={{ x: 300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -300, opacity: 0 }}
              src={storyContents[idx].img}
            />
          </div>
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            className="contentText"
          >
            <div className="contentTitle">{storyContents[idx].storyTitle}</div>
            <div className="contentDesc">
              {rawHTML(storyContents[idx].storyDesc)}
            </div>
            <a
              className="link"
              href={storyContents[idx].url}
              target="_blank"
              rel="noreferrer"
            >
              Read more
            </a>
          </motion.div>
          <div className="buttonGroup">
            <div className="next" onClick={() => onNext(1)}>
              <img src={nextArrowGameplay} alt="next" />
            </div>
          </div>
        </div>
      </AnimatePresence>
    </StyledMetaverse>
  );
};

export default MetaverseMobile;
