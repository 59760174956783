import React, { useEffect } from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import iconStamina from 'src/assets/images/howtoplay/iconStamina.png';
import fitTokenXs from 'src/assets/images/fitTokenXs.png';
import { SneakerBoxItem, StyledChooseSneakerDesktop } from './styled';
import { renderSneakerImg, renderTypeIcon, sneakerStats } from '../data';

const ChooseSneakerDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledChooseSneakerDesktop isDarkMode={isDarkMode}>
      <div className="container">
        <div className="main-title">CHOOSE A SNEAKER</div>
        <div className="title">That fits your fitness level</div>
        <div className="desc">
          You only earn when you have an NFT sneaker and move outdoors with GPS
        </div>
        <div className="boxContainer">
          <div className="boxItems flex-between">
            {sneakerStats.map((s, key) => (
              <SneakerBoxItem type={s.name} key={key}>
                <div className="type flex-center">
                  <img className="icon" src={renderTypeIcon(s.name)} alt="icon" />
                  <div className="name">{s.name}</div>
                </div>
                <div className="boxImg flex-center">
                  <img src={renderSneakerImg(s.name)} alt="shoeImg" />
                </div>
                <div className="speed">{s.speed} (KM/HR)</div>
                <div className="earn flex-center">
                  <span className="stamina flex-center">
                    <span className="text">1</span>
                    <img src={iconStamina} alt="stamina" />
                  </span>
                  <span className="equal">≈</span>
                  <span className="fit-earn flex-start">
                    <span className="text">{s.fitEarn}</span>
                    <img src={fitTokenXs} alt="token" />
                  </span>
                </div>
              </SneakerBoxItem>
            ))}
          </div>
        </div>
      </div>
    </StyledChooseSneakerDesktop>
  );
};

export default ChooseSneakerDesktop;
