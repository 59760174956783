import React, { useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Affix, Menu, Tooltip } from 'antd';
import caloLogo from 'src/assets/images/caloLogo.svg';
import iconCopyAddress from 'src/assets/images/iconCopyAddress.svg';
import certikDark from 'src/assets/images/logoCertik.svg';
import divider from 'src/assets/images/divider.svg';
import Switch from 'src/components/Switch';
import { useAppStore } from 'src/hooks/useAppStore';
import { scrollTo } from 'src/utils';
import { StyledHeader } from './styled';
import { truncateAddress } from 'src/utils/text';
import { caloAddress, fitAddress } from 'src/variables/calo';
import DocumentsDropdown from '../DocumentsDropdown';

const HeaderDesktop = (props) => {
  const { theme, toggleTheme, id } = props;
  const [appStore, updateAppStore] = useAppStore();
  const { isDarkMode } = appStore;
  const history = useHistory();
  const { pathname } = useLocation();

  const isHomePage = pathname === '/';

  const noSwitchPath = ['/how-to-play', '/onepaper'];

  const navigateScroll = (toId, toRef, duration) =>
    scrollTo({ id: toId, ref: toRef, duration });

  const onNavigatePage = (id) => {
    if (isHomePage) {
      navigateScroll(id);
    } else {
      history.push(`/#${id}`);
    }
  };

  const onToggleTheme = () => {
    toggleTheme();
    updateAppStore((draft) => {
      draft.isDarkMode = theme === 'dark' ? false : true;
    });
  };

  const [copied, setCopied] = useState(false);

  const copyAddress = (address) => {
    setCopied(true);
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(address);
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('input');
      ele.value = address;
      document.body.appendChild(ele);
      ele.select();
      document.execCommand('copy');
      document.body.removeChild(ele);
    }
  };

  return (
    <StyledHeader id={id} isHomePage={isHomePage}>
      <Affix offsetTop={0}>
        <div className="caloAddressContainer">
          <div className="contract">
            <span className="calo"> CALO </span>
            <a
              href={`https://bscscan.com/token/${caloAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="address">
                {truncateAddress(caloAddress, 18, '...')}
              </span>
            </a>
            <Tooltip
              title={copied ? 'Copied' : 'Copy'}
              onVisibleChange={(visible) => {
                if (!visible) {
                  setCopied(false);
                }
              }}
              placement="bottom"
            >
              <img
                className="copy link"
                src={iconCopyAddress}
                onClick={() => copyAddress(caloAddress)}
                width="24px"
              />
            </Tooltip>
          </div>
          <div className="contract">
            <span className="calo fit"> FIT </span>
            <a
              href={`https://bscscan.com/token/${fitAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <span className="address">
                {truncateAddress(fitAddress, 18, '...')}
              </span>
            </a>
            <Tooltip
              title={copied ? 'Copied' : 'Copy'}
              onVisibleChange={(visible) => {
                if (!visible) {
                  setCopied(false);
                }
              }}
              placement="bottom"
            >
              <img
                className="copy link"
                src={iconCopyAddress}
                onClick={() => copyAddress(fitAddress)}
                width="24px"
              />
            </Tooltip>
          </div>
          <div className="divider">
            <img src={divider} alt="divider" />
          </div>
          <a
            href="https://www.certik.com/projects/calo"
            target="_blank"
            rel="noreferrer"
          >
            <div className="auditedBox">
              <span>Audited by</span>
              <img src={certikDark} alt="certik" />
              <div className="status">Verified</div>
            </div>
          </a>
        </div>
      </Affix>
      <nav className="navContainer">
        <div className="navWrapper">
          <div className="navHeader">
            <Link to="/" className="navBrand">
              <img
                className="navLogo img-fluid"
                src={caloLogo}
              />
            </Link>
          </div>
          <div className="navMenuCenterContainer">
            <div className="navMenuContainer">
              <ul className="navMenu">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.calo.run/app/"
                  className="navLink link"
                >
                  <li>Whitepaper</li>
                </a>
                <Link to="/onepaper" className="navLink link">
                  <li>Onepaper</li>
                </Link>
                <Link to="/how-to-play" className="navLink link">
                  <li>How to play</li>
                </Link>
                <li
                  className="navLink link"
                  onClick={() => onNavigatePage('gameplay')}
                >
                  Gameplay
                </li>
                <li
                  className="navLink link"
                  onClick={() => onNavigatePage('team')}
                >
                  Team
                </li>
              </ul>
            </div>
          </div>
          <div className="navMenuRightContainer">
            {/* {!noSwitchPath.includes(pathname) && (
              <Switch checked={isDarkMode} onChange={onToggleTheme} />
            )} */}

            <a
              className="linkTrial"
              href="https://dapps.calo.run/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="buttonTrial">
                <span>Enter Dapp</span>
              </div>
            </a>
            <a
              className="linkTrial"
              href="https://blog.calo.run/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="buttonTrial">
                <span>Blog</span>
              </div>
            </a>
          </div>
        </div>
      </nav>
    </StyledHeader>
  );
};

export default HeaderDesktop;
