import styled from 'styled-components';

export const StyledIntroduction = styled.section`
  margin-top: -60px;
  .slick-slider {
    .slick-list {
      padding: 60px 0;
    }
    .slick-dots {
      bottom: unset;
      top: 400px;
      display: inline-block;
    }
  }
  @media (min-width: 767px) {
    /* max-width: 400px; */
    margin: 0 auto;
    margin-top: -60px;
  }

  .bannerSlide {
    position: relative;
    margin: 0 -15px;
    .darkThemeBackground {
      max-height: 295px;
      width: 100%;
      position: absolute;
      top: 45%;
      left: 55%;
      transform: translate(-50%, -50%);
      mix-blend-mode: lighten;
      z-index: -1;
      img {
        height: 100%;
        width: 100%;
        filter: blur(30px);
      }
    }
    .banner-event-img {
      height: 315px;
      padding: 12px;
      img {
        border-radius: 24px;
      }
    }
  }

  .contentIntro {
    text-align: center;
    padding-top: 20px;
    .header {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #bfbfbf;
      margin-bottom: 12px;
    }
    .title {
      font-weight: 700;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      color: #ff8a34;
    }
    .description {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      margin-top: 16px;
      margin-bottom: 32px;
    }

    .btnSocial {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        /* cursor: none; */
        margin-right: 16px;
        display: inline-block;
        img {
          width: 42px;
          display: unset;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
