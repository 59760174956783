import styled from 'styled-components';

export const StyledTokenV2Desktop = styled.section`
  padding: 200px 0;
  position: relative;
  z-index: 3;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    @media (max-width: 1024px) {
      padding: 0 64px;
    }
    .title {
      font-weight: 500;
      font-size: 145.752px;
      line-height: 162px;
      letter-spacing: -9px;
      color: #ff8a34;
      .r-1 {
        display: block;
      }
      .r-2 {
        padding-left: 46px;
      }
    }
    .tokens {
      .token-wrapper {
        .blur-bg {
          position: absolute;
          z-index: 1;
        }
        .calo {
          z-index: 3;
        }
        .fit {
          margin-top: -200px;
          margin-left: -100px;
          z-index: 2;
          .token-img {
            width: 300px !important;
            @media (max-width: 1024px) {
              width: 260px !important;
            }
          }
        }
        .token-item {
          .token-img {
            width: 330px;
            @media (max-width: 1024px) {
              width: 280px;
            }
          }
          .name {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #f45c0e;
            margin-top: 16px;
            margin-bottom: 8px;
            text-align: center;
          }
          .desc {
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.0015em;
            color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#4D4D4D')};
            text-align: center;
            padding-top: 8px;
          }
        }
      }
    }
  }
`;
