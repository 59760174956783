import styled from 'styled-components';

export const StyledTokenomic = styled.section`
  /* padding: 100px 0; */
  position: relative;
  z-index: 3;
  .container {
    max-width: 1216px;
    margin: auto;
    .header {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-align: center;
      color: ${({ theme }) => theme.header};
      padding-bottom: 8px;
    }
    .title {
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.25px;
      color: ${({ theme }) => theme.title};
      padding-bottom: 24px;
    }
    .description {
      max-width: 607px;
      margin: auto;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.0125em;
      color: #b3b4cd;
      color: ${({ theme }) => theme.desc};
    }
    .tokenomicContent {
      text-align: center;
      margin-top: 64px;
      .title-mb {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        color: #e5e5e5;
        margin-bottom: 24px;
        text-align: center;
      }
      img {
        /* width: 314px; */
      }
      @media (min-width: 768px) {
        padding: 0 120px;
      }
      @media (min-width: 1024px) {
        padding: 0;
      }
    }
  }
`;
