import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import TeamDesktop from './TeamDesktop';
import TeamMobile from './TeamMobile';

const Team = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<TeamDesktop {...props} />}
      mobileView={<TeamMobile {...props} />}
    />
  );
};

export default Team;
