import styled from 'styled-components';
import { Input } from 'antd';

export const StyledFooter = styled.footer`
  width: 100%;
  padding: 40px 0 90px 0;
  background: #0c0d18;
  .container {
    margin: auto;
    .caloInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .logoBox {
        text-align: center;
        img {
          width: 145px;
        }
      }
      .companyInfo {
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.4px;
        color: #bfbfbf;
        div:not(:last-child) {
          margin-bottom: 4px;
        }
        a {
          color: #bfbfbf;
        }
      }
    }
    .copyright {
      margin-top: 24px;
      color: #8a94a6;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1.5px;
      text-align: center;
    }
    .containerBottom {
      max-width: 66.9%;
      margin: 32px auto 0 auto;
      @media (max-width: 576px) {
        max-width: 80%;
      }
      .menuGroupBox {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
      }
      .footerMenu {
        list-style: none;
        padding: 0;
        ul {
          margin-bottom: 0;
        }
        .footerLink {
          color: #fcfcfc;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .inputBox {
        width: 100%;
        margin-top: 48px;
        .label {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
          margin-bottom: 12px;
          letter-spacing: 0.4px;
        }
      }
      .coin-gecko {
        .btn-download {
          margin: 24px auto;
          max-width: fit-content;
          cursor: pointer;
          padding: 10px;
          border: 2px solid #ffb37d;
          border-radius: 36px;
          .icon {
            padding-right: 8px;
          }
          .label {
            font-weight: 700;
            font-size: 12px;
            letter-spacing: 1.25px;
            color: #ffb37d;
          }
        }
        .text {
          color: #fcfcfc;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.01em;
        }
        a {
          display: block;
          margin: 10px 0;
        }
      }
    }
    @media only screen and (max-width: 766px) {
      .containerBottom {
        max-width: 100%;
      }
    }
  }
`;

export const StyledInputEmail = styled(Input)`
  &.ant-input-affix-wrapper {
    background: rgba(12, 11, 46, 0.36);
    border: 1px solid #8a8cb2;
    border-radius: 16px;
    padding: 10px 12px;
    color: #8a8cb2;
    &:focus {
      box-shadow: unset;
    }
    &:not(.ant-input-affix-wrapper-disabled) {
      &:hover {
        border-color: #8a8cb2;
      }
    }
    .ant-input-affix-wrapper-focused {
      box-shadow: unset;
    }
    .ant-input {
      background: transparent;
      color: #8a8cb2;
      &::placeholder {
        color: #8a8cb2;
      }
    }
  }
`;
