import React from 'react';
import tokenDemand from 'src/assets/images/token-demand/tokenDemand.png';
import tokenDemandMb from 'src/assets/images/token-demand/tokenDemandMb1.png';
import textDemandMb from 'src/assets/images/token-demand/textDemandMb1.png';
import { useAppStore } from 'src/hooks/useAppStore';
import { StyledTokenDemand } from './styled';
import ResponsiveLayout from '../ResponsiveLayout';

const TokenDemand = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const tokenContents = [
    {
      content: 'Customization Fee',
      caloUse: true,
      fitUse: false,
    },
    {
      content: 'Boosting Fee',
      caloUse: false,
      fitUse: true,
    },
    {
      content: 'Sneaker Repair',
      caloUse: false,
      fitUse: true,
    },
    {
      content: 'Sneaker Sockets Unlocking',
      caloUse: false,
      fitUse: true,
    },
    {
      content: 'Level Up Sneakers',
      caloUse: true,
      fitUse: true,
    },
    {
      content: 'Shoe Minting',
      caloUse: true,
      fitUse: true,
    },
    {
      content: 'Upgrade Gems',
      caloUse: true,
      fitUse: true,
    },
  ];

  const contentDesktop = () => {
    return (
      <div className="container">
        <div className="title">Token Demand</div>
        <div className="img-wrapper">
          <img className="img-fluid" src={tokenDemand} />
        </div>
      </div>
    );
  };

  const contentMobile = () => {
    return (
      <div className="container">
        <div className="title">Token Demand</div>
        <div className="img-wrapper text-center">
          <img className="img-fluid img-demand" src={tokenDemandMb} />
          <img className="img-fluid mt-4" src={textDemandMb} />
        </div>
      </div>
    );
  };

  return (
    <StyledTokenDemand isDarkMode={isDarkMode}>
      <ResponsiveLayout
        desktopView={contentDesktop()}
        mobileView={contentMobile()}
      />
    </StyledTokenDemand>
  );
};

export default TokenDemand;
