export const truncateAddress = (fullText, textLen, separator) => {
  if (!fullText) return;
  if (fullText.length <= textLen) return fullText;

  separator = separator || '...';

  var sepLen = separator.length,
    charsToShow = textLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return fullText.substr(0, frontChars) + separator + fullText.substr(fullText.length - backChars);
};