import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import btnIos from 'src/assets/images/howtoplay/btnIos.png';
import btnAndroid from 'src/assets/images/howtoplay/btnAndroid.png';
import carlLoryDownload from 'src/assets/images/howtoplay/carlLoryDownload.png';
import { StyledDownloadDesktop } from './styled';

const DownloadDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledDownloadDesktop isDarkMode={isDarkMode}>
      <div className="container">
        <div className="content-wrapper flex-center">
          <div className="left">
            <img className="contentImg" src={carlLoryDownload} alt="img" />
          </div>
          <div className="content-box">
            <div className="title">YOUR STEPS COUNT WITH CALO!</div>
            <div className='btn-download flex-center'>
              <div className='ios'>
                <a
                  href="https://apps.apple.com/us/app/calo-run/id1606108720"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={btnIos} />
                </a>
              </div>
              <div className='android'>
                <a
                  href="https://play.google.com/store/apps/details?id=com.calometaverse"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={btnAndroid} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledDownloadDesktop>
  );
};

export default DownloadDesktop;
