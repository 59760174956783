import { Drawer } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled.header`
  .ant-affix {
    z-index: 20;
    .caloAddressContainer {
      box-shadow: rgb(0 35 149 / 8%) 0px 2px 4px;
    }
  }
  .caloAddressContainer {
    min-height: 73px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.addressBg};
    .contract {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      letter-spacing: 0.0125em;
      &:first-child {
        padding-bottom: 6px;
      }
      .label {
        color: ${({ theme }) => theme.title};
      }
      .calo {
        padding-right: 14px;
        font-weight: 600;
        color: #f67d3e;
        flex: 1;
      }
      .fit {
        color: #008bbf !important;
      }
      .address {
        font-weight: 600;
        color: ${({ theme }) => theme.description};
      }
      .copy {
        margin-left: 12px;
      }
    }
    .divider {
      margin-left: 16px;
      margin-right: 16px;
    }
    .auditedBox {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      background: ${({ theme }) => theme.auditBox};
      box-shadow: ${({ theme }) => theme.auditShadow};
      border-radius: 16px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        border: ${({ theme }) => theme.boxBorder};
        border-radius: 16px;
      }
    }
    @media (max-width: 428px) {
      .contract {
        font-size: 12px;
      }
    }
    @media (max-width: 320px) {
      .contract {
        font-size: 12px;
      }
      .divider {
        margin-left: 8px;
        margin-right: 8px;
      }
      .auditedBox {
        padding: 8px;
      }
    }
  }
  .headerWrapper {
    text-align: center;
    position: relative;
    margin-top: 20px;
    padding: 12px 0;
    z-index: 1;
    .navLogo {
      max-width: 128px;
    }
    .menuIcon {
      position: absolute;
      top: 25px;
      left: 20px;
      z-index: 100;
      color: #ffffff;
      font-size: 18px;
    }
    .switchThemeBtn {
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 100;
    }
  }
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    background: ${({ theme }) => theme.body};
  }
  .navMenuContainer {
    margin-right: 64px;
    margin-bottom: 24px;
    .navMenu {
      list-style: none;
      margin: 0;
      padding: 0;
      .navLink {
        /* cursor: pointer; */
        color: #999999;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 24px;
        display: block;
      }
    }
  }

  .buttonTrial {
    padding: 10px;
    background: linear-gradient(180deg, #ffaa4e 0%, #f45c0e 100%);
    box-shadow: 2px 2px 50px -5px rgba(249, 131, 75, 0.8);
    border-radius: 56px;
    text-align: center;
    margin-bottom: 24px;
    width: 50%;
    span {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1.25px;
      color: white;
    }
  }
`;
