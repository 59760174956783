import React, { useEffect, useState } from 'react';
import { throttle } from 'lodash';
import breakpoints from 'src/variables/breakpoint';

const windowDims = () => ({
  height: typeof window !== `undefined` ? window.innerHeight : 0,
  width: typeof window !== `undefined` ? window.innerWidth : 0,
});

const ResponsiveLayout = (props) => {
  const { breakpoint = breakpoints.lgMin, mobileView, desktopView } = props;
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const [init, setInit] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setDimensions(windowDims());
    };

    const throttledHandleResize = throttle(handleResize, 500);

    window.addEventListener('resize', throttledHandleResize);
    return () => {
      window.removeEventListener('resize', throttledHandleResize);
      if (throttledHandleResize) {
        throttledHandleResize.cancel();
      }
    };
  }, []);

  useEffect(() => {
    if (!init) {
      setInit(true);
      setDimensions({
        height: window.innerHeight || 0,
        width: window.innerWidth || 0,
      });
    }
  }, [init]);

  const { width } = dimensions;

  if (typeof window === `undefined`) {
    return <div />;
  }

  return width > breakpoint ? desktopView : mobileView;
};

export default ResponsiveLayout;
