import styled from 'styled-components';

export const StyledWhyUs = styled.div`
  padding: 100px 0;
  @media (max-width: 768px) {
    padding: 32px 0;
  }
  .container {
    max-width: 1216px;
    margin: auto;
    .header {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-align: center;
      color: ${({ theme }) => theme.header};
      padding-bottom: 8px;
    }
    .title {
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.25px;
      color: ${({ theme }) => theme.title};
      padding-bottom: 24px;
    }
    .description {
      max-width: 607px;
      margin: auto;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.0125em;
      color: #b3b4cd;
      color: ${({ theme }) => theme.desc};
    }
    .boxContainer {
      margin-top: 60px;
      @media (max-width: 1024px) {
        overflow-x: auto;
        .boxItems {
          overflow-x: auto;
          width: 1216px;
        }
      }
      @media (max-width: 768px) {
        margin-top: 24px;
      }
      .boxItems {
        .boxInfo {
          padding: 24px 8px 0 8px;
          height: 264px;
          width: 270px;
          background: rgba(255, 158, 88, 0.1);
          box-shadow: inset 0px 5px 34px rgba(255, 255, 255, 0.25);
          border-radius: 18.8475px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 18.8475px;
            padding: 2px;
            background: linear-gradient(
              100.01deg,
              #ff8a34 5.41%,
              rgba(255, 138, 52, 0) 32.38%,
              rgba(255, 138, 52, 0) 65.19%,
              #ff8a34 89.79%
            );
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
          }
          .boxTitle {
            font-weight: 600;
            font-size: 26px;
            line-height: 32px;
            text-align: center;
            color: #ff8a34;
            margin-bottom: 16px;
          }
          .boxContent {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.4px;
            color: #b3b3b3;
          }
          @media (max-width: 768px) {
            &:hover {
              box-shadow: unset;
            }
            .boxTitle {
              font-size: 20px;
            }
            .boxContent {
              font-size: 14px;
            }
          }
        }
      }
    }
    .mission {
      margin: 60px auto 0 auto;
      max-width: 905px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #f5f5f5;
      @media (max-width: 768px) {
        font-size: 20px;
      }
      @media (max-width: 568px) {
        font-size: 16px;
        max-width: 288px;
      }
    }
  }
`;
