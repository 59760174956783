import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import { teamMember } from '../teamMember';
import { StyledTeam } from './styled';

const TeamDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const slideSettings = {
    dots: true,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    infinite: false,
    arrows: false,
    speed: 300,
    rows: 2,
    slidesPerRow: 3,
    // slidesToShow: 3,
    appendDots: (dots) => (
      <div
        className="link"
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="link"
        style={{
          width: '46px',
          height: '4px',
          marginRight: '12px',
          backgroundColor: '#AAB2C8',
          borderRadius: '4px',
        }}
      ></div>
    ),
  };

  return (
    <>
      <StyledTeam isDarkMode={isDarkMode}>
        <div className="container">
          <div className="teamTitle">Calo Team</div>
          <div className="description">
            We cooperate with experienced professors who are blockchain,
            e-commerce associates, gaming studios, and finance experts. And
            dynamic young members who adapt quickly to technology and experience
            in the fitness & healthy lifestyle field.
          </div>
          <div className="teamSlider">
            {teamMember
              .filter((t) => t.position !== 'ADVISOR')
              .map((member, i) => (
                <div className="teamCard" key={i}>
                  <div className="cardImage">
                    <img src={member.avatar} />
                  </div>
                  <div className="teamInfo">
                    <div className="position">{member.position}</div>
                    <div className="name">{member.name}</div>
                    {/* <div className="link flex-center">
                      <a href="">
                        <img src={iconTwitterSm} />
                      </a>
                      <a href="">
                        <img src={iconLinkedinSm} />
                      </a>
                    </div> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </StyledTeam>
      <StyledTeam isDarkMode={isDarkMode}>
        <div className="container">
          <div className="advisors">
            <div className="teamTitle">Advisor</div>
            <div className="cardWrapper">
              {teamMember
                .filter((t) => t.position === 'ADVISOR')
                .map((member, i) => (
                  <div className="teamCard" key={i}>
                    <div className="cardImage">
                      <img src={member.avatar} />
                    </div>
                    <div className="teamInfo">
                      <div className="position">{member.position}</div>
                      <div className="name">{member.name}</div>
                      <div className="jobDesc">{member.jobDesc}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </StyledTeam>
    </>
  );
};

export default TeamDesktop;
