import React from 'react';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import { useAppStore } from 'src/hooks/useAppStore';
import { partners, corePartners } from '../partnersList';
import { StyledPartnersDesktop } from './styled';

const PartnersDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const slideSettings = {
    dots: true,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    infinite: false,
    arrows: false,
    speed: 300,
    rows: 1,
    slidesPerRow: 6,
  };

  return (
    <StyledPartnersDesktop isDarkMode={isDarkMode}>
      <div className="container partners-container">
        <div className="title">Partners & Communities</div>
        <div className="partnerWrapper">
          {corePartners.map((p, i) => (
            <div className="partnerCard">
              <div className="cardWrapper">
                <div className="cardImage">
                  <img
                    src={isDarkMode ? p.logoDark : p.logoLight}
                    alt={p.name}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="slider-partner">
          <SliderSlick showArrow={false} settings={slideSettings} paddingX="16px" paddingY="">
            {partners.map((p, i) => (
              <div className="partnerCard">
                <div className="cardWrapper">
                  <div className="cardImage grey">
                    <img
                      src={isDarkMode ? p.logoDark : p.logoLight}
                      alt={p.name}
                    />
                  </div>
                </div>
              </div>
            ))}
          </SliderSlick>

        </div>
      </div>
    </StyledPartnersDesktop>
  );
};

export default PartnersDesktop;
