import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import ChooesSneakerDesktop from './ChooesSneakerDesktop';
import ChooseSneakerMobile from './ChooseSneakerMobile';

const StepChooseSneaker = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<ChooesSneakerDesktop {...props} />}
      mobileView={<ChooseSneakerMobile {...props} />}
    />
  );
};

export default StepChooseSneaker;
