import React from 'react';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import { useAppStore } from 'src/hooks/useAppStore';
import teamBg from 'src/assets/images/team/mobile/teamBgMb.png';
import { teamMember } from '../teamMember';
import { StyledTeamMobile } from './styled';
import breakpoint from 'src/variables/breakpoint';

const TeamMobile = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const slideSettings = {
    className: 'center',
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    infinite: false,
    centerMode: true,
    centerPadding: '0px',
    speed: 500,
    dots: true,
    rows: 1,
    slidesPerRow: 2,
    responsive: [
      {
        breakpoint: breakpoint.lgMin,
        settings: {
          centerPadding: '200px',
          infinite: false,
          centerMode: false,
          rows: 1,
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: '0',
          infinite: true,
          centerMode: true,
          rows: 1,
          slidesPerRow: 2,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '6px',
          height: '6px',
          backgroundColor: '#AAB2C8',
          borderRadius: '50%',
        }}
      ></div>
    ),
  };

  return (
    <StyledTeamMobile isDarkMode={isDarkMode}>
      {/* {isDarkMode && (
        <div className="teamBackground">
          <img src={teamBg} />
        </div>
      )} */}
      <div className="container">
        <div className="header">OUR TEAM</div>
        <div className="teamTitle">Calo Team</div>
        <div className="description">
          We cooperate with experienced professors who are blockchain,
          e-commerce associates, gaming studios, and finance experts. And
          dynamic young members who adapt quickly to technology and experience
          in the fitness & healthy lifestyle field.
        </div>
        <div className="teamSlider">
          <SliderSlick
            showArrow={false}
            settings={slideSettings}
            paddingX="0"
            paddingY="10px"
          >
            {teamMember
              .filter((t) => t.position !== 'ADVISOR')
              .map((member, i) => (
                <div className="teamCard" key={i}>
                  <div className="cardImage">
                    <img src={member.avatar} />
                  </div>
                  <div className="teamInfo">
                    <div className="position">{member.position}</div>
                    <div className="name">{member.name}</div>
                    {/* <div className="jobDesc">{member.jobDesc}</div> */}
                  </div>
                </div>
              ))}
          </SliderSlick>
        </div>
        <div className="advisors">
          <div className="teamTitle">Advisor</div>
          <div className="teamSlider">
            <SliderSlick
              showArrow={false}
              settings={slideSettings}
              paddingX="0"
              paddingY="10px"
            >
              {teamMember
                .filter((t) => t.position === 'ADVISOR')
                .map((member, i) => (
                  <div className="teamCard" key={i}>
                    <div className="cardImage">
                      <img src={member.avatar} />
                    </div>
                    <div className="teamInfo">
                      <div className="position">{member.position}</div>
                      <div className="name">{member.name}</div>
                      <div className="jobDesc">{member.jobDesc}</div>
                    </div>
                  </div>
                ))}
            </SliderSlick>
          </div>
        </div>
      </div>
    </StyledTeamMobile>
  );
};

export default TeamMobile;
