import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import CustomerJourneyDesktop from './CustomerJourneyDesktop';
import CustomerJourneyMobile from './CustomerJourneyMobile';

const CustomerJourney = (props) => {
  return (
    <>
      <ResponsiveLayout
        desktopView={<CustomerJourneyDesktop {...props} />}
        mobileView={<CustomerJourneyMobile {...props} />}
      />
    </>
  );
};

export default CustomerJourney;
