import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledLayout = styled(Layout)`
  overflow: hidden;
  background: ${({ theme }) => theme.body};
  min-height: 100vh;
  color: #fff;
`;

export const StyledAnimatedCursor = styled.div`
  > div {
    border: ${({ theme }) => theme.cursorBorder};
    border-inline-style: solid;
    background-color: rgba(255, 255, 255, 0.01);
  }
`;

export const StyledContainer = styled.div`
  position: relative;
  max-width: 1216px;
  margin: 0 auto;
  padding-bottom: 5rem;
  text-align: left;
  width: 100%;
  * {
    color: ${({ theme }) => theme.title};
  }
  h1 {
    font-size: 2rem;
  }
  ol li {
    margin-bottom: 5px;
  }
  a {
    color: #f67d3e;
  }
`;
