import styled from 'styled-components';

export const StyledTeam = styled.section`
  padding: 80px 0;
  margin-top: -1px;
  position: relative;
  z-index: 3;
  .semiCircleBg {
    position: absolute;
    top: 300px;
    right: 0;
    z-index: -1;
    img {
      width: 100%;
    }
  }
  .container {
    max-width: 1223px;
    margin: auto;
    .header {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.01em;
      color: ${({ theme }) => theme.header};
      padding-bottom: 8px;
    }
    .teamTitle {
      font-weight: 600;
      font-size: 60px;
      line-height: 68px;
      text-align: center;
      color: #ffffff;
      padding-bottom: 16px;
    }
    .description {
      max-width: 660px;
      margin: auto;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.4px;
      color: #bfbfbf;
      color: ${({ theme }) => theme.description};
    }

    .advisors {
      /* padding-top: 100px; */
      position: relative;
      .cardWrapper {
        margin-top: 80px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .teamCard {
          width: 25%;
        }
      }
    }

    .teamSlider {
      margin-top: 64px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 170px;
      row-gap: 40px;
      .slick-slider {
        .slick-dots {
          bottom: -60px;
        }
      }
    }

    .teamCard {
      /* padding: 38px; */
      width: 170px;
      .cardImage {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 176px;
        }
      }
    }
    .teamInfo {
      padding-top: 40px;
      .position {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.0125em;
        color: #f45c0e;
        padding-bottom: 8px;
      }
      .name {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }
      .jobDesc {
        max-width: 244px;
        margin: 0 auto;
        padding-top: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.0125em;
        color: #8c8c8c;
      }
      .link {
        padding-top: 16px;
        a {
          &:not(:last-child) {
            margin-right: 24px;
          }
        }
      }
    }
    :global(.slick-slide) {
      > div {
        display: flex;
        align-items: flex-start;
      }
    }
    @media (max-width: 1200px) {
      .advisors {
        .cardWrapper {
          justify-content: center;
          .teamCard {
            width: 300px;
          }
        }
      }
      .teamInfo {
        .name {
          font-size: 22px;
          line-height: 22px;
        }
        .position {
          font-size: 18px;
          line-height: 18px;
        }
        .jobDesc {
          font-size: 14px;
        }
      }
    }
  }
`;
