import styled from 'styled-components';

export const StyledBannerDesktop = styled.div`
  padding: 0 0 100px 0;
  .banner-container {
    max-width: 1216px;
    margin: auto;
    width: 100%;
    height: 435px;
    background: #091434;
    border-radius: 24px;
    @media (max-width: 1024px) {
      max-width: 900px;
    }
    .wrapper {
      height: 100%;
      position: relative;
      top: -50px;
      .col-left {
        padding-left: 10%;
      }
    }
    @media (max-width: 1024px) {
      height: 375px;
      .wrapper {
        top: -70px;
      }
    }
  }
`;
