import styled from 'styled-components';
import backgroundDownload from 'src/assets/images/howtoplay/backgroundDownload.png';

export const StyledDownloadDesktop = styled.div`
  padding: 90px 0 64px 0;
  background-image: url(${backgroundDownload});
  background-size: cover;
  background-repeat: round;
  .container {
    /* max-width: 1216px; */
    margin: auto;
    padding: 0;
    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #e5e5e5;
    }
    .content-wrapper {
      .content-box {
        .token-img {
          text-align: right;
          margin-top: -350px;
          margin-bottom: 60px;
        }
        .title {
          font-weight: 600;
          font-size: 34px;
          line-height: 58px;
          text-align: center;
          color: #ffffff;
          padding-bottom: 28px;
        }
        .btn-download {
          img {
            margin: 0 16px;
          }
          @media (max-width: 1024px) {
            img {
              width: 200px;
            }
          }
        }
      }
      @media (max-width: 1024px) {
        .content-box {
          .title {
            font-size: 30px;
          }
        }
        .contentImg {
          width: 100%;
        }
      }
    }
  }
`;
