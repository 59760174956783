import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import ShareSocialDesktop from './ShareSocialDesktop';
import ShareSocialMobile from './ShareSocialMobile';

const StepShareSocial = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<ShareSocialDesktop {...props} />}
      mobileView={<ShareSocialMobile {...props} />}
    />
  );
};

export default StepShareSocial;