import React from 'react';
import phoneSocial from 'src/assets/images/howtoplay/phoneSocialSm3.png';
import { StyledShareSocialMobile } from './styled';

const ShareSocialMobile = () => {
  return (
    <StyledShareSocialMobile>
      <div className="container">
        <div className='img-wrapper'>
          <img className="img-fluid" src={phoneSocial} alt="phone" />
          <div className="blur" />
        </div>
      </div>
    </StyledShareSocialMobile>
  );
};

export default ShareSocialMobile;
