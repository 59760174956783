import styled from 'styled-components';

export const StyledShareSocialDesktop = styled.div`
  padding: 180px 0;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .col-content {
      .content-title {
        .title {
          font-weight: 700;
          font-size: 64px;
          line-height: 40px;
          letter-spacing: 0.25px;
          color: #ffffff;
          @media (max-width: 1024px) {
            font-size: 50px;
          }
          .bold {
            color: #ff8a34;
            font-style: italic;
            padding-right: 16px;
          }
        }
      }
      .top {
        padding-top: 200px;
      }
      .bottom {
        padding-top: 100px;
        padding-left: 200px;
      }
      @media (max-width: 1024px) {
        .top {
          padding-top: 200px;
          padding-left: 20px;
        }
        .bottom {
          padding-left: 160px;
        }
      }
    }
    .col-img {
      height: 533px;
      .phone-box {
        position: absolute;
        left: -100px;
        z-index: 1;
      }
      .blur {
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 872px;
        height: 872px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(49, 193, 255, 0.3) -60%,
          rgba(53, 85, 255, 0) 80%
        );
        @media (max-width: 1024px) {
          left: 50%;
          width: 772px;
          height: 772px;
        }
      }
    }
  }
`;
