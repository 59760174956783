import { Row } from 'antd';
import React from 'react';
import titleBannerHTPMb from 'src/assets/images/howtoplay/titleBannerHTPMb.png';
import { StyledBannerMobile } from './styled';

const BannerMobile = () => {
  return (
    <StyledBannerMobile>
      <div className='banner-container'>
        <Row className="wrapper" align="middle" justify="center">
          <img className='banner-img img-fluid' src={titleBannerHTPMb} />
        </Row>
      </div>
    </StyledBannerMobile>
  );
};

export default BannerMobile;
