export const milestones = [
  {
    key: 'April',
    labels: ['Wallet In-app On-chain', 'Marketplace In-app', 'Sneaker System'],
  },
  {
    key: 'May',
    labels: ['Single Mode Beta', 'Shoebox Minting', 'OFFICIAL LAUNCH'],
  },
  {
    key: 'June',
    labels: ['Earning Gacha', 'Stone Upgrade', 'NFT Collaborations'],
  },
  {
    key: 'QIII-22',
    labels: ['Marketplace Upgrade', 'Tournament Mode', 'Achievement System'],
  },
  {
    key: 'QIV-22',
    labels: ['Mission System', 'Multi-chain', 'Rental System'],
  },
  {
    key: 'QI-23',
    labels: ['Team Mode', 'AR Single Mode', 'Customization'],
  },
];
