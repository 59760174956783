import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useDarkMode } from 'src/hooks/useDarkMode';
import { darkTheme, lightTheme } from 'src/styles/theme-colors';
import AnimatedCursor from 'react-animated-cursor';
import Introduction from 'src/components/Introduction/Introduction';
import Topics from 'src/components/Topics/Topics';
import Metaverse from 'src/components/Metaverse/Metaverse';
import CustomerJourney from 'src/components/CustomerJourney/CustomerJourney';
import RoadmapV2 from 'src/components/RoadmapV2/RoadmapV2';
import Team from 'src/components/Team/Team';
import Partners from 'src/components/Partners/Partners';
import Documents from 'src/components/Documents/Documents';
import Social from 'src/components/Social/Social';
import Footer from 'src/components/Footer/Footer';
import AppScreen from 'src/components/AppScreen/AppScreen';
import iconTopDark from 'src/assets/images/iconTopDark.png';
import iconTopLight from 'src/assets/images/iconTopLight.png';
import style from './home.module.scss';
import { StyledAnimatedCursor, StyledLayout } from './home.styled';
import Header from 'src/components/Header/Header';
import { Affix, BackTop } from 'antd';
import { scrollTo } from 'src/utils';

const Home = (props) => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'dark' ? darkTheme : lightTheme;

  const navigateScroll = (toId, toRef, duration) =>
    scrollTo({ id: toId, ref: toRef, duration });

  useEffect(() => {
    const sectionId = window.location.hash;
    if (sectionId) {
      setTimeout(() => {
        navigateScroll(sectionId.substring(1));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  return (
    <ThemeProvider theme={themeMode}>
      {/* <StyledAnimatedCursor>
        <AnimatedCursor
          innerSize={12}
          outerSize={30}
          color={theme === 'dark' ? '255, 255, 255' : '90, 91, 116'}
          outerAlpha={0.2}
          innerScale={1}
          outerScale={2}
          trailingSpeed={2}
        />
      </StyledAnimatedCursor> */}
      <StyledLayout>
        <div className="intro-section">
          <Introduction toggleTheme={toggleTheme} />
        </div>
        <div id="topics">
          <Topics />
        </div>
        <AppScreen />
        <div id="gameplay">
          <Metaverse />
        </div>
        <div id="nft-system">
          <CustomerJourney />
        </div>
        {/* <div id="token">
          <Token />
        </div>
        <div id="tokenomics">
          <Tokenomics />
        </div>
        <div id="tokenmetrics">
          <TokenMetrics />
        </div> */}
        <div id="roadmap">
          <RoadmapV2 />
        </div>
        <div id="team">
          <Team />
        </div>
        <div id="partners">
          <Partners />
        </div>
        {/* <div>
          <News />
        </div>
        <div>
          <Videos />
        </div> */}
        {/* <div className={[style.section, style.document].join(' ')}>
          <Documents />
        </div> */}
        <div className={[style.section].join(' ')}>
          <Social />
        </div>
        <Footer />
        <Affix offsetBottom={80}>
          <BackTop>
            <img
              src={theme === 'dark' ? iconTopDark : iconTopLight}
              width="56px"
              height="56px"
            />
          </BackTop>
        </Affix>
      </StyledLayout>
    </ThemeProvider>
  );
};

export default Home;
