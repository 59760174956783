import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import DownloadDesktop from './DownloadDesktop';
import DownloadMobile from './DownloadMobile';

const StepDownload = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<DownloadDesktop {...props} />}
      mobileView={<DownloadMobile {...props} />}
    />
  );
};

export default StepDownload;