import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useAppStore } from 'src/hooks/useAppStore';
import customization from 'src/assets/images/nft-system/customization.png';
import rental from 'src/assets/images/nft-system/rental.png';
import sneaker from 'src/assets/images/nft-system/sneaker.png';
import stone from 'src/assets/images/nft-system/stone.png';
import inapp from 'src/assets/images/nft-system/inapp.png';
import medal from 'src/assets/images/nft-system/medal.png';
import { StyledCustomerJourneyMobile } from './styled';
import { Col, Row } from 'antd';

const CustomerJourneyMobile = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const [inViewRef, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  const controls = useAnimation();

  const nonAnimatingContainer = {
    visible: {
      transition: {
        when: 'beforeChildren',
        duration: 0.5,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
    hidden: {
      transition: {
        duration: 0.3,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
  };

  const animationVariant = {
    visible: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: {
        when: 'beforeChildren',
        duration: 0.5,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      scaleY: 1,
      y: '-10%',
      transition: {
        duration: 0.3,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
  };

  function getAnimation() {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 200);
    } else {
      controls.start('hidden');
    }
  }

  useEffect(() => {
    getAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, inView]);

  return (
    <StyledCustomerJourneyMobile isDarkMode={isDarkMode} ref={inViewRef}>
      <AnimatePresence>
        <div className="title">NFTs System</div>
        <div className="description">
          Calo Metaverse builds up the NFTs Sneakers system and creates game-play
          around them.
        </div>
        <motion.div
          className="itemWrapper"
          initial="hidden"
          animate={controls}
          variants={nonAnimatingContainer}
        >
          <Row gutter={16} align="middle" justify="space-between">
            <Col xs={12} sm={8}>
              <motion.div className="box" variants={animationVariant} key="customization">
                <div className="blur" />
                <div className="img">
                  <img className="img-fluid" src={customization} />
                </div>
                <div className="footer">
                  <div className="text">Customization</div>
                </div>
              </motion.div>
            </Col>
            <Col xs={12} sm={8}>
              <motion.div className="box" variants={animationVariant} key="rental">
                <div className="blur" />
                <div className="img center">
                  <img className="img-fluid" src={rental} />
                </div>
                <div className="footer">
                  <div className="text">Rental System</div>
                </div>
              </motion.div>
            </Col>
            <Col xs={12} sm={8}>
              <motion.div className="box" variants={animationVariant} key="sneaker">
                <div className="blur" />
                <div className="img center">
                  <img className="img-fluid" src={sneaker} />
                </div>
                <div className="footer">
                  <div className="text">Mint new Sneaker</div>
                </div>
              </motion.div>
            </Col>
            <Col xs={12} sm={8}>
              <motion.div variants={animationVariant} className="box" key="stone">
                <div className="blur" />
                <div className="img center">
                  <img className="img-fluid" src={stone} />
                </div>
                <div className="footer">
                  <div className="text">NFT Stone</div>
                </div>
              </motion.div>
            </Col>
            <Col xs={12} sm={8}>
              <motion.div className="box" variants={animationVariant}>
                <div className="blur" />
                <div className="img center">
                  <img className="img-fluid" src={inapp} />
                </div>
                <div className="footer">
                  <div className="text">In-app Trading</div>
                </div>
              </motion.div>
            </Col>
            <Col xs={12} sm={8}>
              <motion.div variants={animationVariant} className="box" key="medal">
                <div className="blur" />
                <div className="img center">
                  <img className="img-fluid" src={medal} />
                </div>
                <div className="footer">
                  <div className="text">NFT Achievement Medal</div>
                </div>
              </motion.div>
            </Col>
          </Row>
        </motion.div>
      </AnimatePresence>
    </StyledCustomerJourneyMobile>
  );
};

export default CustomerJourneyMobile;
