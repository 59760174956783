import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useAppStore } from 'src/hooks/useAppStore';
import carlLory from 'src/assets/images/carlLorySocialNew.png';
import curvedSocial from 'src/assets/images/curvedSocial.svg';
import { StyledSocial } from './styled';
import { icons, socials } from '../socials';
import iconTiktokWhite from 'src/assets/images/social/desktop/iconTiktokColor.png';
import iconFacebookColor from 'src/assets/images/social/desktop/iconFacebookColor.png';

const SocialDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const [focus, setFocus] = useState('');

  return (
    <StyledSocial isDarkMode={isDarkMode}>
      <div className='blur' />
      <div className="container">
        <Row justify="space-between">
          <Col span={12} className="containerLeft">
            <div className="title">Join Our Community</div>
            <div className="description">
              Connect with Calo community to update the newest thing about the
              market and our business.
            </div>
            <div className="icon-wrapper">
              {socials.map((social, index) => (
                <a
                  className="image-social"
                  href={social.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={social.iconWhite} />
                  <img className="hover-img" src={social.iconColor} />
                </a>
              ))}
            </div>
          </Col>
          <Col span={12} className="containerRight">
            <div className="mainObject">
              <img src={carlLory} />
            </div>
            {/* <motion.div
              className="iconFacebook"
              animate={{
                y: ['10px', '0px', '10px'],
              }}
              transition={{ repeat: Infinity, duration: 2 }}
            >
              <a
                href="https://www.facebook.com/calometaverse"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebookIcon} />
              </a>
            </motion.div>
            <motion.div
              className="iconInsta"
              animate={{
                y: ['10px', '0px', '10px'],
              }}
              transition={{ repeat: Infinity, duration: 2 }}
            >
              <a
                href="https://www.instagram.com/caloappvn/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instaIcon} />
              </a>
            </motion.div>
            <motion.div
              className="iconYoutube"
              animate={{
                y: ['5px', '0px', '5px'],
              }}
              transition={{ repeat: Infinity, duration: 2 }}
            >
              <a
                href="https://www.youtube.com/channel/UCoSkHLD97-rSElS_6fqF1NQ"
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtubeIcon} />
              </a>
            </motion.div>
            <motion.div
              className="iconTwitter"
              animate={{
                y: ['5px', '0px', '5px'],
              }}
              transition={{ repeat: Infinity, duration: 2 }}
            >
              <a
                href="https://twitter.com/AppCalo"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitterIcon} />
              </a>
            </motion.div>
            <motion.div
              className="iconTelegram"
              animate={{
                y: ['10px', '0px', '10px'],
              }}
              transition={{ repeat: Infinity, duration: 2 }}
            >
              <a href="https://t.me/caloapp" target="_blank" rel="noreferrer">
                <img src={telegramIcon} />
              </a>
            </motion.div>
            <motion.div
              className="iconTiktok"
              animate={{
                y: ['5px', '0px', '5px'],
              }}
              transition={{ repeat: Infinity, duration: 2 }}
            >
              <a
                href="http://www.tiktok.com/@caloapp"
                target="_blank"
                rel="noreferrer"
              >
                <img src={tiktokIcon} />
              </a>
            </motion.div>
            <motion.div
              className="iconDiscord"
              animate={{
                y: ['10px', '0px', '10px'],
              }}
              transition={{ repeat: Infinity, duration: 2 }}
            >
              <a
                href="https://dsc.gg/calocommunity"
                target="_blank"
                rel="noreferrer"
              >
                <img src={discordIcon} />
              </a>
            </motion.div> */}
          </Col>
        </Row>
      </div>
    </StyledSocial>
  );
};

export default SocialDesktop;
