import styled from 'styled-components';

export const StyledBannerWC = styled.div`
  max-width: 1216px;
  margin: 0 auto;
  margin-bottom: -50px;
  padding: 80px 0 0 0;
  width: 100%;
  height: calc(100% - 265px);

  .rowContainer {
    height: 100%;
    .imageColumn {
      position: relative;
      .calo {
        position: absolute;
        top: -20px;
        left: 40px;
        width: 152px;
      }
      .fit {
        position: absolute;
        top: 300px;
        right: 30px;
        width: 120px;
      }
      .calo-circle {
        position: absolute;
        width: 290px;
        bottom: 20px;
        left: -200px;
        @media (max-width: 1024px) {
          width: 200px;
          left: -80px;
        }
      }
      .image-box {
        img {
          margin-left: 100px;
          margin-top: -100px;
          width: 360px;
          @media (max-width: 1024px) {
            width: 300px;
          }
        }
      }
    }

    .contentColumn {
      padding-top: 24px;
      position: relative;
      .circle {
        position: absolute;
        top: -40px;
        left: -55px;
        width: 186px;
        z-index: -1;
      }
      .title {
        img {
          max-width: 316px;
        }
      }
      .jackpot {
        max-width: 785px;
        margin-top: 32px;
        @media (max-width: 1024px) {
          width: 480px;
        }
      }
      .desc {
        max-width: 523px;
        margin-top: 32px;
      }
      .downloadBox {
        margin-top: 80px;
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
    }
  }
`;
