import styled from 'styled-components';
import { Input } from 'antd';

export const StyledFooter = styled.footer`
  width: 100%;
  padding: 72px 0 24px 0;
  background: #0c0d18;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: unset;
    .copyright {
      text-align: left;
      margin-top: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.4px;
      color: #738dc3;
      @media (max-width: 1024px) {
        font-size: 12px;
      }
    }
    .caloInfo {
      .footerLogo {
        width: 156px;
      }
    }

    .companyInfo {
      margin-top: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #bfbfbf;
      @media (max-width: 1024px) {
        font-size: 12px;
      }
      div:not(:last-child) {
        margin-bottom: 8px;
      }
      a {
        color: #bfbfbf;
      }
    }

    .footerMenu {
      list-style: none;
      padding: 0;
      .footerLink {
        padding-right: 34px;
        padding-bottom: 24px;
        a {
          color: #fcfcfc;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;
          transition: all ease 0.4s;
          &:hover {
            color: #ff7510;
          }
          @media (max-width: 1024px) {
            font-size: 12px;
          }
        }
        &:last-child {
          padding-right: 0;
          padding-bottom: 0;
        }
      }
    }

    @media (max-width: 1024px) {
      .caloInfo {
        .copyright {
          margin-top: 13px;
          color: #8a94a6;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }

    .inputBox {
      width: 100%;
      padding-top: 14px;
      .label {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        letter-spacing: 0.0125em;
        margin-bottom: 14px;
      }
    }

    .data-provided {
      margin-top: 24px;
      .text {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.4px;
        color: #595959;
      }
      .coingecko {
        margin-right: 24px;
        img {
        }
      }
    }
    .btn-download {
      cursor: pointer;
      padding: 16px 13.5px;
      border: 2px solid #ffb37d;
      border-radius: 36px;
      .icon {
        padding-right: 8px;
      }
      .label {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1.25px;
        color: #ffb37d;
      }
      @media (max-width: 1024px) {
        padding: 12px;
        .icon {
          width: 26px;
          padding-right: 6px;
        }
        .label {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .container {
      max-width: 980px;
    }
  }
`;

export const StyledInputEmail = styled(Input)`
  &.ant-input-affix-wrapper {
    background: transparent;
    border: 1px solid #8a8cb2;
    border-radius: 8px;
    padding: 12px 16px;
    color: #8a8cb2;
    &:focus {
      box-shadow: unset;
    }
    &:not(.ant-input-affix-wrapper-disabled) {
      &:hover {
        border-color: #8a8cb2;
      }
    }
    .ant-input-affix-wrapper-focused {
      box-shadow: unset;
    }
    .ant-input {
      background: transparent;
      color: #8a8cb2;
      &::placeholder {
        color: #8a8cb2;
      }
    }
  }
`;
