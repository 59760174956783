import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import FindSneakerMarketDesktop from './FSMDesktop';
import FindSneakerMarketMobile from './FSMMobile';

const StepFindSneakerMarket = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<FindSneakerMarketDesktop {...props} />}
      mobileView={<FindSneakerMarketMobile {...props} />}
    />
  );
};

export default StepFindSneakerMarket;
