import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import strongGPS from 'src/assets/images/howtoplay/strongGPS.png';
import speedometer from 'src/assets/images/howtoplay/speedometer.png';
import runningMeasure from 'src/assets/images/howtoplay/runningMeasure.png';
import { StyledMeasureDesktop } from './styled';

const MeasureDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledMeasureDesktop isDarkMode={isDarkMode}>
      <div className="container">
        <div className="title">Measure</div>
        <div className="main-title">RUNNING OUTDOOR WITH GPS</div>
        <div className="flex-evenly">
          <div className="flex-center">
            <img className="icon" src={strongGPS} alt="gps" />
            <div className="text-desc">Strong GPS</div>
          </div>
          <div className="flex-center">
            <div className="text-desc">Ensure</div>
            <span className="dot-running" />
            <div className="text-desc">Running</div>
          </div>
          <div className="flex-center">
            <img className="icon" src={speedometer} alt="speedometer" />
            <div className="text-desc">Run within orange zone</div>
          </div>
        </div>
        <div className="content-wrapper flex-center">
          <div className="blur" />
          <img className="img-fluid" src={runningMeasure} alt="img-detail" />
        </div>
      </div>
    </StyledMeasureDesktop>
  );
};

export default MeasureDesktop;
