import React from 'react';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import { useAppStore } from 'src/hooks/useAppStore';
import breakpoint from 'src/variables/breakpoint';
import { partners, corePartners } from '../partnersList';
import { StyledPartnersMobile } from './styled';

const PartnersMobile = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const slideSettings = {
    className: 'center',
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    infinite: false,
    centerMode: true,
    centerPadding: '0px',
    speed: 500,
    dots: true,
    rows: 2,
    slidesPerRow: 3,
    responsive: [
      {
        breakpoint: breakpoint.lgMin,
        settings: {
          centerPadding: '200px',
          infinite: false,
          centerMode: false,
          rows: 2,
          slidesPerRow: 6,
        },
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: '0px',
          infinite: true,
          centerMode: true,
          rows: 2,
          slidesPerRow: 3,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '24px',
          height: '4px',
          marginRight: '12px',
          backgroundColor: '#AAB2C8',
          borderRadius: '4px',
        }}
      ></div>
    ),
  };

  return (
    <StyledPartnersMobile isDarkMode={isDarkMode}>
      <div className="container">
        <div className="header">BURN TO EARN</div>
        <div className="title">Partners & Communities</div>
        <div className="partners">
          {corePartners.map((p, i) => (
            <div className="partnerCard">
              <div className="cardWrapper">
                <div className="cardImage">
                  <img
                    src={isDarkMode ? p.logoDark : p.logoLight}
                    alt={p.name}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container partners-container">
        <div className="slider-partner">
          <SliderSlick showArrow={false} settings={slideSettings} paddingX="16px" paddingY="">
            {partners.map((p, i) => (
              <div className="partnerCard">
                <div className="cardWrapper">
                  <div className="cardImage link">
                    <img
                      src={isDarkMode ? p.logoDark : p.logoLight}
                      alt={p.name}
                    />
                  </div>
                </div>
              </div>
            ))}
          </SliderSlick>
        </div>
      </div>
    </StyledPartnersMobile>
  );
};

export default PartnersMobile;
