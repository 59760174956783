import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import iconStamina from 'src/assets/images/howtoplay/iconStamina.png';
import fitTokenXs from 'src/assets/images/fitTokenXs.png';
import { SneakerBoxItem, StyledChooseSneakerMobile } from './styled';
import breakpoint from 'src/variables/breakpoint';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import { renderSneakerImg, renderTypeIcon, sneakerStats } from '../data';

const ChooseSneakerMobile = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const slideSettings = {
    className: 'center',
    infinite: true,
    centerMode: true,
    centerPadding: '40px',
    slidesToShow: 1,
    speed: 500,
    dots: false,
    responsive: [
      {
        breakpoint: breakpoint.lgMin,
        settings: {
          centerPadding: '100px',
          centerMode: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: '60px',
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          centerPadding: '30px',
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '24px',
          height: '4px',
          marginRight: '12px',
          backgroundColor: '#AAB2C8',
          borderRadius: '4px',
        }}
      ></div>
    ),
  };

  return (
    <StyledChooseSneakerMobile isDarkMode={isDarkMode}>
      <div className="container">
        <div className="main-title py-10">CHOOSE A SNEAKER</div>
        <div className="title py-10">THAT FITS YOUR FITNESS LEVEL</div>
        <div className="desc py-10">
          You only earn when you have an NFT sneaker and move outdoors with GPS
        </div>
        <div className="boxContainer">
          <div className="boxItems">
            <SliderSlick
              showArrow={false}
              settings={slideSettings}
              paddingX="12px"
              paddingY="20px"
            >
              {sneakerStats.map((s, key) => (
                <SneakerBoxItem type={s.name} key={key}>
                  <div className="type flex-center">
                    <img
                      className="icon"
                      src={renderTypeIcon(s.name)}
                      alt="icon"
                    />
                    <div className="name">{s.name}</div>
                  </div>
                  <div className="boxImg flex-center">
                    <img src={renderSneakerImg(s.name)} alt="shoeImg" />
                  </div>
                  <div className="speed">{s.speed} (KM/HR)</div>
                  <div className="earn flex-center">
                    <span className="stamina flex-center">
                      <span className="text">1</span>
                      <img src={iconStamina} alt="stamina" />
                    </span>
                    <span className="equal">≈</span>
                    <span className="fit-earn flex-start">
                      <span className="text">{s.fitEarn}</span>
                      <img src={fitTokenXs} alt="token" />
                    </span>
                  </div>
                </SneakerBoxItem>
              ))}
            </SliderSlick>
          </div>
        </div>
      </div>
    </StyledChooseSneakerMobile>
  );
};

export default ChooseSneakerMobile;
