import picLinhTran from 'src/assets/images/team/desktop/polygon2x/picLinhTran.png';
import picTuanPhan from 'src/assets/images/team/desktop/polygon2x/picTuanPhan.png';
import picTriNguyen from 'src/assets/images/team/desktop/polygon2x/picTriNguyen.png';
import picDungTran from 'src/assets/images/team/desktop/polygon2x/picDungTran.png';
import picThaoNguyen from 'src/assets/images/team/desktop/polygon2x/picThaoNguyen.png';
import picThiAnhDao from 'src/assets/images/team/desktop/polygon2x/picThiAnhDao.png';
import picMarcusLeng from 'src/assets/images/team/desktop/polygon2x/picMarcusLeng.png';
import picDiepPham from 'src/assets/images/team/desktop/polygon2x/picDiepPham.png';
import picThanhNguyen from 'src/assets/images/team/desktop/polygon2x/picThanhNguyen.png';
import picHungLuong from 'src/assets/images/team/desktop/polygon2x/picHungLuong.png';

const teamMember = [
  {
    name: 'Mr. Tuan Phan',
    position: 'CEO - FOUNDER',
    avatar: picTuanPhan,
    jobDesc: 'CEO Sweetlife / COO Hanofarm',
  },
  {
    name: 'Mr. Tri Nguyen',
    position: 'CTO - FOUNDER',
    avatar: picTriNguyen,
    jobDesc: 'Co-Founder Moonka',
  },
  {
    name: 'Mr. Thanh Nguyen',
    position: 'CSO',
    avatar: picThanhNguyen,
    jobDesc: '',
  },
  {
    name: 'Mr. Marcus Leng',
    position: 'ADVISOR',
    avatar: picMarcusLeng,
    jobDesc: 'Co-Founder of MOC Vietnam',
  },
  {
    name: 'Ms. Thi Anh Dao',
    position: 'ADVISOR',
    avatar: picThiAnhDao,
    jobDesc: 'CEO Isobar Vietnam',
  },
  {
    name: 'Mr. Hung Luong',
    position: 'ADVISOR',
    avatar: picHungLuong,
    jobDesc: 'Vice President VN Entrepreneurs Scientific Union',
  },
  {
    name: 'Ms. Dung Tran',
    position: 'ART DIRECTOR',
    avatar: picDungTran,
    jobDesc: 'Founder of Takidu8 Studio',
  },
  {
    name: 'Ms. Thao Nguyen',
    position: 'BRAND MANAGER',
    avatar: picThaoNguyen,
    jobDesc: 'MM of 365 Begin/Sweetlife',
  },
  {
    name: 'Ms. Linh Tran',
    position: 'OPERATION MANAGER',
    avatar: picLinhTran,
    jobDesc: 'OM of Hanofarm, Sweetlife',
  },
  {
    name: 'Ms. Diep Pham',
    position: 'COMMUNITY MANAGER',
    avatar: picDiepPham,
    jobDesc: 'Co-Founder of Sweetlife Ltd',
  },
];

export { teamMember };
