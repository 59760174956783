import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import {
  boxItemLeftVariant,
  boxItemRightVariant,
  nonAnimatingContainer,
} from 'src/variables/animationVariant';
import { useAppStore } from 'src/hooks/useAppStore';
import caloTokenNew from 'src/assets/images/calo-token/desktop/caloToken3.png';
import fitToken from 'src/assets/images/fitToken.png';
import blurBg from 'src/assets/images/blurBg.png';
import m2eTitle from 'src/assets/images/m2eTitle.png';
import { StyledTokenV2Desktop } from './styled';

const TokenV2Desktop = (props) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });
  const [appStore] = useAppStore();
  const controls = useAnimation();

  const { isDarkMode } = appStore;

  return (
    <StyledTokenV2Desktop isDarkMode={isDarkMode} ref={inViewRef}>
      <div className="container flex-between">
        <div className="title">
          <img src={m2eTitle} alt="title" />
        </div>
        <div className="tokens">
          <div className="token-wrapper flex-center">
            <div className='blur-bg'>
              <img src={blurBg} />
            </div>
            <div className="token-item calo">
              <motion.div
                animate={{
                  y: ['10px', '0px', '10px'],
                }}
                variants={boxItemLeftVariant}
                transition={{ repeat: Infinity, duration: 2 }}
                className="token-img "
              >
                <img className='img-fluid' src={caloTokenNew} />
              </motion.div>
              <div className="name">CALO TOKEN</div>
              <div className="desc">LIMITED</div>
              <div className="desc">500.000.000 Token</div>
            </div>
            <div className="token-item fit">
              <motion.div
                animate={{
                  y: ['5px', '0px', '5px'],
                }}
                variants={boxItemLeftVariant}
                transition={{ repeat: Infinity, duration: 2 }}
                className="token-img"
              >
                <img className='img-fluid' src={fitToken} />
              </motion.div>
              <div className="name">FIT TOKEN</div>
              <div className="desc">UNLIMITED</div>
              <div className="desc">SUPPLY</div>
            </div>
          </div>
        </div>
      </div>
    </StyledTokenV2Desktop>
  );
};

export default TokenV2Desktop;
