import styled from 'styled-components';

export const StyledIntroductionV2 = styled.div`
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .text-og {
      color: #ff7510 !important;
    }
    .header-title {
      margin-bottom: 40px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    .title {
      font-weight: 600;
      font-size: 65px;
      line-height: 68px;
      text-align: left;
      color: ${({ theme }) => theme.title};
      padding-bottom: 16px;
      @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 44px;
      }
      @media (max-width: 768px) {
        font-size: 28px;
        line-height: 32px;
      }
    }
    .intro-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 64px;
      @media (max-width: 768px) {
        padding-top: 24px;
        max-width: 500px;
        margin: 0 auto;
      }
      .content-column {
        width: 50%;
        margin-right: 66px;
        align-self: flex-start;

        @media (max-width: 1024px) {
          margin-right: 24px;
          padding: 0 16px;
        }
        .content-title {
          font-weight: 600;
          font-size: 34px;
          line-height: 40px;
          letter-spacing: 0.25px;
          color: ${({ theme }) => theme.title};
          margin-bottom: 16px;
          @media (max-width: 768px) {
            font-size: 20px;
          }
        }
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.4px;
          color: #bfbfbf;
          margin-bottom: 24px;
          @media (max-width: 568px) {
            font-size: 14px;
          }
        }
      }
      .image-column {
        /* width: 698px; */
        position: relative;
        .img {
          /* float: right; */
        }
        .icon-image {
          position: absolute;
          bottom: 0;
          left: -5%;
          width: 269px;
        }
      }
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        .content-column {
          width: 100%;
          margin-right: 0;
        }
        .image-column {
          width: 300px;
          margin-bottom: 32px;
          .icon-image {
            position: absolute;
            bottom: -10%;
            left: -40%;
            width: 200px;
          }
        }
      }
      @media (max-width: 568px) {
        .image-column {
          /* width: 240px; */
          .icon-image {
            position: absolute;
            bottom: -15%;
            right: 0;
            left: unset;
            width: 160px;
          }
        }
      }
    }
    .downloadBox {
      .btn-download {
        &:first-child {
          margin-right: 32px;
        }
      }
    }
  }
  .bottom-container {
    padding: 100px 0;
    @media (max-width: 768px) {
      width: 500px;
      margin: 0 auto;
    }
    @media (max-width: 568px) {
      padding: 100px 16px;
      width: 100%;
    }
    .img-wrapper {
      position: relative;
      @media (max-width: 768px) {
        margin: auto;
        display: flex;
        align-item: center;
        justify-content: center;
      }
      .main-img {
        position: relative;
        z-index: 2;
      }
      .blur {
        z-index: 0;
        width: 280px;
        height: 280px;
        border-radius: 50%;
        background: #425687;
        position: absolute;
        top: 40%;
        left: 40%;
        transform: translate(-50%, -50%);
        filter: blur(100px);
        @media (max-width: 568px) {
          width: 160px;
          height: 160px;
          filter: blur(70px);
        }
      }
    }
    .content-wrapper {
      @media (max-width: 1024px) {
        padding-top: 32px;
      }
    }
    .leading-title {
      font-weight: 600;
      font-size: 60px;
      line-height: 68px;
      color: #f2f2f2;
      @media (max-width: 1024px) {
        font-size: 48px;
      }
      @media (max-width: 768px) {
        font-size: 36px;
      }
      @media (max-width: 568px) {
        font-size: 24px;
        line-height: unset;
      }
    }
    .profit-content {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 568px) {
        flex-direction: column;
      }
      .content-column {
        .content-title {
          font-weight: 600;
          font-size: 34px;
          line-height: 40px;
          letter-spacing: 0.25px;
          color: #f5f5f5;
          margin-bottom: 16px;
          @media (max-width: 1024px) {
            font-size: 28px;
          }
          @media (max-width: 568px) {
            font-size: 18px;
          }
        }
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.4px;
          color: #e5e5e5;
          margin: 0;
          @media (max-width: 1024px) {
            font-size: 14px;
          }
        }
        @media (max-width: 568px) {
          width: 100%;
        }
      }
    }
  }
`;
