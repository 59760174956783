import styled from 'styled-components';
import roadmapV2Bg from 'src/assets/images/roadmap/desktop/roadmapV2Bg.png';
import roadmapBgMb from 'src/assets/images/roadmap/mobile/roadmapBgMb.png';
import boxPolygon from 'src/assets/images/boxPolygon.png';

export const StyledRoadmapV2Desktop = styled.div`
  padding: 100px 0;
  height: 900px;
  position: relative;
  background-image: url(${roadmapV2Bg});
  background-size: cover;
  background-repeat: round;
  @media (max-width: 768px) {
    background-image: url(${roadmapBgMb});
  }
  .container {
    padding: 0;
    max-width: 1216px;
    margin: 0 auto;
    .title {
      font-weight: 600;
      font-size: 60px;
      line-height: 68px;
      text-align: center;
      color: #ffffff;
      padding-bottom: 24px;
    }
    .wrapper {
      margin-top: 32px;
      .ant-col {
        margin-bottom: 64px;
      }
      .roadmap-item {
        background-image: url(${boxPolygon});
        background-position: center;
        background-size: auto;
        background-repeat: no-repeat;
        width: 100%;
        height: 280px;
        .content {
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          ul {
            padding: 0;
            color: #fff;
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.4px;
          }
          .milestone {
            margin-top: 24px;
            font-weight: 700;
            font-size: 34px;
            line-height: 40px;
            color: #ffffff;
            text-align: center;
          }
        }
      }
    }
  }
`;
