import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import SocialDesktop from './SocialDesktop';
import SocialMobile from './SocialMobile';

const Social = (props) => {
  return (
    <div>
      <ResponsiveLayout
        desktopView={<SocialDesktop {...props} />}
        mobileView={<SocialMobile {...props} />}
      />
    </div>
  );
};

export default Social;
