import React from 'react';
import { Row, Col } from 'antd';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import lunarTitle from 'src/assets/images/introduction/desktop/lunar/lunarTitle.png';
import lunarDesc from 'src/assets/images/introduction/desktop/lunar/lunarDesc.png';
import lunarCat from 'src/assets/images/introduction/desktop/lunar/lunarCat.png';
import caloToken from 'src/assets/images/calo-token/desktop/caloToken.png';
import fitToken from 'src/assets/images/fitTokenStr.png';

import { StyledBannerLunarGacha23 } from './styled';

const BannerLunarGacha23 = () => {
  return (
    <StyledBannerLunarGacha23>
      <Row className="rowContainer">
        <Col lg={12} xl={12} className="contentColumn">
          <div className="title">
            <img className="img-fluid" src={lunarTitle} alt="title" />
          </div>
          <div className="desc">
            <img className="img-fluid" src={lunarDesc} alt="lunarDesc" />
          </div>
          <div className="downloadBox flex-start">
            <div className="btn-download">
              <a
                href="https://apps.apple.com/us/app/calo-run/id1606108720"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnIos} alt="download" />
              </a>
            </div>
            <div className="btn-download">
              <a
                href="https://play.google.com/store/apps/details?id=com.calometaverse"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnAndroid} alt="download" />
              </a>
            </div>
          </div>
        </Col>
        <Col lg={12} xl={12} className="imageColumn">
          <img className="calo img-fluid" src={caloToken} alt="caloToken" />
          <img className="fit img-fluid" src={fitToken} alt="fitToken" />
          <div className="image-box">
            <img className="img-" src={lunarCat} alt="lunarCat" />
          </div>
        </Col>
      </Row>
    </StyledBannerLunarGacha23>
  );
};

export default BannerLunarGacha23;
