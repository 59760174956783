import styled from 'styled-components';
import bgBlurGamePlay from 'src/assets/images/bgBlurGamePlay.png';

export const StyledMetaverse = styled.section`
  padding: 48px 0 100px 0;
  position: relative;
  background-image: url(${bgBlurGamePlay});
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: 767px) {
    background-repeat: round;
    background-position: 0 -30px;
  }
  .backgroundLayer {
    position: absolute;
    width: 100%;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    .line {
      text-align: center;
      opacity: 0.5;
    }
    @media (min-width: 767px) {
      width: unset;
    }
  }
  .header {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.5px;
    color: ${({ theme }) => theme.header};
    padding-bottom: 8px;
  }
  .title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: ${({ theme }) => theme.metaverseTitle};
  }
  .contentWrapper {
    padding: 0 27px;
    position: relative;
    margin-top: 64px;
    .contentText {
      height: 350px;
    }
    .contentTitle {
      text-align: left;
      font-style: italic;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      padding-top: 60px;
      padding-bottom: 16px;
    }
    .contentDesc {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #bfbfbf;
      padding-top: 8px;
      text-align: left;
      margin: 0;
    }
    .link {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      color: #ff9e58;
      text-decoration: underline;
      margin-top: 16px;
    }
    .buttonGroup {
      position: absolute;
      bottom: -50px;
      /* left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center; */
      .btnItem {
        .btnIcon {
          width: 24px;
        }
      }
      .slideCounter {
        margin: 0 51.5px;
        color: #f89462;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.0125em;
      }
    }
    @media (max-width: 320px) {
      .buttonGroup {
        /* bottom: -75px; */
      }
    }
  }
  .contentImage {
    text-align: center;
    margin-top: 24px;
    position: relative;
    .object {
      position: absolute;
      top: -20px;
      left: 60px;
      img {
        width: 67px;
      }
    }
    .mainImage {
      width: 270px;
      height: 300px;
    }
  }

  @media (min-width: 767px) {
    .contentWrapper {
      max-width: 500px;
      margin: 0 auto;
      .contentTitle {
        padding-top: 20px;
      }
    }
    .contentImage img {
      max-width: 370px;
    }
  }
`;
