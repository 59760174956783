import styled from 'styled-components';

export const StyledTokenmetrics = styled.section`
  padding: 235px  0;
  position: relative;
  .container {
    max-width: 1216px;
    margin: auto;
    .header {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: ${({ theme }) => theme.header};
      padding-bottom: 8px;
    }
    .title {
      font-weight: 600;
      font-size: 60px;
      line-height: 68px;
      color: #e5e5e5;
      padding-bottom: 16px;
    }
    .description {
      max-width: 488px;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.4px;
      color: #e5e5e5;
    }
    .tokenSupply {
      position: relative;
      margin-top: 40px;
      .imageCenter {
        display: flex;
        justify-content: center;
        img {
          width: 600px;
        }
      }
    }
    .total {
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      color: #ffffff;
      margin-top: 28px;
      .number {
        color: #ff7510;
      }
    }
    .contact {
      margin-top: 32px;
      float: left;
      width: 445px;
      .label {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: ${({ theme }) => theme.title};
        margin-bottom: 10px;
      }
      .inputBox {
        padding: 12px 25px;
        text-align: left;
        color: ${({ theme }) => theme.title};
        border: ${({ theme }) => theme.inputBorder};
        background: ${({ theme }) => theme.inputBg};
        box-sizing: border-box;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    @media (max-width: 1024px) {
      .description {
        max-width: 420px;
      }
      .tokenSupply {
        margin-top: 100px;
        .imageCenter {
          img {
            width: 500px;
          }
        }
      }
    }
  }
`;
