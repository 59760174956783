import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { nonAnimatingContainer } from 'src/variables/animationVariant';
import { useAppStore } from 'src/hooks/useAppStore';
import tokenomics from 'src/assets/images/tokenomics.png';
import tokenomicMb from 'src/assets/images/tokenomicMb2.png';
import { StyledTokenomic } from './styled';
import ResponsiveLayout from '../ResponsiveLayout';

const Tokenomic = (props) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });
  const [appStore] = useAppStore();
  const controls = useAnimation();

  const animationVariant = {
    visible: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: {
        when: 'beforeChildren',
        duration: 0.5,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      scaleY: 1,
      y: '10%',
      transition: {
        duration: 0.3,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
  };

  function getAnimation() {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 200);
    } else {
      controls.start('hidden');
    }
  }

  useEffect(() => {
    getAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, inView]);

  const { isDarkMode } = appStore;

  return (
    <StyledTokenomic isDarkMode={isDarkMode} ref={inViewRef}>
      <AnimatePresence>
        <motion.div
          className="container"
          initial="hidden"
          animate={controls}
          variants={nonAnimatingContainer}
        >
          <div className="tokenomicContent">
            <motion.div className="tokenSupply" variants={animationVariant}>
              <ResponsiveLayout
                desktopView={
                  <img className="img-fluid" src={tokenomics} alt="tokenomic" />
                }
                mobileView={
                  <>
                    <div className='title-mb'>Tokenomics</div>
                    <img
                      className="img-fluid"
                      src={tokenomicMb}
                      alt="tokenomic"
                    />
                  </>
                }
              />
            </motion.div>
          </div>
        </motion.div>
      </AnimatePresence>
    </StyledTokenomic>
  );
};

export default Tokenomic;
