import styled from 'styled-components';

export const StyledShareSocialMobile = styled.div`
  padding: 64px 0;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0 16px;
    .img-wrapper {
      text-align: center;
      position: relative;
      img {
        position: relative;
        z-index: 1;
      }
      .blur {
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 60%;
        transform: translate(-50%, -50%);
        width: 372px;
        height: 372px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(49, 193, 255, 0.3) -60%,
          rgba(53, 85, 255, 0) 80%
        );
        @media (max-width: 1024px) {
          width: 272px;
          height: 272px;
        }
      }
    }
  }
`;
