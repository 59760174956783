import React from 'react';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import { StyledIntroduction } from './styled';
import HeaderMobile from 'src/components/Header/HeaderMobile';
import BannerLunarGacha23 from '../BannerScreen/BannerScreenMobile/BannerLunarGacha23';
// import { useAppStore } from 'src/hooks/useAppStore';

const IntroductionMobile = (props) => {
  // const [appStore] = useAppStore();
  // const { isDarkMode } = appStore;

  const slideSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    rows: 1,
    slidesPerRow: 1,
    appendDots: (dots) => (
      <div
        className="link"
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="link"
        style={{
          width: '46px',
          height: '4px',
          marginRight: '12px',
          backgroundColor: '#AAB2C8',
          borderRadius: '4px',
        }}
      ></div>
    ),
  };

  return (
    <>
      <HeaderMobile />
      <StyledIntroduction>
        <SliderSlick settings={slideSettings} paddingX="0px">
          <BannerLunarGacha23 />
        </SliderSlick>
      </StyledIntroduction>
    </>
  );
};

export default IntroductionMobile;
