import styled from 'styled-components';

export const StyledPartnersDesktop = styled.section`
  padding: 80px 0 144px 0;
  margin-top: -1px;
  position: relative;
  z-index: 3;
  .partners-container {
    /* margin: 80px auto !important; */
    .partnerWrapper {
      .partnerCard {
        padding: 15px;
        width: 180px;
        @media (max-width: 1024px) {
          width: 160px;
        }
        .cardWrapper {
          background: transparent;
          border-radius: 16px;
          position: relative;
          .cardImage {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              max-width: 140px;
            }

            @media (max-width: 1024px) {
              max-width: 120px;
            }
          }
        }
      }
    }
  }
  .slider-partner {
    .slick-dots {
      bottom: -60px;
    }
  }
  .container {
    max-width: 1223px;
    margin: auto;
    .header {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.01em;
      color: ${({ theme }) => theme.header};
      padding-bottom: 8px;
    }
    .title {
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      letter-spacing: 0.25px;
      text-align: center;
      color: ${({ theme }) => theme.title};
      padding-bottom: 8px;
    }
    .partnerWrapper {
      margin-top: 44px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    .partnerCard {
      padding: 15px;
      width: 280px;
      @media (max-width: 1024px) {
        width: 220px;
      }
      .cardWrapper {
        background: transparent;
        border-radius: 16px;
        position: relative;
        .cardImage {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 150px;
          }
        }
        .grey {
          img {
            filter: grayscale(1);
            transition: all 0.5s ease;
            &:hover {
              filter: grayscale(0);
            }
          }
        }
      }
    }
    :global(.slick-slide) {
      > div {
        display: flex;
        align-items: flex-start;
      }
    }
  }
`;
