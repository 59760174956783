import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useDarkMode } from 'src/hooks/useDarkMode';
import { darkTheme, lightTheme } from 'src/styles/theme-colors';
import AnimatedCursor from 'react-animated-cursor';
import TokenV2 from 'src/components/TokenV2/TokenV2';
import Tokenomic from 'src/components/Tokennomic/Tokennomic';
import TokenMetrics from 'src/components/TokenMetrics/TokenMetrics';
import Social from 'src/components/Social/Social';
import Footer from 'src/components/Footer/Footer';
import iconTopDark from 'src/assets/images/iconTopDark.png';
import iconTopLight from 'src/assets/images/iconTopLight.png';
import Header from 'src/components/Header/Header';
import TokenDemand from 'src/components/TokenDemand';
import WhyUs from 'src/components/WhyUs';
import IntroductionV2 from 'src/components/IntroductionV2';
import Profit from 'src/components/Profit';
import RoadmapV2 from 'src/components/RoadmapV2/RoadmapV2';
import { Affix, BackTop } from 'antd';
import { scrollTo } from 'src/utils';
import { StyledAnimatedCursor, StyledLayout } from '../home.styled';
import { useAppStore } from 'src/hooks/useAppStore';

const LitePaper = (props) => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'dark' ? darkTheme : lightTheme;
  const [appStore, updateAppStore] = useAppStore();

  const navigateScroll = (toId, toRef, duration) =>
    scrollTo({ id: toId, ref: toRef, duration });

  const handleScrollTop = () => {
    const element = document.getElementById('home');
    if (element) {
      element.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    updateAppStore((draft) => {
      draft.isDarkMode = true;
    });
    handleScrollTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      {/* <StyledAnimatedCursor>
        <AnimatedCursor
          innerSize={12}
          outerSize={30}
          color={theme === 'dark' ? '255, 255, 255' : '90, 91, 116'}
          outerAlpha={0.2}
          innerScale={1}
          outerScale={2}
          trailingSpeed={2}
        />
      </StyledAnimatedCursor> */}
      <StyledLayout>
        <div className='intro-section-lite'>
          <Header theme={theme} toggleTheme={toggleTheme} id="home" />
          <IntroductionV2 />
        </div>
        <Profit />
        <div>
          <WhyUs />
        </div>
        <div id="token">
          <TokenV2 />
        </div>
        <div id="tokenomics">
          <Tokenomic />
        </div>
        <div id="tokenmetrics">
          <TokenMetrics />
        </div>
        <div>
          <TokenDemand />
        </div>
        <div>
          <RoadmapV2 />
        </div>
        <div>
          <Social />
        </div>
        <Footer />
        <Affix offsetBottom={80}>
          <BackTop>
            <img
              src={theme === 'dark' ? iconTopDark : iconTopLight}
              width="56px"
              height="56px"
            />
          </BackTop>
        </Affix>
      </StyledLayout>
    </ThemeProvider>
  );
};

export default LitePaper;
