import styled from 'styled-components';

export const StyledFMSMobile = styled.div`
  padding: 48px 0;
  .container {
    margin: auto;
    padding: 0;
    .py-10 {
      padding: 0 10px;
    }
    .main-title {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      text-align: center;
      color: #ff8a34;
    }
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      padding-bottom: 16px;
      max-width: 340px;
      margin: 0 auto;
    }
    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #e5e5e5;
    }
    .content-wrapper {
      margin-top: 20px;
      flex-direction: column;
      .phone-box {
        width: 300px;
      }
      .content-box {
        padding-left: 20px;
        .info {
          .title {
            text-align: left;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #ffffff;
            padding-bottom: 24px;
            span {
              padding-top: 28px;
              padding-right: 8px;
            }
          }
          .info-item {
            margin-bottom: 28px;
            .box-img {
              max-width: 106px;
              background: #03111e;
              box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.25);
              border-radius: 12px;
              img {
                width: 100%;
              }
            }
            .desc {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #e5e5e5;
              padding-left: 20px;
              max-width: 180px;
              text-align: left;
            }
          }
        }
      }
    }
  }
`;
