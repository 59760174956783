import styled from 'styled-components';
import roadmapV2BgMb from 'src/assets/images/roadmap/mobile/roadmapV2BgMb.png';
import boxPolygonMb from 'src/assets/images/boxPolygonMb.png';

export const StyledRoadmapV2Mobile = styled.div`
  padding: 64px 0;
  position: relative;
  background-image: url(${roadmapV2BgMb});
  background-size: cover;
  background-repeat: round;
  .container {
    padding: 0;
    max-width: 1216px;
    margin: 0 auto;
    .title {
      font-weight: 600;
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.25px;
      color: ${({ theme }) => theme.title};
      padding-bottom: 16px;
    }
    .wrapper {
      margin-top: 32px;
      padding: 0 16px;
      .ant-col {
        margin-bottom: 24px;
      }
      .roadmap-item {
        background-image: url(${boxPolygonMb});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 240px;
        @media (max-width: 568px) {
          height: 200px;
        }
        @media (max-width: 375px) {
          height: 170px;
        }
        .content {
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          ul {
            padding: 0;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            list-style: none;
            flex-direction: column;
            li {
              padding-bottom: 4px;
            }
            @media (max-width: 375px) {
              font-size: 12px;
            }
            @media (max-width: 320px) {
              font-size: 10px;
            }
          }
          .milestone {
            margin-top: 16px;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            text-align: center;
            @media (max-width: 375px) {
              margin-top: 10px;
              font-size: 16px;
            }
            @media (max-width: 320px) {
              margin-top: 8px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;
