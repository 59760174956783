import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import { StyledWhyUs } from './styled';

const WhyUs = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const whyUsContent = [
    {
      title: 'Whoever',
      content:
        '<span>With the perfect combination of gaming, move-to-earn, and healthy guidelines, Calo Metaverse promises to help millions of users improve their exercise habits.</span>',
    },
    {
      title: 'Whenever',
      content:
        '<span>Wherever you are, as long as you equip yourself with NFT SNEAKER, you can collect Calo Token and FIT Token. It will be your asset, and you can make any transaction with it.</span>',
    },
    {
      title: 'Wherever',
      content:
        '<span>To use NFT SNEAKER to earn FIT and Calo while moving, players need STAMINA. The Stamina will be replenished throughout the day. The percentage of energy added is 25% of your current Stamina. So don&#39;t worry about the lack of Stamina.</span>',
    },
    {
      title: 'Forever',
      content: `<span>Calo Token, FIT Token, and SNEAKER are your assets. It is valid forever, and you have full rights to use it. Calo&#39;s mission is to help you keep information about this property secure and to support you 200% throughout your journey with Calo.</span>`,
    },
  ];

  function createMarkup(text) {
    return { __html: text };
  }

  function rawHTML(text) {
    // eslint-disable-next-line react/react-in-jsx-scope
    return <div dangerouslySetInnerHTML={createMarkup(text)} />;
  }

  return (
    <StyledWhyUs isDarkMode={isDarkMode}>
      <div className="container">
        <div className="title">Why Us ?</div>
        <div className="boxContainer">
          <div className="boxItems flex-between">
            {whyUsContent.map((w) => (
              <div className="boxInfo">
                <div className="boxTitle">{w.title}</div>
                <div className="boxContent">{rawHTML(w.content)}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="mission">
          Calo Metaverse Mission: Motivating the community to exercise more and
          earn more passive income
        </div>
      </div>
    </StyledWhyUs>
  );
};

export default WhyUs;
