import styled from 'styled-components';

export const StyledCustomerJourneyMobile = styled.section`
  padding: 48px 0 40px 0;
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  .header {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-align: center;
    color: ${({ theme }) => theme.header};
    padding-bottom: 8px;
  }
  .title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: ${({ theme }) => theme.title};
    padding-bottom: 16px;
  }
  .description {
    max-width: 313px;
    margin: auto;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.description};
    padding-bottom: 24px;
  }

  .itemWrapper {
    padding: 0 16px;
    margin-top: 24px;
    .box {
      width: 100%;
      margin: 0 auto 16px 0;
      height: 225px;
      background: rgba(255, 158, 88, 0.1);
      box-shadow: inset 0px 5px 34px rgba(255, 255, 255, 0.25);
      border-radius: 18.8475px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 18.8475px;
        padding: 2px;
        background: linear-gradient(
          100.01deg,
          #ff8a34 5.41%,
          rgba(255, 138, 52, 0) 32.38%,
          rgba(255, 138, 52, 0) 65.19%,
          #ff8a34 89.79%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
      }
      &::after {
        content: '';
        position: absolute;
        width: 77px;
        height: 77px;
        border-radius: 50%;
        background: #ff9e58;
        opacity: 0.8;
        filter: blur(50px);
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
      .img {
        position: absolute;
        bottom: 1px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 2;
      }
      .top {
        bottom: unset;
        top: 10px;
      }
      .center {
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: unset;
      }
      .footer {
        position: absolute;
        z-index: 3;
        bottom: 0px;
        width: 100%;
        padding: 18px 0;
        text-align: center;
        background: linear-gradient(
          180deg,
          #ff8a34 0%,
          rgba(255, 138, 52, 0) 184.82%
        );
        backdrop-filter: blur(9px);
        border-bottom-left-radius: 18.8475px;
        border-bottom-right-radius: 18.8475px;
        .text {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
          @media (min-width: 320px) {
            font-size: 12px;
          }
        }
      }
    }
  }
`;
