import iconFacebookWhite from 'src/assets/images/social/desktop/iconFacebookWhite.png';
import iconInstaWhite from 'src/assets/images/social/desktop/iconInstaWhite.png';
import iconTwitterWhite from 'src/assets/images/social/desktop/iconTwitterWhite.png';
import iconYoutubeWhite from 'src/assets/images/social/desktop/iconYoutubeWhite.png';
import iconDiscordWhite from 'src/assets/images/social/desktop/iconDiscordWhite.png';
import iconTelegramWhite from 'src/assets/images/social/desktop/iconTelegramWhite.png';
import iconTiktokWhite from 'src/assets/images/social/desktop/iconTiktokWhite.png';
import iconFacebookColor from 'src/assets/images/social/desktop/iconFacebookColor.png';
import iconInstaColor from 'src/assets/images/social/desktop/iconInstaColor.png';
import iconTwitterColor from 'src/assets/images/social/desktop/iconTwitterColor.png';
import iconYoutubeColor from 'src/assets/images/social/desktop/iconYoutubeColor.png';
import iconDiscordColor from 'src/assets/images/social/desktop/iconDiscordColor.png';
import iconTelegramColor from 'src/assets/images/social/desktop/iconTelegramColor.png';
import iconTiktokColor from 'src/assets/images/social/desktop/iconTiktokColor.png';

export const socials = [
  {
    key: 'facebook',
    url: 'https://www.facebook.com/calometaverse',
    iconWhite: iconFacebookWhite,
    iconColor: iconFacebookColor,
  },
  {
    key: 'telegram',
    url: 'https://t.me/caloapp',
    iconWhite: iconTelegramWhite,
    iconColor: iconTelegramColor,
  },
  {
    key: 'discord',
    url: 'https://dsc.gg/calocommunity',
    iconWhite: iconDiscordWhite,
    iconColor: iconDiscordColor,
  },
  {
    key: 'twitter',
    url: 'https://twitter.com/AppCalo',
    iconWhite: iconTwitterWhite,
    iconColor: iconTwitterColor,
  },
  {
    key: 'youtube',
    url: 'https://www.youtube.com/channel/UCoSkHLD97-rSElS_6fqF1NQ',
    iconWhite: iconYoutubeWhite,
    iconColor: iconYoutubeColor,
  },
  {
    key: 'instagram',
    url: 'https://www.instagram.com/caloappvn/',
    iconWhite: iconInstaWhite,
    iconColor: iconInstaColor,
  },
  {
    key: 'tiktok',
    url: 'http://www.tiktok.com/@caloapp',
    iconWhite: iconTiktokWhite,
    iconColor: iconTiktokColor,
  },
];
