import styled from 'styled-components';

export const StyledJourney = styled.section`
  padding: 140px 0;
  position: relative;
  z-index: 3;
  .gradientBg {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    img {
      width: 100%;
    }
  }
  .container {
    max-width: 1216px;
    margin: 0 auto;
    padding: 0;
    .title {
      font-weight: 600;
      font-size: 60px;
      line-height: 68px;
      text-align: center;
      color: #ffffff;
      padding-bottom: 16px;
    }
    .description {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.4px;
      color: #bfbfbf;
      padding-bottom: 60px;
    }
    .mb-48 {
      margin-bottom: 48px;
    }

    @media (max-width: 1024px) {
      jutify-content: space-around;
    }

    .box {
      width: 100%;
      max-width: 357px;
      height: 334px;
      background: rgba(255, 158, 88, 0.1);
      box-shadow: inset 0px 5px 34px rgba(255, 255, 255, 0.25);
      border-radius: 18.8475px;
      position: relative;
      @media (max-width: 1024px) {
        max-width: 287px;
      }
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 18.8475px;
        padding: 2px;
        background: linear-gradient(
          100.01deg,
          #ff8a34 5.41%,
          rgba(255, 138, 52, 0) 32.38%,
          rgba(255, 138, 52, 0) 65.19%,
          #ff8a34 89.79%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
      }
      &::after {
        content: '';
        position: absolute;
        width: 136px;
        height: 136px;
        border-radius: 50%;
        background: #ff9e58;
        opacity: 0.8;
        filter: blur(50px);
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
      .img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 2;
      }
      .top {
        bottom: unset;
        top: 10px;
      }
      .center {
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: unset;
      }
      .footer {
        position: absolute;
        z-index: 3;
        bottom: 0px;
        width: 100%;
        padding: 24px 0;
        text-align: center;
        background: linear-gradient(
          180deg,
          #ff8a34 0%,
          rgba(255, 138, 52, 0) 184.82%
        );
        backdrop-filter: blur(9px);
        border-bottom-left-radius: 18.8475px;
        border-bottom-right-radius: 18.8475px;
        .text {
          font-weight: 700;
          font-size: 26px;
          line-height: 32px;
          color: #ffffff;
          @media (max-width: 1024px) {
            font-size: 20px;
          }
        }
      }
    }
  }
`;
