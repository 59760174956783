import styled from 'styled-components';

export const StyledTopics = styled.section`
  .container {
    padding-top: 80px;
    position: relative;
    .circleCorner {
      position: absolute;
      right: 0;
      img {
        width: 100%;
      }
    }
    .title {
      max-width: 408px;
      margin: 0 auto;
      font-weight: 600;
      font-size: 60px;
      line-height: 68px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 48px;
    }
    .counterContainer {
      max-width: 1209px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      @media (max-width: 1024px) {
        justify-content: space-around;
      }
      .counterItem {
        padding: 24px 0;
        min-width: 270px;
        background: rgba(255, 158, 88, 0.1);
        box-shadow: inset 0px 5px 34px rgba(255, 255, 255, 0.25);
        border-radius: 18.8475px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          border-radius: 18.8475px;
          padding: 2px;
          background: linear-gradient(
            100.01deg,
            #ff8a34 5.41%,
            rgba(255, 138, 52, 0) 32.38%,
            rgba(255, 138, 52, 0) 65.19%,
            #ff8a34 89.79%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
        }
        .number {
          font-weight: 700;
          font-size: 48px;
          line-height: 68px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 8px;
          min-width: 180px;
        }
        .label {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          letter-spacing: -1.5px;
          color: #ff9e58;
          text-transform: uppercase;
        }
        @media (max-width: 1024px) {
          min-width: 200px;
          .number {
            font-size: 40px;
            line-height: 46px;
          }
          .label {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    .activitiesContainer {
      max-width: 1216px;
      margin: auto;
      padding: 160px 0;
      .header {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: ${({ theme }) => theme.topicsHeader};
        padding-bottom: 10px;
      }

      .activityItem {
        position: relative;
        top: 0;
        transition: top ease 0.5s;
        img {
          display: block;
          width: 100%;
          border-radius: 24px;
        }
        .ant-image-mask {
          display: none;
        }
        &:hover {
          position: relative;
          top: -24px;
          transition: top ease 0.5s;
          opacity: 1 !important;
        }
      }
      .slick-list {
        padding-top: 24px;
        &:hover {
          .activityItem {
            opacity: 0.5;
          }
        }
      }
      .slick-dots {
        bottom: -120px;
      }
    }
  }
  .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.65) !important;
  }
  .ant-image-preview-img {
    /* cursor: none !important; */
    pointer-events: none !important;
  }
`;
