import styled from 'styled-components';
import bgBlurAppScreen from 'src/assets/images/bgBlurAppScreen.png';

export const StyledAppScreenMobile = styled.div`
  padding-bottom: 100px;
  overflow: hidden;
  background-image: url(${bgBlurAppScreen});
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: 767px) {
    background-repeat: round;
    background-position: 0 -30px;
  }
  .container {
    padding: 0;
    .img-wrapper {
      text-align: center;
      position: relative;
      margin-top: 64px;
      .img-app {
        position: relative;
        z-index: 2;
        max-width: 316px;
        @media (min-width: 767px) {
          max-width: 428px;
        }
      }
    }
    .box-content {
      text-align: center;
      margin-top: 64px;
      .title {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        color: #ffffff;
        .bold {
          color: #ff8a34;
        }
      }
      .downloadBox {
        padding-top: 32px;
        flex-direction: column;
        .btn-download {
          &:first-child {
            margin-bottom: 24px;
          }
        }
      }
      .btn-primary {
        margin-top: 32px;
        padding: 8px 18.5px;
        background: linear-gradient(180deg, #ffaa4e 0%, #f45c0e 100%);
        box-shadow: 2px 2px 50px -5px rgba(249, 131, 75, 0.8);
        border-radius: 24px;
        max-width: fit-content;
        cursor: pointer;
        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 1.25px;
          color: #ffffff;
        }
      }
    }
  }
`;
