import React, { useEffect } from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import phoneMarketMb from 'src/assets/images/howtoplay/phoneMarketMb2.png';
import sneakerCard from 'src/assets/images/howtoplay/sneakerCard.png';
import shoeboxCard from 'src/assets/images/howtoplay/shoeboxCard.png';
import lineBottomMb from 'src/assets/images/howtoplay/lineBottomMb.png';
import { StyledFMSMobile } from './styled';

const FindSneakerMarketMobile = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledFMSMobile isDarkMode={isDarkMode}>
      <div className="container">
        <div className="main-title">FIND A SNEAKER</div>
        <div className="title py-10">From the in-app marketplace</div>
        <div className="content-wrapper flex-center">
          <div className="phone-box">
            <img className="phoneImg img-fluid" src={phoneMarketMb} alt="img" />
          </div>
          <div className="content-box">
            <div className="info">
              <div className="title flex-start">
                <span>Search Sneaker</span>
                <div className="text-center">
                  <img className="line" src={lineBottomMb} alt="line" />
                </div>
              </div>
              <div className="flex-start info-item">
                <div className="box-img">
                  <img src={sneakerCard} alt="box-img" />
                </div>
                <div className="desc">
                  Own more sneaker to gain higher engery level
                </div>
              </div>
              <div className="flex-start info-item">
                <div className="box-img">
                  <img src={shoeboxCard} alt="box-img" />
                </div>
                <div className="desc">
                  Shoebox contains one sneaker with random type and quality
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFMSMobile>
  );
};

export default FindSneakerMarketMobile;
