import styled from 'styled-components';

export const StyledMeasureMobile = styled.div`
  padding: 64px 0;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      padding-bottom: 28px;
      max-width: 300px;
      margin: 0 auto;
    }
    .text-desc {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.0125em;
      color: #ffffff;
    }
    .info-wrapper {
      flex-direction: column;
      display: flex;
      align-items: center;
      .item {
        margin-bottom: 16px;
      }
      @media (min-width: 767px) {
        flex-direction: row;
        justify-content: space-evenly;
      }
    }
    .dot-running {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #58fe90;
      margin: 0 6px;
    }
    .icon {
      margin-right: 8px;
    }
    .phone {
      margin: 60px 0;
      padding: 0 16px;
      position: relative;
      img {
        position: relative;
        z-index: 1;
      }
      .blur {
        z-index: 0;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 372px;
        height: 372px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(49, 193, 255, 0.3) -60%,
          rgba(53, 85, 255, 0) 80%
        );
      }
    }
  }
`;
