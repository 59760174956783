import { Col, Row } from 'antd';
import React from 'react';
import loryOnepaper from 'src/assets/images/carlLoryR2E_2.png';
import carlLoryR2E from 'src/assets/images/carlLoryR2E.png';
import sneakerIcon from 'src/assets/images/sneakerIcon.png';
import { useAppStore } from 'src/hooks/useAppStore';
import { StyledProfit } from './styled';

const Profit = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledProfit isDarkMode={isDarkMode}>
      <div className="container bottom-container">
        <Row gutter={24} align="middle">
          <Col sm={24} lg={12} xl={10}>
            <div className="img-wrapper">
              <img
                className="img-fluid main-img"
                src={carlLoryR2E}
                alt="carlLoryR2E"
              />
              <div className="blur" />
            </div>
          </Col>
          <Col sm={24} lg={12} xl={14}>
            <div className="content-wrapper">
              <div className="leading-title">Run & Earn Together</div>
              <div className="profit-content flex-start">
                <div className="content-column">
                  <div className="content-title">
                    How We <span className="text-og">Profit?</span>
                  </div>
                  <p>
                    From activities in the app and community, such as NFT
                    trading, SNEAKER exchange,... and other offline events, Calo
                    collects a small portion of the tax. In other words, players
                    create private value for themselves and bring income mainly
                    for Calo at the same time.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </StyledProfit>
  );
};

export default Profit;
