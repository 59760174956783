import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';

import RoadmapV2Desktop from './RoadmapV2Desktop';
import RoadmapV2Mobile from './RoadmapV2Mobile';

const RoadmapV2 = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<RoadmapV2Desktop {...props} />}
      mobileView={<RoadmapV2Mobile {...props} />}
    />
  );
};

export default RoadmapV2;
