import styled from 'styled-components';

export const StyledBannerBetaPhase2 = styled.div`
  max-width: 1216px;
  margin: 0 auto;
  width: 100%;
  height: 500px;

  .rowContainer {
    height: 100%;
    .imageColumn {
      .image-box {
        margin-top: -100px;
        @media (max-width: 1024px) {
            margin-top: -50px;
        }
      }
    }

    .contentColumn {
      padding-top: 24px;
      .header {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        color: #bfbfbf;
        margin-bottom: 24px;
      }
      .main-title {
        font-weight: 700;
        font-size: 84px;
        color: #ff8a34;
        margin-bottom: 24px;
      }
      .title {
        margin: 0 auto;
        font-weight: 700;
        font-size: 34px;
        line-height: 40px;
        color: #ffffff;
      }
      .downloadBox {
        margin-top: 80px;
        .btn-download {
          &:first-child {
            margin-right: 24px;
          }
        }
      }
      @media (max-width: 1024px) {
        .header {
            font-size: 20px;
        }
        .main-title {
            font-size: 60px;
        }
        .title {
            font-size: 24px;
        }
      }
    }
  }
`;
