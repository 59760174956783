import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useDarkMode } from 'src/hooks/useDarkMode';
import { darkTheme, lightTheme } from 'src/styles/theme-colors';
import AnimatedCursor from 'react-animated-cursor';

import Footer from 'src/components/Footer/Footer';
import iconTopDark from 'src/assets/images/iconTopDark.png';
import iconTopLight from 'src/assets/images/iconTopLight.png';
import { StyledAnimatedCursor, StyledLayout, StyledContainer } from './styled';
import Header from 'src/components/Header/Header';
import { Affix, BackTop } from 'antd';
import { scrollTo } from 'src/utils';

const TermPage = (props) => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'dark' ? darkTheme : lightTheme;

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    handleScrollTop();
  }, []);

  const params = new URLSearchParams(window.location.search);
  const isEmbed = parseInt(params.get('isEmbed')) === 1;

  return (
    <ThemeProvider theme={themeMode}>
      {/* <StyledAnimatedCursor>
        <AnimatedCursor
          innerSize={12}
          outerSize={30}
          color={theme === 'dark' ? '255, 255, 255' : '90, 91, 116'}
          outerAlpha={0.2}
          innerScale={1}
          outerScale={2}
          trailingSpeed={2}
        />
      </StyledAnimatedCursor> */}
      <StyledLayout>
        {!isEmbed && (
          <Header theme={theme} toggleTheme={toggleTheme} id="home" />
        )}
        <StyledContainer
          className="container"
          style={{ paddingTop: isEmbed ? '20px' : '0' }}
        >
          <h1>CALO TERMS AND CONDITIONS (T&C)</h1>
          <div>
            <h2>I. OVERVIEW</h2>
            <ol>
              <li>
                <p>
                  <span>The CALO app (hereinafter referred to as the “</span>
                  <b>App</b>
                  <span>”) is made available by </span>
                  <b>METAVERSE APPLICATION PTE. LTD., </b>
                  <span>
                    a company incorporated in Singapore (hereinafter referred to
                    as the
                  </span>
                  <b> “Company</b>
                  <span>
                    ”). If using the App, the user (hereinafter referred to as
                    the
                  </span>
                  <b> “Client</b>
                  <span>
                    ”) is required to first carefully read as well as fully
                    understand these App related Terms and Conditions
                    (hereinafter referred to as the “
                  </span>
                  <b>CALO Terms and Conditions</b>
                  <span>
                    ”) which are applicable when the Client views/accesses/uses
                    (hereinafter collectively, referred to as “
                  </span>
                  <b>use</b>
                  <span>” or “</span>
                  <b>using</b>
                  <span>
                    ”) the App. The Client shall also carefully read and fully
                    understand the Company’s Privacy Policy available at{' '}
                  </span>
                  <span>
                    <a
                      href="https://calo.run/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://calo.run/privacy-policy
                    </a>
                  </span>
                  <span> (hereinafter referred to as the “</span>
                  <b>Privacy Policy</b>
                  <span>
                    ”), which is incorporated in these CALO Terms and Conditions
                    by reference.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  By using the App from any computer, computer device, mobile,
                  smartphone or any electronic device, the Client has indicated
                  to the Company that the Client has read, acknowledged and
                  understood as well as fully agreed, to be bound by these CALO
                  Terms and Conditions, Privacy Policy and/or other App
                  guidelines and policies (guidelines and policies hereinafter
                  collectively referred to as “Other Policies”), irrespective
                  whether the Client registers with or uses the App or creates
                  an account with the Company or not.
                </p>
              </li>
              <li>
                <p>
                  If, for any reason, the Client does not agree to these CALO
                  Terms and Conditions, Privacy Policy and all Other Policies as
                  communicated to the Client when the Client uses the App or
                  does not wish to be bound by them, please do not use the App
                  in any way whatsoever.
                </p>
              </li>
            </ol>
            <h2>II. ABOUT THE APP</h2>
            <ol>
              <li>
                <p>
                  The App is proprietary to the Company and constitutes its
                  intellectual property. Any intellectual property relating to
                  any products and/or services offered on or through the App may
                  be proprietary to the Company or its partners and shall
                  constitute the intellectual property of the Company or its
                  partners as the case may be.
                </p>
              </li>
              <li>
                <p>
                  The App does not have an in-built tracking system to keep a
                  track of the Client’s steps when the Client does the athletic
                  activities. This service shall be provided by a third party.
                  The Company shall not be liable to the Client or any third
                  party for any and all issues arising out of this service.
                </p>
              </li>
              <li>
                <p>
                  Based on the minimum number of points (as mentioned in the
                  App) collected in the Client’s account, the Client can redeem
                  the same either against products and/or services offered on or
                  through the App, whether they are offered by the Company or by
                  its partners. The Client can also use such points to buy the
                  ticket to join in the events which are held on or through the
                  App. Further, the said points can also be used to avail
                  various discount offers that may be made available on or
                  through the App, whether by the Company or by its partners. If
                  there are any specific or additional terms and conditions
                  attached to any such offered products and/or services, then
                  the same will also be applicable and shall form an integral
                  part of these CALO Terms and Conditions.
                </p>
              </li>
              <li>
                <p>
                  The App is provided currently free of cost. The Company may
                  convert the App to a subscription-based model at a future
                  date. However, the Company shall communicate the same to the
                  Client as and when the Company decides to do so.
                </p>
              </li>
            </ol>
            <h2>III. ACCEPTANCE OF TERMS, ETC.</h2>

            <ol>
              <li>
                These CALO Terms and Conditions is in the form of an electronic
                and legally binding contract that establishes the terms and
                conditions the Client has accepted before using the App or any
                part thereof. These include the Privacy Policy and Other
                Policies as mentioned in these CALO Terms and Conditions as well
                as other specific policies and terms and conditions disclosed to
                the Client, in case the Client uses any subscription or any
                additional features, products or services the Company offers on
                or through the App, whether free or otherwise, including but not
                limited to terms governing features, billing, free trials,
                promotions and discounts. By using the App, the Client hereby
                unconditionally consents and accepts these CALO Terms and
                Conditions, Privacy Policy and Other Policies. To withdraw such
                consent, the Client must immediately cease using the App and
                terminate the Client’s account with the Company. The Client is
                requested to keep a physical copy of these CALO Terms and
                Conditions and all other referred policies herein for the
                Client’s records.
              </li>
              <li>
                The Client consents to have these CALO Terms and Conditions and
                all notices provided to the Client in electronic form.
              </li>
              <li>
                Every time the Client uses the App, the Client confirms the
                Client’s agreement with these CALO Terms and Conditions, Privacy
                Policy and Other Policies.
              </li>
              <li>
                If the Client has entered into any other agreement with the
                Company, such as a race entry agreement, a liability waiver, or
                a sponsorship agreement, the terms and conditions of those
                agreements will supersede and prevail over these CALO Terms and
                Conditions in the event of any inconsistency.
              </li>
            </ol>
            <h2>IV. ELIGIBILITY</h2>
            <ol>
              <li>
                The minimum age to use the App is 14 years. By using the App and
                in order to be competent to contract under applicable law, the
                Client represents and warrants that the Client is at least 14
                years of age or not a minor in any other jurisdiction from where
                the Client uses the App.
              </li>
              <li>
                By using the App, the Client hereby represents and warrants to
                the Company that the Client has all right, authority and
                capacity to enter into these CALO Terms and Conditions and to
                abide by all of the terms and conditions thereof.
              </li>
              <li>
                By using the App, the Client hereby represents and warrants that
                all registration and other information The Client submits is
                truthful and accurate (if any) and the Client will maintain the
                accuracy of any information the Client provides.
              </li>
            </ol>
            <h2>V. APP ACCOUNT</h2>
            <ol>
              <li>
                In order to use the App, the Client has the option of signing in
                using the Client’s Google login. If the Client does so, the
                Client authorizes the Company to access and use certain Google
                account information of the Client, including but not limited to
                the Client’s public Google profile and information about the
                Client’s Google friends, the Client’s interests and dislikes.
                For more information regarding the type/nature of information
                the Company collects from the Client and how the Company uses
                it, please refer to the Privacy Policy at{' '}
                <a
                  href="https://calo.run/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://calo.run/privacy-policy
                </a>
              </li>
              <li>
                The Client’s account details the Client provides to the Company
                must always be kept private and confidential and should not be
                disclosed to or permitted to be used by anyone else and the
                Client is solely responsible and liable for any and all usage
                and activity on the App that takes place under the Client’s
                account.
              </li>
              <li>
                By agreeing to these CALO Terms and Conditions, the Client
                grants the Company the permission to send electronic
                communications to the Client as part of the Company’s offering.
                This includes but is not limited to sending emails, newsletters,
                notifications and promotional offers from the Company and its
                partners. Should the Client no longer wish to receive such
                electronic communications, the Client may write to the Company
                at{' '}
                <a href="https://t.me/caloapp" target="_blank" rel="noreferrer">
                  https://t.me/caloapp
                </a>
              </li>
              <li>
                Any account the Client opens with the Company is personal to the
                Client and the Client is prohibited from gifting, lending,
                transferring or otherwise permitting any other person or entity
                to use the Client’s account in any way whatsoever.
              </li>
              <li>
                Each Client is only permitted to have one account on this App
                platform. If the use of multiple accounts/registrations by a
                single user is found, the Company reserves the right to
                investigate, suspend and/or terminate, whether temporarily or
                permanently, the Client’s App account with the Company.
              </li>
            </ol>
            <h2>VI. TERM AND TERMINATION</h2>
            <ol>
              <li>
                These CALO Terms and Conditions will remain in full force and
                effect while the Client uses the App or any part thereof.
              </li>
              <li>
                The Client may terminate or disable the Client’s App account at
                any time and for any reason by deleting the App from the
                Client’s device.
              </li>
              <li>
                The Company may, at the Company’s sole discretion, terminate or
                suspend, whether temporarily or permanently, the Client’s App
                account at any time with or without notice and for any reason,
                including for the Client’s breach of these CALO Terms and
                Conditions. After the Client’s App account is terminated or
                suspended, all the terms hereof shall survive such termination
                or suspension, and continue in full force and effect, except for
                any terms that by their nature expire or are fully satisfied.
              </li>
              <li>
                The Client acknowledges that the Company reserves the right to
                terminate or delete the Client’s account in case it remains
                'inactive' for a duration as determined by the Company in the
                Company’s sole discretion. If the Company terminates or suspends
                the Client’s account because the Client has breached this CALO
                Terms and Conditions, the Client will not be entitled to any
                refund of unused subscription fees and for any fees towards any
                in-App purchases.
              </li>
              <li>
                Following termination of these CALO Terms and Conditions, the
                Company will only retain and use the Client’s content in
                accordance with these CALO Terms and Conditions.
              </li>
            </ol>
            <h2>VII. APP USAGE</h2>
            <ol>
              <li>
                The App is strictly available for the Client’s personal and
                non-commercial use only.
              </li>
              <li>
                The Client is hereby strictly prohibited from and against:
                <p>
                  a. Undertaking any marketing, promotion, advertising or
                  soliciting any other Client to buy or sell any products or
                  services whether through the App or not;
                </p>
                <p>
                  b. Transmitting any chain letters, junk, bulk or spam e-mail
                  or other unsolicited communications of any kind whatsoever to
                  other Clients or publishing the same on the App or anywhere
                  else; and
                </p>
                <p>
                  c. Contacting any other Client or using the details of any
                  other Client for any purpose not expressly permitted under
                  these CALO Terms and Conditions.
                </p>
              </li>
              <li>
                It is hereby expressly clarified that any of the aforesaid acts
                undertaken by the Client shall be to the Client’s sole
                liability, responsibility, risk and consequences and the Client
                hereby agree to indemnify the Company for the same.
              </li>
              <li>
                The Client agrees to take all necessary precautions in all
                interactions with other Clients when the Client communicates
                with them through the App if permitted.
              </li>
              <li>
                The App may provide links or references to other apps, websites
                or platforms (“<b>site</b>”). The Company has no control over or
                responsibility for the content on third party sites or
                transactions that occur there. Links or references are provided
                for convenience only and the Company will not be liable for any
                damages or injury arising from those sites. By using a
                third-party site, the Client may be agreeing to their terms and
                conditions, which may differ significantly from these CALO Terms
                and Conditions. Make certain the Client understands the risks
                and any applicable policies of third-party sites before
                retrieving, using, relying upon, or purchasing anything, or
                before otherwise transacting, via the Internet.
              </li>
            </ol>
            <h2>VIII. ACCOUNT SECURITY</h2>
            <p>
              The Client shall be solely responsible and liable for maintaining
              the utmost privacy and confidentiality of the Client’s App log-in
              (including but not limited to username and password) details as
              well as for any and all activities that occur under the Client’s
              log-in. The Client agrees to immediately notify the Company of any
              disclosure or unauthorized use of the Client’s log-in or any other
              breach of security by emailing us on{' '}
              <a href="mailto:tech@calo.run">tech@calo.run</a>.
            </p>
            <h2>IX. PROPRIETARY RIGHTS</h2>
            <ol>
              <li>
                The Client confirms and agrees that the Company or its partners
                are the owner of the proprietary information made available to
                the Client through the App and hereby retains all proprietary
                and intellectual property rights in the same, including but not
                limited to all confidential information.
              </li>
              <li>
                The Client undertakes not to post, copy, modify, transmit,
                disclose, show in public, create any derivative works from,
                distribute, make commercial use of, or reproduce in any way, and
                whether partly or fully, any (i) confidential or proprietary
                information, or (ii) copyrighted or copyrightable material,
                trademarks, service marks or other proprietary information
                accessible via the App; whether belonging to the Company or not
                without first obtaining the Company’s prior written consent.
              </li>
              <li>
                Other Clients may upload/publish/post any copyrighted
                information, which may have copyright protection or not or which
                may be identified as copyright. The Client undertakes not to
                copy, modify, publish, transmit, distribute, perform, display,
                commercially use/exploit, sell or use such information in any
                way and for any purpose whatsoever.
              </li>
              <li>
                By posting information or content to any profile pages or public
                area of the App, or making it accessible to the Company by
                linking the Client’s account to any social network accounts
                (e.g. via Facebook, Twitter, Instagram, etc.), the Client grants
                the Company unconditionally and in perpetuity, and represents
                and warrants that the Client has the right to grant to the
                Company, an irrevocable, perpetual, non-exclusive,
                fully-paid/royalty-free, worldwide license to use, reproduce,
                publicly perform, publicly display and distribute such
                information and content, and to prepare derivative works of, or
                incorporate into other works, such information and content, and
                to grant and authorize sub-licenses of the foregoing anywhere in
                the world. From time to time, the Company may modify, add or
                vary existing features or programs of the App or create, add,
                test or implement new features or programs on the App in which
                the Client may voluntarily choose to participate or may be a
                part of a test group with special access, in accordance with the
                additional terms and conditions of such features or programs. By
                participating in such features or programs, the Client grants
                the Company an unconditional and perpetual right and consent to
                the terms and conditions (if any) of such features or programs
                which will be in addition to these CALO Terms and Conditions.
              </li>
            </ol>
            <h2>X. CLIENT INFORMATIONY</h2>
            <ol>
              <li>
                For information about the collection and possible use of
                information and content provided by the Client, please review
                the Privacy Policy at the link{' '}
                <a
                  href="https://calo.run/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://calo.run/terms-and-conditions
                </a>
                .
              </li>
              <li>
                It is hereby expressly agreed and understood by the Client that
                for exchanging personal contact information of the Client with
                any other Client, the Client shall have the choice to opt into
                the exchange. Upon choosing to opt in, the Client shall be
                solely responsible and liable for any and all risks and
                consequences thereof and the Client hereby agrees to indemnify
                the Company for the same.
              </li>
              <li>
                Notwithstanding any other provisions of these CALO Terms and
                Conditions, the Company reserves the right to disclose any
                information that the Client submits to the Company, if in the
                Company’s sole opinion, the Company reasonably believes that
                such disclosure is required to be disclosed (i) for complying
                with applicable laws, requests or orders from law enforcement
                agencies, appropriate competent authorities or for any legal
                process; (ii) for enforcing these CALO Terms and Conditions;
                (iii) for protecting or defending the Company's, any other
                Client’s or any third party's rights or property; (iv) for
                supporting any fraud/ legal investigation/ verification checks;
                or (v) in connection with a corporate transaction, including but
                not limited to the sale of the Company's business, merger,
                consolidation, or in the unlikely event of bankruptcy. The
                Client acknowledges and understands the provisions of this
                Clause 10.3 and grants the Company an unconditional, perpetual
                right and permission to make such disclosure.
              </li>
              <li>
                By using the App, the Client hereby permits the Company to use
                the information the Client provides the Company, including the
                Client’s experiences to facilitate the Company to improve the
                App and its functionality as well as for promotional purposes,
                including the permission to publish the Client’s non-personal
                information in any of the Company’s partner websites and/or to
                use for research and analytical purposes.
              </li>
            </ol>
            <h2>X. PROHIBITED ACTIVITIES</h2>
            <p>
              The Company reserves the right to investigate, suspend and/or
              terminate, whether temporarily or permanently, the Client’s App
              account with the Company if the Client undertakes any of the
              following acts:
            </p>
            <ol>
              <li>
                Breach these CALO Terms and Conditions, Privacy Policy or Other
                Policies;
              </li>
              <li>
                Abuse, impersonate or defame the Company or any other Client or
                any other person, entity or any religious community;
              </li>
              <li>
                Use the App for any commercial use or activity not expressly
                permitted as per Clause 7 of these CALO Terms and Conditions;
              </li>
              <li>
                “Stalk” or otherwise harass any other Client or any other
                person;
              </li>
              <li>
                Make any statements, whether expressed or implied, and whether
                privately or publicly, as those endorsed by the Company without
                the Company’s specific prior written consent;
              </li>
              <li>
                Use the App in an illegal manner or commit an illegal act or use
                the App not expressly authorized by the Company;
              </li>
              <li>
                Use any robot, spider, tool, site search/retrieval application,
                or other manual or automatic device or process to retrieve,
                index, ‘data mine’, or in any way reproduce or circumvent the
                navigational structure or presentation of the App;
              </li>
              <li>
                Collect any personal information, including contact details, of
                any other Client by electronic or any other means and for any
                purpose, not expressly permitted under these CALO Terms and
                Conditions;
              </li>
              <li>
                Send any unsolicited email or any other communication in any way
                whatsoever not expressly permitted under these CALO Terms and
                Conditions;
              </li>
              <li>
                Undertake any unauthorized framing of or linking to the App or
                “frame” or “mirror” any part of the App, without The Company’s
                prior written authorization;
              </li>
              <li>
                Interfere with, obstruct, destroy or disrupt the App or the
                servers or networks connected to the App, whether partly or
                fully and whether permanently or temporarily;
              </li>
              <li>
                Email or otherwise transmit any content or material that
                contains software viruses, malware, spyware or any other
                computer code, files or programs designed to interrupt, destroy,
                disrupt or limit the functionality of the App or of any computer
                software or hardware or telecommunications equipment connected
                with the App;
              </li>
              <li>
                Forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any information transmitted to or through
                the App (either directly or indirectly through use of any third
                party software);
              </li>
              <li>
                Use meta tags, code, programs or other devices, tools or
                technology containing any reference to the Company or the App
                (or any trademark, trade name, service mark, logo or slogan of
                the Company or its partners) to direct any person to any other
                website or link or application for any purpose;
              </li>
              <li>
                Directly or indirectly modify, adapt, sublicense, translate,
                sell, reverse engineer, decipher, decompile or otherwise
                disassemble any portion of the App; or
              </li>
              <li>
                Post, use, transmit or distribute, directly or indirectly, in
                any manner or media any content (whether textual, graphical,
                images, audio, video, audio-video or any combination thereof) or
                information obtained from the App other than solely in
                connection with the Client’s use of the App in accordance with
                these CALO Terms and Conditions.
              </li>
              <li>
                Registration of multiple accounts for a single user or
                registration of multiple accounts by assuming fake identities;
              </li>
              <li>
                Undertaking and participating in any fraudulent activities with
                a purpose to collect invite rewards;
              </li>
              <li>
                Hit the Company’s servers/use a code/ undertake in any
                fraudulent activities to emulate steps, or adopt any means
                whatsoever to show and claim a number of steps, that is over and
                above the steps counted by the Company’s App i.e. Steps would be
                counted when the Client actually performed the athletic
                activities.
              </li>
            </ol>
            <h2>XII. CONTENT POSTED BY THE CLIENT</h2>
            <ol>
              <li>
                The Client is solely responsible for any and all content or
                information that the Client posts, uploads, shares, publishes,
                links to, transmits, records, displays or otherwise make
                available (hereinafter collectively referred to as “
                <b>publish</b>”) on or through the App or transmit to other
                Clients, including text messages, chat, audio, video,
                photographs, images, graphics or any combination thereof,
                whether publicly published or privately transmitted (hereinafter
                collectively referred to as “<b>Content</b>”).
              </li>
              <li>
                The Company does not verify or validate the completeness,
                accuracy or truth of any Content the Client publishes on or
                through the App. The Company is not the publisher of the Content
                and only provides the Client with a technology platform to
                facilitate the Client to publish such Content. The Company
                assumes no responsibility or liability of any sort whatsoever
                for providing a technology platform to the Company's Clients to
                facilitate publishing their Content. To protect the integrity of
                the App, the Company reserves the right to exercise editorial
                control over the Client’s Content, including the right to block
                any Client from using the App, whether temporarily or
                permanently.
              </li>
              <li>
                The Client shall not publish to the Company or to any other
                Client (either on or off the App), any offensive, inaccurate,
                incomplete, inappropriate, abusive, obscene, profane,
                threatening, intimidating, harassing, racially offensive, or
                illegal material or content that infringes or violates the
                rights of the Company or its partners or any third parties
                (including intellectual property rights, and rights of privacy
                and publicity).
              </li>
              <li>
                The Client represents and warrants that (i) all information and
                Content that the Client submits upon creation of the Client’s
                App account, including information submitted from any
                permissible linked third party account, is accurate, complete
                and truthful and that the Client will promptly update any
                information provided by the Client that subsequently becomes
                inaccurate, incomplete, misleading or false and (ii) the Client
                has the right to publish the Content on the App and grant the
                licenses as agreed in these CALO Terms and Conditions.
              </li>
              <li>
                The Client understands and agrees that the Company may monitor
                or review any Content the Client publishes on the App. The
                Company may delete any Content, in whole or in part, that in the
                Company’s sole judgment violates these CALO Terms and Conditions
                or may harm the reputation of the App or the Company or its
                partners.
              </li>
              <li>
                By publishing Content on the App, the Client automatically
                grants the Company and to the Company's partners, affiliates,
                licensees, successors and assigns, an irrevocable, perpetual,
                non-exclusive, transferable, sub-licensable, fully
                paid-up/royalty-free, worldwide right and license to (i) use,
                copy, store, perform, display, reproduce, record, play, adapt,
                modify and distribute the Content, (ii) prepare derivative works
                of the Content or incorporate the Content into other works, and
                (iii) grant and authorize sublicenses of the foregoing in any
                media now known or hereafter created. The Client represents and
                warrants that any such publishing and/or use of the Client’s
                Content will not infringe or violate the rights of any third
                party.
              </li>
              <li>
                The Client shall not publish, upload, modify, display, publish,
                transmit, update, share or otherwise make available Content
                that:
                <p>
                  a. Advocates harassment or intimidation of another person;
                </p>
                <p>
                  b. Relates to or promotes or encourages money laundering, sex
                  trafficking or gambling;
                </p>
                <p>
                  c. Requests money from, or is intended to otherwise defraud,
                  other Clients of the App;
                </p>
                <p>
                  d. Involves the transmission of “junk mail”, “chain letters,”
                  or “spamming” or similar activities;
                </p>
                <p>
                  e. Promotes racism, bigotry, hatred or physical harm or injury
                  of any kind against any religion, group, community or
                  individual or is offensive, false, misleading, untrue,
                  unlawful, illegal, defamatory, harassing, disparaging,
                  obscene, sexually explicit, blasphemous, scandalous, libelous,
                  threatening, abusive, hateful, harmful, bigoted, racially
                  offensive, invasive of privacy right of any person, or
                  otherwise objectionable or inappropriate;
                </p>
                <p>
                  f. Belongs to another person and to which the Client are
                  already aware that the same does not belong to the Client or
                  that the Client do not have any right to the same;
                </p>
                <p>
                  g. Is an illegal or unauthorized copy of another person’s
                  copyrighted work;
                </p>
                <p>
                  h. Contains video, audio, photographs or images of a person
                  without his or her permission (or in the case of a minor, the
                  minor’s legal guardian);
                </p>
                <p>
                  i. Contains unauthorised, restricted or password only access
                  pages or hidden pages or images;
                </p>
                <p>
                  j. Contains or disseminates viruses, time bombs, trojan
                  horses, worms or other harmful or disruptive codes, components
                  or devices;
                </p>
                <p>
                  k. Impersonates, or otherwise misrepresents affiliation,
                  connection or association with, any person or entity;
                </p>
                <p>
                  l. Provides information or data the Client does not have a
                  right to make available under law or under contractual or
                  fiduciary relationships (such as inside information,
                  proprietary and confidential information);
                </p>
                <p>
                  m. Disrupts the normal flow of communication between other
                  Clients or otherwise negatively affects a Clients’ ability to
                  engage in real time communication through the App;
                </p>
                <p>
                  n. Solicits passwords or personal identifiable information for
                  commercial, unauthorised or unlawful purposes from other
                  Clients or disseminates another person’s personal information
                  without his or her permission;
                </p>
                <p>
                  o. Contains any advertising or commercial messages not
                  expressly permitted under these CALO terms and conditions;
                </p>
                <p>
                  p. Infringes upon or violates any third party's right to
                  privacy, including any intellectual property rights;
                </p>
                <p>
                  q. Hinders the App functionality in any way or interferes or
                  affects other Clients’- use and enjoyment of the App; or
                  (xviii) violates any applicable law for the time being in
                  force.
                </p>
              </li>
              <li>
                The Company reserves the right, in the Company’s sole
                discretion, to investigate and take any legal action against
                anyone who violates this Clause 12.7, including deleting or
                removing the offending Content from the App and/or terminating
                or suspending the App account of such violating Client. Whilst
                the Company reserves the Company’s right to delete or remove
                such Content, the Company does not guarantee that such offensive
                Content will be removed or deleted. Failure by the Company to
                remove or delete such Content does not waive the Company's right
                to remove or delete the same in subsequent or similar cases.
              </li>
              <li>
                The Client’s use of the App, including all Content the Client
                publishes, must comply with all applicable laws and regulations.
                The Client agrees that the Company may access, preserve and
                disclose the Client’s account information and Content if
                required to do so by law or in a good faith belief that such
                access, preservation or disclosure is reasonably necessary, such
                as to (i) comply with applicable laws, requests or orders from
                law enforcement agencies, appropriate competent authorities or
                for any legal process or proceeding; (ii) protect or defend the
                rights or property of the Company or any third party; (iii)
                enforce these CALO Terms and Conditions, Privacy Policy and
                Other Policies; (iii) in support of any fraud/ legal
                investigation/ verification checks; or (iv) respond to the
                Client’s requests for customer service or allow the Client to
                use the App in the future.
              </li>
              <li>
                The Company assumes no responsibility or liability for any
                deletion of or failure to store any of the Client’s Content.
              </li>
            </ol>
            <h2>XIII. MODIFICATIONS TO THE APP</h2>
            <p>
              The Company reserves the right at any time to modify or
              discontinue, temporarily or permanently, the App (or any part
              thereof) with or without notice. The Client agrees that the
              Company shall not be liable to the Client or to any third party
              for any modification, suspension or discontinuance of the App. Any
              access or usage by the Client of the App shall imply that the
              Client has accepted any new or modified CALO Terms and Conditions.
              Please re-visit these CALO Terms and Conditions from time to time
              to stay abreast of any changes that the Company may introduce.
            </p>
            <h2>XIV. DISCLAIMER OF WARRANTY</h2>
            <ol>
              <li>
                To the maximum extent permitted by applicable law, the Company
                has provided the App on an "AS IS" and "AS AVAILABLE" and “BEST
                EFFORTS” basis and grant no warranties of any kind, whether
                express, implied, direct, indirect statutory or otherwise with
                respect to the App or any part thereof (including all content
                contained therein), including any implied warranties of
                correctness, validity, accuracy, completeness, appropriateness,
                fitness, compatibility for a particular purpose or outcome or
                non-infringement. The Company does not warrant that the use of
                the App will always be secured, uninterrupted, available,
                error-free, free of viruses or similar contamination or
                destructive features or will meet the Client’s requirements or
                expectations, or that any defects in the App will be corrected
                or result in the desired results. The Company disclaims
                liability for, and no warranty is made with respect to, the
                connectivity and availability of the App at all times and the
                results of the use of the App.
              </li>
              <li>
                Opinions, advice, statements, offers, or other information or
                content made available through the App, but not directly by the
                Company, are those of their respective authors, and should not
                necessarily be relied upon. Such authors are solely responsible
                for such Content. The Company does not (i) guarantee the
                accuracy, completeness or usefulness of any information provided
                on the App, or (ii) adopt, endorse or accept responsibility for
                the accuracy or reliability of any opinion, advice, or statement
                made by any party other than the Company.
              </li>
              <li>
                From time to time, the Company may offer new features or tools
                which the Company’s Clients may experiment or experience. Such
                features or tools are offered solely for experimental purposes
                and without any warranty of any kind and may be modified or
                discontinued at the Company’s sole discretion. The provisions of
                this Disclaimer of Warranty section shall apply with full force
                to such features and tools.
              </li>
              <li>
                The Company accepts no responsibility for any damage, loss,
                liabilities, injury or disappointment incurred or suffered by
                the Client as a result of the App.
              </li>
            </ol>
            <h2>XV. LIMITATION OF LIABILITY</h2>
            <ol>
              <li>
                The Client expressly agrees that the Company is not providing
                medical advice on or through the App. All content provided on or
                through the App, including all text, photographs, images,
                illustrations, graphics, audio, video and audio-video clips, and
                other materials, whether provided by the Company or by other
                Clients or third parties are not intended to be and should not
                be used in place of (a) the advice of the Client’s physician or
                other medical professionals, (b) a visit, call or consultation
                with the Client’s physician or other medical professionals, or
                (c) information contained on or in any product packaging or
                label. Should the Client have any health-related questions,
                please call or see the Client’s physician or other healthcare
                providers promptly.
              </li>
              <li>
                The Client should never disregard medical advice or delay in
                seeking medical advice because of any content presented on or
                through the App, and the Client should not use the App or any
                content available through or via the App for diagnosing or
                treating a health problem. The transmission and receipt of any
                content, in whole or in part, or communication via the internet,
                e-mail, or other means does not constitute or create a
                doctor-patient, therapist-patient or other healthcare
                professional relationship between the Client and the Company or
                other Clients or any third parties.
              </li>
              <li>
                The Client expressly agrees that the Client’s athletic
                activities on or through the App carry certain inherent and
                significant risks of property damage, bodily injury, or death
                and that the Client voluntarily assumes all known and unknown
                risks associated with these athletic activities, even if caused
                in whole or part by the action, inaction, or negligence of the
                parties or by the action, inaction, or negligence of others. The
                Client also expressly agrees that the Company shall not be
                responsible and liable for the inspection, supervision,
                preparation, or conduct of any race, contest, group athletic
                activity, or event on or through the App. The Client is strongly
                encouraged to take all necessary precautions to make sure that
                the Client satisfies all reasonable mental and physical
                conditions necessary and has a complete physical examination by
                a licensed medical doctor prior to doing such athletic
                activities.
              </li>
              <li>
                To the maximum extent permitted by applicable law, the Client
                expressly agrees that in no event will the Company be liable to
                the Client or any third parties for any direct, indirect,
                punitive, incidental, special, or consequential damages arising
                out of or in any way connected with (a) the Client's use or
                misuse of the; (b) the Client's use or misuse of equipment or
                programs created, sold, or licensed by the Company while engaged
                in athletic activities; (c) the use, disclosure, display, or
                maintenance of the Client’s personal information and/or location
                information; (d) the result of using the App, including without
                limitation, damages for recommendation of the App, loss or
                corruption of data or programs, service interruptions and
                procurement of substitute services; (e) the Client's dealings
                with third parties service providers or advertisers available on
                or through the App; (f) any delay or inability to use the App
                experienced by the Client; (g) the networks and systems that
                make the App available; (h) any information, software, products,
                services, or content obtained on or through the App, (i) any
                interactions between the Client with the Company or other client
                or any other third parties, whether based on contract, tort,
                strict liability, or otherwise, even if any of the Company know
                or have been advised of the possibility of damages. This
                limitation also applies, without limitation, to the costs of
                procurement of substitute goods or services, lost profits.
              </li>
              <li>
                To the maximum extent permitted by applicable law, without
                limiting the foregoing, under no circumstances will the Company,
                its partners, affiliates, subsidiaries, and each of their
                respective officers, directors, employees, agents, consultants
                and other related or affiliated third parties be held liable for
                any delay or failure in performance of its obligations resulting
                directly or indirectly from acts of nature, forces, or causes
                beyond their reasonable control, including, without limitation,
                internet failures, computer equipment or security failures,
                telecommunication equipment failures, other equipment failures,
                hacking, electrical power failures, strikes, labor disputes,
                riots, insurrections, civil disturbances, shortages of labor or
                materials, fires, floods, storms, explosions, acts of God, war,
                governmental actions, orders of domestic or foreign courts or
                tribunals, non-performance obligations of third parties.
              </li>
              <li>
                The Client expressly agrees to release the Company, its
                partners, affiliates, subsidiaries, and each of their respective
                officers, directors, employees, agents, consultants and other
                related or affiliated third parties, from any and all liability
                connected with the Client's use of the App (including but not
                limited to the Client's participation in or any athletic
                activities), and promise not to sue the Company, its partners,
                affiliates, subsidiaries, and each of their respective officers,
                directors, employees, agents, consultants and other related or
                affiliated third parties for any claims, actions, injuries,
                death, damages, or losses associated with the Client use of the
                App (including but not limited to the Client participation in or
                any athletic activities).
              </li>
            </ol>
            <h2>XVI. INDEMNIFICATION</h2>
            <p>
              The Client agrees to indemnify, defend and hold the Company
              harmless, as well as its partners, affiliates, subsidiaries, and
              each of their respective officers, directors, employees, agents,
              consultants and other related or affiliated third parties, from
              and against any and all losses, claims, costs, demands,
              liabilities and expenses (including reasonable attorneys’ fees)
              relating to or arising out of the Client’s use of the App,
              including but not limited to (i) any violation by the Client of
              these CALO Terms and Conditions, or (ii) any action arising from
              the Content that the Client publish on the App or using the App
              that infringes any proprietary or intellectual property rights
              (e.g. copyright, trade secrets, trademark or patent) of any third
              party, or (iii) any content or communication that denigrates,
              libels or invades the privacy right of any third party. The
              Company reserves the right, at the Company’s own cost, to assume
              the exclusive defense and control of any matter otherwise subject
              to indemnification by the Client, and the Client will cooperate
              fully in asserting any available defenses in such case.
            </p>
            <h2>XVII. CAUTION</h2>
            <ol>
              <li>
                The Company may check new user account profiles strictly for
                verifying the accuracy of profile information and any
                unsuitable, objectionable or inappropriate content. However, the
                Company may not be able to verify the identity of all such
                Clients or the accuracy of their content, nor can the Company
                guarantee that the Company will be able to identify all
                unsuitable, objectionable or inappropriate content. Please do
                not take any other Client content as fully accurate and/or
                complete. The Company will not be liable or responsible for any
                false and misleading content and information given by any
                Client. If the Client has any concerns over any other Client
                content, please write to us on{' '}
                <a href="mailto:tech@calo.run">tech@calo.run</a> with the
                details thereof.
              </li>
              <li>
                The Client hereby acknowledges and agrees that the Client shall
                ensure that at all times the Client’s interaction with other
                Clients of the App will always be lawful and appropriate and
                that the Client alone shall be responsible for all consequences
                thereof.
              </li>
            </ol>
            <h2>XVIII. MISCELLANEOUS</h2>
            <ol>
              <li>
                <b>Entire Agreement:</b> These CALO Terms and Conditions
                constitutes the entire agreement between the Client and the
                Company regarding the subject matter hereof, and replaces and
                supersedes any and all prior agreements/ understandings/
                correspondences, whether written or oral, between the Client and
                the Company.
              </li>
              <li>
                <b>Amendment:</b> The Company reserves the right to amend these
                CALO Terms and Conditions from time to time. Any such amendments
                will be applicable to all persons viewing/accessing/using the
                App once the revisions have been posted onto the same. The
                Client should therefore check the App from time to time to
                review the current CALO Terms and Conditions as applicable to
                the Client.
              </li>
              <li>
                <b>Survival:</b> Termination or suspension of the Client’s App
                Account shall not affect those provisions hereof that by their
                nature are intended to survive such termination or suspension.
              </li>
              <li>
                <b>Governing Law and Jurisdiction:</b> These CALO Terms and
                Conditions shall be governed and construed in accordance with
                the laws of Singapore. Any and all disputes, controversies or
                conflicts arising from or in relation to this CALO Terms and
                Conditions and/or the App, including disputes on its existence,
                validity, conclusion, binding effect, breach, amendment,
                expiration, and termination (hereinafter collectively referred
                to as (the “<b>Dispute</b>”), shall, as far as possible, be
                settled amicably by the Company and the Client. If any such the
                Dispute is not settled amicably within thirty (30) days as from
                the date on which any party informs the other party that any the
                Dispute has arisen, the Company and the Client agree that such
                the Dispute shall be referred to and finally resolved by
                Singapore International Arbitration Centre (“<b>SIAC</b>”) in
                accordance with the SIAC rules, which rules are deemed to be
                incorporated by reference in this Clause. The tribunal shall
                consist of one (01) arbitrator, to be appointed by the President
                of the SIAC. The language of the arbitration shall be English.
                The physical venue of the arbitration shall be Singapore. Any
                arbitral award is final and may be enforced in any court of
                competent jurisdiction. The Company and the Client shall duly
                and punctually perform their obligations hereunder pending
                issuance of the arbitral award.
              </li>
              <li>
                <b>No Assignment:</b> These CALO Terms and Conditions is
                personal to the Client. The Client cannot assign the Client’s
                rights or obligations hereunder, whether partly or fully, to
                anyone.
              </li>
              <li>
                <b>Severability:</b> If any provisions of these CALO Terms and
                Conditions is held invalid or unenforceable under applicable
                law, such provision will be inapplicable, but the remainder will
                continue in full force and effect.
              </li>
              <li>
                <b>Waiver:</b> No waiver of any term, provision or condition of
                these CALO Terms and Conditions whether by conduct or otherwise
                in any one or more instances shall be deemed to be or construed
                as a further or continuing waiver of any such term, provision or
                condition or of any other term, provision or condition of these
                CALO Terms and Conditions.
              </li>
            </ol>
            <h2>XIX. CHANGES</h2>
            <p>
              The Company reserves the right, at any time, to modify or update
              these CALO Terms and Conditions, Privacy Policy and/or Other
              Policies. Changes may be made without notice, and the Client
              agrees to be bound by such modifications or updates. When the
              Company posts any changes, the Company will revise the "last
              updated" date. The Client agrees to periodically review these CALO
              Terms and Conditions, Privacy Policy and/or Other Policies for
              changes.
            </p>
            <h2>XX. CONTACT US</h2>
            <p>
              Please contact us by email on{' '}
              <a href="mailto:tech@calo.run">tech@calo.run</a> for any questions
              or comments regarding these CALO Terms and Conditions.
            </p>
          </div>
        </StyledContainer>
        <Footer />
        <Affix offsetBottom={80}>
          <BackTop>
            <img
              src={theme === 'dark' ? iconTopDark : iconTopLight}
              width="56px"
              height="56px"
            />
          </BackTop>
        </Affix>
      </StyledLayout>
    </ThemeProvider>
  );
};

export default TermPage;
