import styled from 'styled-components';

export const StyledSocialMobile = styled.section`
  min-height: 692px;
  padding-top: 48px;
  position: relative;
  .container {
    margin: auto;
    padding: 0;

    .containerTop {
      .title {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        text-align: center;
        color: ${({ theme }) => theme.title};
        padding-bottom: 16px;
      }
      .description {
        max-width: 243px;
        margin: 0 auto;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-align: center;
        color: ${({ theme }) => theme.description};
      }
    }
    .containerBottom {
      margin-top: 48px;
      position: relative;
      min-height: 452px;
      .mainObject {
        text-align: center;
        img {
          max-width: 300px;
        }
      }
      @media (min-width: 767px) {
        max-width: 450px;
        margin: 48px auto 0 auto;
      }
      .icon-wrapper {
        margin-top: 16px;
        margin: 0 auto;
        width: 224px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        img {
          margin-bottom: 16px;
          margin-right: 16px;
          width: 40px;
        }
      }
    }
  }
`;
