import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import MeasureDesktop from './MeasureDesktop';
import MeasureMobile from './MeasureMobile';

const StepMeasure = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<MeasureDesktop {...props} />}
      mobileView={<MeasureMobile {...props} />}
    />
  );
};

export default StepMeasure;