import logoVecom from 'src/assets/images/partners/logoVecom.png';
import logoSweetLife from 'src/assets/images/partners/logoSweetLife.png';
import logoChinaPolka from 'src/assets/images/partners/logoChinaPolka.png';
import logoDEODark from 'src/assets/images/partners/logoDEODark.png';
import logoDEOLight from 'src/assets/images/partners/logoDEOLight.png';
import logoCRVNLight from 'src/assets/images/partners/logoCRVNCapital.png';
import logoCRVNDark from 'src/assets/images/partners/logoCRVNDark.png';
import logoWildCatDark from 'src/assets/images/partners/logoWildCatDark.png';
import logoWildCatLight from 'src/assets/images/partners/logoWildCatVenture.png';
import logoBFRILight from 'src/assets/images/partners/logoBFRI.png';
import logoBFRIDark from 'src/assets/images/partners/logoBFRIDark.png';
import logoFairumLight from 'src/assets/images/partners/logoFAIRUM.png';
import logoFairumDark from 'src/assets/images/partners/logoFairumDark.png';
import logoAstronautCapitalLight from 'src/assets/images/partners/logoAstronautCapital.png';
import logoAstronautCapitalDark from 'src/assets/images/partners/logoAstronautCapitalDark.png';
import logoPencilLight from 'src/assets/images/partners/logoPencil.png';
import logoPencilDark from 'src/assets/images/partners/logoPencilDark.png';
import logoMVOTLight from 'src/assets/images/partners/logoMVOT.png';
import logoMVOTDark from 'src/assets/images/partners/logoMVOTDark.png';
import logoBTCMondayLight from 'src/assets/images/partners/logoBTCMonday.png';
import logoBTCMondayDark from 'src/assets/images/partners/logoBTCMondayDark.png';
import logoTSSLight from 'src/assets/images/partners/logoTSSLight.png';
import logoTSSDark from 'src/assets/images/partners/logoTSSDark.png';
import logoMonihubLight from 'src/assets/images/partners/logoMonihub.png';
import logoMonihubDark from 'src/assets/images/partners/logoMonihubDark.png';
import logoNulyLight from 'src/assets/images/partners/logoNulyLight.png';
import logoNulyDark from 'src/assets/images/partners/logoNulyDark.png';
import logoBmoonLight from 'src/assets/images/partners/logoBmoonLight.png';
import logoBmoonDark from 'src/assets/images/partners/logoBmoonDark.png';
import logoJadeLabsLight from 'src/assets/images/partners/logoJadeLabsLight.png';
import logoJadeLabsDark from 'src/assets/images/partners/logoJadeLabsDark.png';
import logoBASLight from 'src/assets/images/partners/logoBAS.png';
import logoBASDark from 'src/assets/images/partners/logoBASDark.png';
import logoAVStarLight from 'src/assets/images/partners/logoAVStarLight.png';
import logoAVStarDark from 'src/assets/images/partners/logoAVStarDark.png';
import logoMVCLight from 'src/assets/images/partners/logoMVCLight.png';
import logoMVCDark from 'src/assets/images/partners/logoMVCDark.png';
import logoCyclicAdsLight from 'src/assets/images/partners/logoCyclicAdsLight.png';
import logoCyclicAdsDark from 'src/assets/images/partners/logoCyclicAdsDark.png';
import logoSGNCapitalLight from 'src/assets/images/partners/logoSGNCapital.png';
import logoSGNCapitalDark from 'src/assets/images/partners/logoSGNCapitalDark.png';
import logoOneBitDark from 'src/assets/images/partners/logoOneBit.png';
import logoOneBitLight from 'src/assets/images/partners/logoOnebitLight.png';
import logoRoyalroadLight from 'src/assets/images/partners/logoRoyalroadLight.png';
import logoRoyalroadDark from 'src/assets/images/partners/logoRoyalroadDark.png';
import logoBICCapital from 'src/assets/images/partners/logoBICCapital.png';
import logoGameHub from 'src/assets/images/partners/logoGameHub.png';
import logoAsterdidVenture from 'src/assets/images/partners/logoAsterdidVenture.png';
import logoGemsLab from 'src/assets/images/partners/logoGemsLab.png';
import logoBArmy from 'src/assets/images/partners/logoBArmy.png';
import logoBMWCapital from 'src/assets/images/partners/logoBMWCapital.png';
import logoCoinLab from 'src/assets/images/partners/logoCoinLab.png';
import logoCryptoFutureVenture from 'src/assets/images/partners/logoCryptoFutureVenture.png';
import logoIMGroup from 'src/assets/images/partners/logoIMGroup.png';
import logoInvestpush from 'src/assets/images/partners/logoInvestpush.png';
import logoLightHouse from 'src/assets/images/partners/logoLightHouse.png';
import logoMIFU from 'src/assets/images/partners/logoMIFU.png';
import logoSTech from 'src/assets/images/partners/logoSTech.png';
import logoMoonka from 'src/assets/images/partners/logoMoonka.png';
import logoChainPlay from 'src/assets/images/partners/logoChainPlay.png';
import logoNovusDark from 'src/assets/images/partners/logoNovusDark.png';
import logoNovusLight from 'src/assets/images/partners/logoNovusLight.png';
import logoIBC from 'src/assets/images/partners/logoIBC.png';
import logoKOICapital from 'src/assets/images/partners/logoKOICapital.png';
import logoBSCStation from 'src/assets/images/launchpad/logoBSCStation.png';
import INFINITYPAD from 'src/assets/images/launchpad/INFINITYPAD.png';
import INFINITYPAD_DARK from 'src/assets/images/launchpad/INFINITYPADDARK.png';
import logoRoseon from 'src/assets/images/launchpad/logoRoseon.png';
import logoAppotaDark from 'src/assets/images/partners/logoAppotaDark.png';
import logoAppotaLight from 'src/assets/images/partners/logoAppotaLight.png';
import logoEzChainDark from 'src/assets/images/partners/logoEzChainDark.png';
import logoEzChainLight from 'src/assets/images/partners/logoEzChainLight.png';

const backers = [
  {
    name: 'MVC',
    logoLight: logoMVCLight,
    logoDark: logoMVCDark,
  },
];

const corePartners = [
  {
    name: 'VECOM',
    logoLight: logoVecom,
    logoDark: logoVecom,
  },
  {
    name: 'Appota',
    logoLight: logoAppotaLight,
    logoDark: logoAppotaDark,
  },
  {
    name: 'BMWCapital',
    logoLight: logoBMWCapital,
    logoDark: logoBMWCapital,
  },
  {
    name: 'KOICapital',
    logoLight: logoKOICapital,
    logoDark: logoKOICapital,
  },
  {
    name: 'PencilGroup',
    logoLight: logoPencilLight,
    logoDark: logoPencilDark,
  },
  {
    name: 'BSCStation',
    logoLight: logoBSCStation,
    logoDark: logoBSCStation,
  },
];

const partners = [
  {
    name: 'OneBit',
    logoLight: logoOneBitLight,
    logoDark: logoOneBitDark,
  },
  {
    name: 'Wildcat',
    logoLight: logoWildCatLight,
    logoDark: logoWildCatDark,
  },
  {
    name: 'DEONetwork',
    logoLight: logoDEOLight,
    logoDark: logoDEODark,
  },
  {
    name: 'ChinaPolka',
    logoLight: logoChinaPolka,
    logoDark: logoChinaPolka,
  },
  {
    name: 'BArmy',
    logoLight: logoBArmy,
    logoDark: logoBArmy,
  },
  {
    name: 'Sweetlife',
    logoLight: logoSweetLife,
    logoDark: logoSweetLife,
  },
  {
    name: 'EzChain',
    logoLight: logoEzChainLight,
    logoDark: logoEzChainDark,
  },
  {
    name: 'Lighthouse',
    logoLight: logoLightHouse,
    logoDark: logoLightHouse,
  },
  {
    name: 'CoinLab',
    logoLight: logoCoinLab,
    logoDark: logoCoinLab,
  },
  {
    name: 'CRVN',
    logoLight: logoCRVNLight,
    logoDark: logoCRVNDark,
  },
  {
    name: 'InfinityPad',
    logoLight: INFINITYPAD,
    logoDark: INFINITYPAD_DARK,
  },
  {
    name: 'Roseon',
    logoLight: logoRoseon,
    logoDark: logoRoseon,
  },
  {
    name: 'Novus',
    logoLight: logoNovusLight,
    logoDark: logoNovusDark,
  },
  {
    name: 'FAIRUM',
    logoLight: logoFairumLight,
    logoDark: logoFairumDark,
  },
  {
    name: 'BICCapital',
    logoLight: logoBICCapital,
    logoDark: logoBICCapital,
  },
  {
    name: 'BFRI',
    logoLight: logoBFRILight,
    logoDark: logoBFRIDark,
  },
  {
    name: 'AVStar',
    logoLight: logoAVStarLight,
    logoDark: logoAVStarDark,
  },
  {
    name: 'Monihub',
    logoLight: logoMonihubLight,
    logoDark: logoMonihubDark,
  },
  {
    name: 'CyclicAds',
    logoLight: logoCyclicAdsLight,
    logoDark: logoCyclicAdsDark,
  },
  {
    name: 'BAS',
    logoLight: logoBASLight,
    logoDark: logoBASDark,
  },
  {
    name: 'SGNCapital',
    logoLight: logoSGNCapitalLight,
    logoDark: logoSGNCapitalDark,
  },
  {
    name: 'Asterdid',
    logoLight: logoAsterdidVenture,
    logoDark: logoAsterdidVenture,
  },
  {
    name: 'AstronautCapital',
    logoLight: logoAstronautCapitalLight,
    logoDark: logoAstronautCapitalDark,
  },
  // {
  //   name: 'CryptoFuture',
  //   logoLight: logoCryptoFutureVenture,
  //   logoDark: logoCryptoFutureVenture,
  // },
  {
    name: 'MVOT',
    logoLight: logoMVOTLight,
    logoDark: logoMVOTDark,
  },
  {
    name: 'BTCMonday',
    logoLight: logoBTCMondayLight,
    logoDark: logoBTCMondayDark,
  },
  {
    name: 'Nuly',
    logoLight: logoNulyLight,
    logoDark: logoNulyDark,
  },
  {
    name: 'Investpush',
    logoLight: logoInvestpush,
    logoDark: logoInvestpush,
  },
  {
    name: 'Jadelabs',
    logoLight: logoJadeLabsLight,
    logoDark: logoJadeLabsDark,
  },
  {
    name: 'STech',
    logoLight: logoSTech,
    logoDark: logoSTech,
  },
  {
    name: 'MIFU',
    logoLight: logoMIFU,
    logoDark: logoMIFU,
  },
  {
    name: 'GemLabs',
    logoLight: logoGemsLab,
    logoDark: logoGemsLab,
  },
  {
    name: 'Bmoon',
    logoLight: logoBmoonLight,
    logoDark: logoBmoonDark,
  },
  {
    name: 'TSS',
    logoLight: logoTSSLight,
    logoDark: logoTSSDark,
  },
  {
    name: 'ImGroup',
    logoLight: logoIMGroup,
    logoDark: logoIMGroup,
  },
  {
    name: 'Moonka',
    logoLight: logoMoonka,
    logoDark: logoMoonka,
  },
  {
    name: 'RoyalRoadHoldings',
    logoLight: logoRoyalroadLight,
    logoDark: logoRoyalroadDark,
  },
  {
    name: 'IBC',
    logoLight: logoIBC,
    logoDark: logoIBC,
  },
  {
    name: 'ChainPlay',
    logoLight: logoChainPlay,
    logoDark: logoChainPlay,
  }
];

export { backers, partners, corePartners };
