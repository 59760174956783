import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import { Image } from 'antd';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import topicListingFit from 'src/assets/images/topics/topicListingFit.png';
import topicExchange from 'src/assets/images/topics/topicExchange.png';
import topicNewGame from 'src/assets/images/topics/topicNewGame.png';
import topicLaunching from 'src/assets/images/topics/topicLaunching.png';
import circleTopicLight from 'src/assets/images/circleTopicLight.svg';
import { StyledTopics } from './styled';
import { useAppStore } from 'src/hooks/useAppStore';

const TopicsDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  const { topics, statistic } = props;
  const totalDistance = statistic?.totalDistance / 1000;
  const totalFitEarned = statistic?.totalFitEarned;
  const discordMembers = statistic?.discordMembers;
  const twitterFollowers = statistic?.twitterFollowers;

  const [inViewRef, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  const SRC_URL = 'https://editor.calo.run';
  const BLOG_URL = 'https://blog.calo.run/en/';

  const renderSuffix = (value) => {
    if (value >= 1_000_000_000) {
      return 'b';
    }
    if (value >= 1_000_000) {
      return 'm';
    }
    if (value >= 1000) {
      return 'k';
    }
    return '';
  };

  const calcNumber = (value) => {
    if (value >= 1_000_000_000) {
      return value / 1_000_000_000;
    }
    if (value >= 1_000_000) {
      return value / 1_000_000;
    }
    if (value >= 1000) {
      return value / 1_000;
    }
    return value;
  };

  return (
    <StyledTopics ref={inViewRef}>
      <div className="container">
        {!isDarkMode && (
          <div className="circleCorner">
            <img src={circleTopicLight} />
          </div>
        )}
        <div className="title">Number of our Communities</div>
        <div className="counterContainer">
          <div className="counterItem">
            {inView && (
              <div className="number">
                <CountUp
                  duration={2}
                  end={totalDistance && calcNumber(parseFloat(totalDistance))}
                  suffix={
                    totalDistance ? `${renderSuffix(totalDistance)} +` : ''
                  }
                />
              </div>
            )}
            <div className="label">Distance (km)</div>
          </div>
          <div className="counterItem">
            {inView && (
              <div className="number">
                <CountUp
                  duration={2}
                  decimal="."
                  decimals={3}
                  end={totalFitEarned && calcNumber(parseFloat(totalFitEarned))}
                  suffix={
                    totalFitEarned ? `${renderSuffix(totalFitEarned)} +` : ''
                  }
                />
              </div>
            )}
            <div className="label">FIT Earning</div>
          </div>
          <div className="counterItem">
            {inView && (
              <div className="number">
                <CountUp
                  duration={2}
                  end={discordMembers && calcNumber(parseFloat(discordMembers))}
                  suffix={
                    discordMembers ? `${renderSuffix(discordMembers)} +` : ''
                  }
                />
              </div>
            )}
            <div className="label">Discord members</div>
          </div>
          <div className="counterItem">
            {inView && (
              <div className="number">
                <CountUp
                  duration={2}
                  end={
                    twitterFollowers && calcNumber(parseFloat(twitterFollowers))
                  }
                  suffix={
                    twitterFollowers
                      ? `${renderSuffix(twitterFollowers)} +`
                      : ''
                  }
                />
              </div>
            )}
            <div className="label">Twitter followers</div>
          </div>
        </div>
        <div className="activitiesContainer">
          <div className="title">Hot topics</div>
          <Image.PreviewGroup>
            {topics?.length > 0 ? (
              <SliderSlick
                showArrow={true}
                paddingX="24px"
                paddingY=""
                data-top="55%"
              >
                {topics?.map((topic, index) => {
                  const imgUrl =
                    topic?.attributes?.image?.data?.attributes?.formats?.medium
                      ?.url;
                  if (index >= 8) {
                    return null;
                  }
                  return (
                    <div className="activityItem link">
                      <a
                        href={BLOG_URL + topic?.attributes?.slug}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image src={SRC_URL + imgUrl} preview={false} />
                      </a>
                    </div>
                  );
                })}
              </SliderSlick>
            ) : (
              <SliderSlick showArrow={false} paddingX="16px" paddingY="">
                <div className="activityItem link">
                  <Image src={topicLaunching} preview={false} />
                </div>
                <div className="activityItem link">
                  <Image src={topicNewGame} preview={false} />
                </div>
                <div className="activityItem link">
                  <Image src={topicListingFit} preview={false} />
                </div>
              </SliderSlick>
            )}
          </Image.PreviewGroup>
        </div>
      </div>
    </StyledTopics>
  );
};

export default TopicsDesktop;
