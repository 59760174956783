import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import AppScreenDesktop from './AppScreenDesktop';
import AppScreenMobile from './AppScreenMobile';

const AppScreen = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<AppScreenDesktop {...props} />}
      mobileView={<AppScreenMobile {...props} />}
    />
  );
};

export default AppScreen;
