import styled from 'styled-components';

export const StyledMeasureDesktop = styled.div`
  padding: 64px 0;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .title {
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #ffffff;
      padding-bottom: 18px;
    }
    .main-title {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #ff8a34;
      padding-bottom: 48px;
    }
    .text-desc {
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.0125em;
      color: #ffffff;
    }
    .dot-running {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #58fe90;
      margin: 0 8px;
    }
    .icon {
      margin-right: 8px;
    }
    .content-wrapper {
      margin-top: 160px;
      position: relative;
      img {
        position: relative;
        z-index: 1;
      }
      @media (max-width: 1024px) {
        img {
          max-width: 80%;
        }
      }
      .blur {
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 872px;
        height: 872px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(49, 193, 255, 0.3) -60%,
          rgba(53, 85, 255, 0) 80%
        );
        @media (max-width: 1024px) {
          width: 572px;
          height: 572px;
        }
      }
    }
  }
`;
