import React from 'react';
import { useAppStore } from 'src/hooks/useAppStore';
import sneakerDetail from 'src/assets/images/howtoplay/sneakerDetail2.png';
import { StyledSneakerDetailDesktop } from './styled';

const SneakerDetailDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;

  return (
    <StyledSneakerDetailDesktop isDarkMode={isDarkMode}>
      <div className="container">
        <div className="title">SNEAKER DETAIL PAGE</div>
        <div className="content-wrapper flex-center">
          <div className="sneaker-detail">
            <img className='img-fluid' src={sneakerDetail} alt="img-detail" />
          </div>
        </div>
      </div>
    </StyledSneakerDetailDesktop>
  );
};

export default SneakerDetailDesktop;
