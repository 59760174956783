import React from 'react';
import { StyledBannerLunarGacha23 } from './styled';
import btnAndroid from 'src/assets/images/btnAndroid.svg';
import btnIos from 'src/assets/images/btnIos.svg';
import lunarTitle from 'src/assets/images/introduction/desktop/lunar/lunarTitle.png';
import lunarDesc from 'src/assets/images/introduction/desktop/lunar/lunarDesc.png';
import lunarCatMb from 'src/assets/images/introduction/mobile/lunar/lunarCatMb.png';

const BannerLunarGacha23 = () => {
  return (
    <StyledBannerLunarGacha23 className="bannerScreenContainer">
      <div className="contentIntro">
        <div className="title">
          <img className="img-fluid" src={lunarTitle} alt="lunarTitle" />
        </div>
        <div className="desc">
          <img className="img-fluid" src={lunarDesc} alt="desc" />
        </div>
        <div className="imageBanner flex-center">
          <img className="img-fluid" src={lunarCatMb} alt="imageBanner" />
        </div>
        <div className="downloadBox flex-center">
          <div className="btn-download">
            <a
              href="https://apps.apple.com/us/app/calo-run/id1606108720"
              target="_blank"
              rel="noreferrer"
            >
              <img src={btnIos} alt="download" />
            </a>
          </div>
          <div className="btn-download">
            <a
              href="https://play.google.com/store/apps/details?id=com.calometaverse"
              target="_blank"
              rel="noreferrer"
            >
              <img src={btnAndroid} alt="download" />
            </a>
          </div>
        </div>
      </div>
    </StyledBannerLunarGacha23>
  );
};

export default BannerLunarGacha23;
