import styled from 'styled-components';
import bgDownloadMb from 'src/assets/images/howtoplay/bgDownloadMb.png';

export const StyledDownloadMobile = styled.div`
  padding: 100px 0;
  background-image: url(${bgDownloadMb});
  background-size: cover;
  background-repeat: round;
  .container {
    margin: auto;
    padding: 0;
    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #e5e5e5;
    }
    .content-wrapper {
      .content-box {
        .title {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.0015em;
          color: #ffffff;
          padding-bottom: 12px;
          margin: 0 auto;
          max-width: 300px;
        }
        .btn-download {
          margin-top: 16px;
          img {
            margin: 0 8px;
          }
        }
      }
    }
  }
`;
