import styled from 'styled-components';

export const StyledTokenDemand = styled.div`
  padding: 80px 0 180px 0;
  @media (max-width: 768px) {
    padding: 64px 0 64px 0;
  }
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    @media (max-width: 768px) {
      padding: 16px;
    }
    .title {
      font-weight: 600;
      font-size: 60px;
      line-height: 68px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #e5e5e5;
      padding-bottom: 24px;
      @media (max-width: 768px) {
        font-size: 26px;
        line-height: 32px;
      }
    }
    .img-wrapper {
      padding-top: 80px;
      @media (max-width: 1024px) {
        padding-left: 10%;
        padding-right: 10%;
      }
      @media (max-width: 768px) {
        padding-top: 32px;
        padding-left: 0;
        padding-right: 0;
      }
      .img-demand {
        @media (max-width: 768px) {
          width: 400px;
          margin: 0 auto;
        }
        @media (max-width: 568px) {
          width: 300px;
        }
      }
      .mt-4 {
        margin-top: 40px;
      }
    }
    .info {
      padding: 70px 100px;
      background: linear-gradient(
        180deg,
        rgba(48, 57, 102, 0.35) 0%,
        rgba(48, 57, 102, 0) 100%
      );
      border-radius: 27.7016px;
      @media (max-width: 768px) {
        padding: 32px 64px;
      }
      @media (max-width: 568px) {
        padding: 24px;
      }
      @media (max-width: 320px) {
        padding: 10px;
      }
      ul {
        padding: 0;
        li {
          list-style: none;
          padding: 16px 0;

          img {
            width: 68px;
            &:first-child {
              margin-right: 24px;
            }
            @media (max-width: 768px) {
              width: 34px;
              &:first-child {
                margin-right: 10px;
              }
            }
          }
          .label {
            margin-left: 40px;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.0015em;
            color: ${({ theme }) => theme.title};
            text-transform: uppercase;
            @media (max-width: 768px) {
              margin-left: 20px;
              font-size: 14px;
              line-height: 18px;
            }
          }
          .hidden {
            visibility: hidden;
          }
        }
      }
    }
  }
`;
