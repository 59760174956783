import styled from 'styled-components';

export const StyledBannerMobile = styled.div`
  padding: 48px 0;
  .banner-container {
    width: calc(100% - 100px);
    height: 300px;
    margin: 0 auto;
    background: #091434;
    border-radius: 24px;
    @media (max-width: 578px) {
      height: 200px;
      width: calc(100% - 24px);
      margin: 0 auto;
    }
    .wrapper {
      height: 100%;
      position: relative;
      top: 10px;
      @media (max-width: 578px) {
        top: 5%;
      }
    }
  }
`;
