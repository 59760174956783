import styled from 'styled-components';

export const StyledEquipMoveMobile = styled.div`
  padding: 48px 0;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .info-content {
      max-width: 268px;
      margin: 0 auto;
      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.0015em;
        color: #ffffff;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        .bold {
          padding-right: 12px;
          color: #ff8a34;
          font-style: italic;
        }
        &:nth-child(2) {
          padding-left: ${() => 0.3 * 90}px;
        }
        &:nth-child(3) {
          padding-left: ${() => 0.3 * 90 * 2}px;
        }
        &:nth-child(4) {
          padding-left: ${() => 0.3 * 90 * 3}px;
        }
      }
    }
    .content-wrapper {
      padding-top: 80px;
      .box-img {
        padding: 0 10px;
      }
      .box-wrapper {
        padding-bottom: 40px;
        position: relative;
        img {
          position: relative;
          z-index: 1;
          width: 280px;
        }
        .blur {
          z-index: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 450px;
          height: 450px;
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(49, 193, 255, 0.3) -100%,
            rgba(53, 85, 255, 0) 80%
          );
        }
      }
      .box-text {
        img {
          width: 300px;
        }
      }
    }
  }
`;
