import styled from 'styled-components';
import bgBlurProfit from 'src/assets/images/bgBlurProfit.png';

export const StyledProfit = styled.div`
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .text-og {
      color: #ff7510 !important;
    }
    .downloadBox {
      .btn-download {
        &:first-child {
          margin-right: 32px;
        }
      }
    }
  }
  .bottom-container {
    padding: 100px 0;
    @media (max-width: 768px) {
      width: 500px;
      margin: 0 auto;
    }
    @media (max-width: 568px) {
      padding: 100px 16px;
      width: 100%;
    }
    .img-wrapper {
      position: relative;
      @media (max-width: 768px) {
        margin: auto;
        display: flex;
        align-item: center;
        justify-content: center;
      }
      .main-img {
        position: relative;
        z-index: 2;
      }
      .blur {
        z-index: 0;
        width: 280px;
        height: 280px;
        border-radius: 50%;
        background: #425687;
        position: absolute;
        top: 40%;
        left: 40%;
        transform: translate(-50%, -50%);
        filter: blur(100px);
        @media (max-width: 568px) {
          display: none;
        }
      }
    }
    .content-wrapper {
      @media (max-width: 1024px) {
        padding-top: 32px;
      }
    }
    .leading-title {
      font-weight: 600;
      font-size: 60px;
      line-height: 68px;
      color: #f2f2f2;
      @media (max-width: 1024px) {
        font-size: 48px;
      }
      @media (max-width: 768px) {
        font-size: 36px;
      }
      @media (max-width: 568px) {
        font-size: 24px;
        line-height: unset;
      }
    }
    .profit-content {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 568px) {
        flex-direction: column;
      }
      .content-column {
        .content-title {
          font-weight: 600;
          font-size: 34px;
          line-height: 40px;
          letter-spacing: 0.25px;
          color: #f5f5f5;
          margin-bottom: 16px;
          @media (max-width: 1024px) {
            font-size: 28px;
          }
          @media (max-width: 568px) {
            font-size: 18px;
          }
        }
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.4px;
          color: #e5e5e5;
          margin: 0;
          @media (max-width: 1024px) {
            font-size: 14px;
          }
        }
        @media (max-width: 568px) {
          width: 100%;
        }
      }
    }
  }
`;
