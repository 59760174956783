import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';

import TokenMetricsDesktop from './TokenMetricsDesktop';
import TokenMetricsMobile from './TokenMetricsMobile';

const TokenMetrics = (props) => {
  return (
    <div>
      <ResponsiveLayout
        desktopView={<TokenMetricsDesktop {...props} />}
        mobileView={<TokenMetricsMobile {...props} />}
      />
    </div>
  );
};

export default TokenMetrics;
