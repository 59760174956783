import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Col, Image, Row } from 'antd';
import CountUp from 'react-countup';
import SliderSlick from 'src/components/SliderSlick/SliderSlick';
import topicListingFit from 'src/assets/images/topics/topicListingFit.png';
import topicExchange from 'src/assets/images/topics/topicExchange.png';
import topicNewGame from 'src/assets/images/topics/topicNewGame.png';
import topicLaunching from 'src/assets/images/topics/topicLaunching.png';
import { StyledTopics } from './styled';
import breakpoint from 'src/variables/breakpoint';
import { formatNumberThousand } from 'src/utils/formatNumber';

const TopicsMobile = (props) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  const { topics, statistic } = props;
  const totalDistance = statistic?.totalDistance / 1000;
  const totalFitEarned = statistic?.totalFitEarned;
  const discordMembers = statistic?.discordMembers;
  const twitterFollowers = statistic?.twitterFollowers;

  const slideSettings = {
    className: 'center',
    infinite: true,
    centerMode: true,
    centerPadding: '40px',
    slidesToShow: 1,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: breakpoint.lgMin,
        settings: {
          centerPadding: '0',
          centerMode: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: '30px',
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '8px',
          height: '8px',
          backgroundColor: '#AAB2C8',
          borderRadius: '50%',
        }}
      />
    ),
  };

  const SRC_URL = 'https://editor.calo.run';
  const BLOG_URL = 'https://blog.calo.run/en/';

  const renderSuffix = (value) => {
    if (value >= 1_000_000_000) {
      return 'b';
    }
    if (value >= 1_000_000) {
      return 'm';
    }
    if (value >= 1000) {
      return 'k';
    }
    return '';
  };

  const calcNumber = (value) => {
    if (value >= 1_000_000_000) {
      return value / 1_000_000_000;
    }
    if (value >= 1_000_000) {
      return value / 1_000_000;
    }
    if (value >= 1000) {
      return value / 1_000;
    }
    return value;
  }

  return (
    <StyledTopics className="container" ref={inViewRef}>
      <div className="topicWrapper">
        <div className="topicTitle">Number of our Communities</div>
        <Row gutter={24} className="counterContainer">
          <Col span={12}>
            <div className="counterItem">
              {inView && (
                <div className="number">
                  <CountUp
                    duration={2}
                    end={
                      totalDistance &&
                      calcNumber(parseFloat(totalDistance))
                    }
                    suffix={
                      totalDistance ? `${renderSuffix(totalDistance)} +` : ''
                    }
                  />
                </div>
              )}
              <div className="label">Distance (km)</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="counterItem">
              {inView && (
                <div className="number">
                  <CountUp
                    duration={2}
                    decimal="."
                    decimals={3}
                    end={
                      totalFitEarned &&
                      calcNumber(parseFloat(totalFitEarned))
                    }
                    suffix={
                      totalFitEarned ? `${renderSuffix(totalFitEarned)} +` : ''
                    }
                  />
                </div>
              )}
              <div className="label">FIT Earning</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="counterItem">
              {inView && (
                <div className="number">
                  <CountUp
                    duration={2}
                    end={
                      discordMembers &&
                      calcNumber(parseFloat(discordMembers))
                    }
                    suffix={
                      discordMembers ? `${renderSuffix(discordMembers)} +` : ''
                    }
                  />
                </div>
              )}
              <div className="label">Discord members</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="counterItem">
              {inView && (
                <div className="number">
                  <CountUp
                    duration={2}
                    end={
                      twitterFollowers &&
                      calcNumber(parseFloat(twitterFollowers))
                    }
                    suffix={
                      twitterFollowers
                        ? `${renderSuffix(twitterFollowers)} +`
                        : ''
                    }
                  />
                </div>
              )}
              <div className="label">Twitter followers</div>
            </div>
          </Col>
        </Row>
        <div className="topicTitle">Hot topics</div>
        <div className="activitiesContainer">
          <Image.PreviewGroup>
            {topics?.length > 0 ? (
              <SliderSlick
                showArrow={false}
                settings={slideSettings}
                paddingX="10px"
                paddingY="32px"
              >
                {topics?.map((topic, index) => {
                  const imgUrl =
                    topic?.attributes?.image?.data?.attributes?.formats?.medium
                      ?.url;
                  if (index >= 8) {
                    return null;
                  }
                  return (
                    <div className="activityItem link">
                      <a
                        href={BLOG_URL + topic?.attributes?.slug}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image src={SRC_URL + imgUrl} preview={false} />
                      </a>
                    </div>
                  );
                })}
              </SliderSlick>
            ) : (
              <SliderSlick
                showArrow={false}
                settings={slideSettings}
                paddingX="10px"
                paddingY="32px"
              >
                <div className="activityItem link">
                  <Image src={topicLaunching} preview={false} />
                </div>
                <div className="activityItem link">
                  <Image src={topicNewGame} preview={false} />
                </div>
                <div className="activityItem link">
                  <Image src={topicListingFit} preview={false} />
                </div>
              </SliderSlick>
            )}
          </Image.PreviewGroup>
        </div>
      </div>
    </StyledTopics>
  );
};

export default TopicsMobile;
