import styled from 'styled-components';

export const StyledEquipMoveDesktop = styled.div`
  padding: 100px 0 200px 0;
  .container {
    max-width: 1216px;
    margin: auto;
    padding: 0;
    .img-wrapper {
      position: relative;
      .img-box {
        position: relative;
        z-index: 1;
        @media (max-width: 1024px) {
          width: 90%;
          padding-left: 24px;
        }
      }
      .blur {
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
        width: 872px;
        height: 872px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(49, 193, 255, 0.3) -60%,
          rgba(53, 85, 255, 0) 80%
        );
        @media (max-width: 1024px) {
          left: 50%;
          width: 772px;
          height: 772px;
        }
      }
    }
    .content-wrapper {
      .title {
        font-weight: 700;
        font-size: 48px;
        line-height: 40px;
        letter-spacing: 0.25px;
        color: #ffffff;
        @media (max-width: 1024px) {
          font-size: 36px;
        }
        &:not(:last-child) {
          margin-bottom: 80px;
        }
        .bold {
          color: #FF8A34;
          font-style: italic;
          padding-right: 16px;
        }
      }
    }
  }
`;
