import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import TokenV2Desktop from './TokenV2Desktop';
import TokenV2Mobile from './TokenV2Mobile';

const TokenV2 = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<TokenV2Desktop {...props} />}
      mobileView={<TokenV2Mobile {...props} />}
    />
  );
};

export default TokenV2;
