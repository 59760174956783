import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { Row, Col, Tooltip } from 'antd';
import { useAppStore } from 'src/hooks/useAppStore';
import tokenmetricV2 from 'src/assets/images/token-metrics/tokenmetricV2.png';
import iconCopyLight from 'src/assets/images/iconCopy.svg';
import iconCopyDark from 'src/assets/images/iconCopyDark.svg';
import { StyledTokenmetrics } from './styled';
import { caloAddress } from 'src/variables/calo';
import { nonAnimatingContainer } from 'src/variables/animationVariant';

const TokenMetricsDesktop = (props) => {
  const [appStore] = useAppStore();
  const { isDarkMode } = appStore;
  const [inViewRef, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  const controls = useAnimation();

  const animationVariant = {
    visible: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: {
        when: 'beforeChildren',
        duration: 0.5,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      scaleY: 1,
      y: '10%',
      transition: {
        duration: 0.3,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
  };

  function getAnimation() {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 200);
    } else {
      controls.start('hidden');
    }
  }

  const [copied, setCopied] = useState(false);

  const copyAddress = (address) => {
    setCopied(true);
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(address);
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('input');
      ele.value = address;
      document.body.appendChild(ele);
      ele.select();
      document.execCommand('copy');
      document.body.removeChild(ele);
    }
  };

  useEffect(() => {
    getAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, inView]);

  return (
    <StyledTokenmetrics ref={inViewRef}>
      <AnimatePresence>
        <motion.div
          className="container"
          initial="hidden"
          animate={controls}
          variants={nonAnimatingContainer}
        >
          <Row>
            <Col span={12} style={{ zIndex: 10 }}>
              <div className="title">TOKENMETRICS</div>
              <div className="description">
                Calo Token is issued based on the Binance Smart Chain, which is
                entirely decentralized. Based on the Heath Mining mechanism,
                Calo will trigger corresponding Token distribution for each
                challenge completion of each user on the chain, which includes
                result recognition, tokens, currency holding income, and NFT
                mining pool, and will record all private keys to ensure its
                fairness.
              </div>
              <div className="tokenInfo">
                <div className="total">
                  Total issue: <span className="number">500.000.000</span> Calo
                  Token
                </div>
                <div className="contact">
                  <div className="label">Contract address</div>
                  <div className="inputBox">
                    <span className="value">{caloAddress}</span>
                    <Tooltip
                      title={copied ? 'Copied' : 'Copy'}
                      onVisibleChange={(visible) => {
                        if (!visible) {
                          setCopied(false);
                        }
                      }}
                    >
                      <img
                        className="iconSuffix link"
                        src={isDarkMode ? iconCopyDark : iconCopyLight}
                        onClick={() => copyAddress(caloAddress)}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <motion.div className="tokenSupply" variants={animationVariant}>
                <div className="imageCenter">
                  <img className="img-fluid" src={tokenmetricV2} />
                </div>
              </motion.div>
            </Col>
          </Row>
        </motion.div>
      </AnimatePresence>
    </StyledTokenmetrics>
  );
};

export default TokenMetricsDesktop;
