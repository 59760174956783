import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { StyledRoadmapV2Mobile } from './styled';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { nonAnimatingContainer } from 'src/variables/animationVariant';
import { milestones } from '../milestones';

const RoadmapV2Mobile = () => {
  const MotionCol = motion(Col);
  const MotionRow = motion(Row);

  const [inViewRef, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
  });

  const controls = useAnimation();

  const animationVariant = {
    visible: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: {
        when: 'beforeChildren',
        duration: 0.5,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      scaleY: 1,
      y: '10%',
      transition: {
        duration: 0.3,
        type: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
  };

  function getAnimation() {
    if (inView) {
      setTimeout(() => {
        controls.start('visible');
      }, 200);
    } else {
      controls.start('hidden');
    }
  }

  useEffect(() => {
    getAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, inView]);

  return (
    <StyledRoadmapV2Mobile ref={inViewRef}>
      <div className="container">
        <div className="title">Roadmap</div>
        <AnimatePresence>
          <MotionRow
            gutter={8}
            className="wrapper"
            align="middle"
            justify="space-between"
            initial="hidden"
            animate={controls}
            variants={nonAnimatingContainer}
          >
            {milestones.map((milestone, index) => (
              <MotionCol xs={12} sm={8} key={index} variants={animationVariant}>
                <div className="roadmap-item">
                  <div className="content">
                    <ul className='flex-center'>
                      {milestone.labels.map((label, index) => (
                        <li key={index}>{label}</li>
                      ))}
                    </ul>
                    <div className="milestone">{milestone.key}</div>
                  </div>
                </div>
              </MotionCol>
            ))}
          </MotionRow>
        </AnimatePresence>
      </div>
    </StyledRoadmapV2Mobile>
  );
};

export default RoadmapV2Mobile;
