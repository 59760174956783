import styled from 'styled-components';

export const StyledDocumentsMobile = styled.section`
  padding: 48px 0;
  .container {
    .documentsTitle {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      text-align: center;
      color: ${({ theme }) => theme.title};
      padding-bottom: 8px;
    }
    .documentsDesc {
      max-width: 273px;
      margin: auto;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.4px;
      color: ${({ theme }) => theme.description};
    }
    .documentsSlider {
      margin: 0 -15px;
    }
    .documentItem {
      position: relative;
      background: ${({ theme }) => theme.itemBg};
      border-radius: 16px;
      padding: 42px 0 42px 20px;
      margin-bottom: 32px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        border: ${({ theme }) => theme.boxBorder};
        border-radius: 16px;
        z-index: -1;
      }
      .itemInfo {
        display: flex;
        align-items: center;
        .icon {
        }
        .itemDetail {
          margin-left: 16px;
          .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.0125em;
            color: ${({ theme }) => theme.title};
          }
          .btnText {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: ${({ theme }) => theme.title};
          }
        }
      }
    }

    @media (max-width: 768px) {
      .documentItem {
        padding: 42px 32px;
      }
    }

    @media (max-width: 428px) {
      .documentItem {
        padding: 32px 0 32px 16px;
        .itemInfo {
          .itemDetail {
            .title {
              font-size: 16px;
              line-height: 20px;
            }
            .btnText {
              margin-top: 8px;
              font-size: 10px;
              line-height: 16px;
            }
          }
        }
      }
    }
    @media (max-width: 375px) {
      .documentItem {
        .itemInfo {
          .icon {
            width: 41px;
          }
          .itemDetail {
            .title {
              font-size: 14px;
              line-height: 16px;
            }
            .btnText {
              font-weight: 400;
              font-size: 10px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
`;
