import styled from 'styled-components';

export const StyledIntroduction = styled.section`
  position: relative;
  /* max-width: 1216px; */
  max-width: 1382px;
  margin: 0 auto;
  margin-top: -50px;
  .darkThemeBackground {
    height: 500px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: lighten;
    border-radius: 64px;
    overflow: hidden;
    opacity: 0.7;
    img {
      height: 100%;
      max-width: 1216px;
    }
  }
  .slick-slider {
    .slick-list {
      margin-bottom: 32px;
      .slick-track {
        padding: 60px 0;
        @media (max-width: 1440px) {
          padding: 100px 0;
        }
        @media (max-width: 1024px) {
          padding: 30px 0;
        }
      }
    }
    .slick-dots {
      bottom: -45px;
    }
  }
  @media (max-width: 1024px) {
    max-width: 960px;
  }
`;
