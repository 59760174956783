import React from 'react';
import ResponsiveLayout from 'src/components/ResponsiveLayout';
import PartnersDesktop from './PartnersDesktop';
import PartnersMobile from './PartnersMobile';

const Partners = (props) => {
  return (
    <ResponsiveLayout
      desktopView={<PartnersDesktop {...props} />}
      mobileView={<PartnersMobile {...props} />}
    />
  );
};

export default Partners;
